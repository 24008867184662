import React, { useEffect, useState } from "react";
import { useQuery, useSubscription } from "@apollo/client";
// import gql queries
import { GET_INQUIRY } from "./graphql/Query";
import UserCard from "~/components/UserCard";
import PaymentsCard from "./components/PaymentsCard";
import TicketRequest from "./components/TicketRequest";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import moment from "moment";
import { FaEnvelopeOpenText } from "react-icons/fa";
import Inbox from "~/modules/InboxModule/segments/Inbox";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import InquiryComments from "./components/InquiryComments";
import InquiryRow from "../../components/InquiryTable/components/InquiryRow";
import { GET_ALL_USER_INQUIRIES } from "~/graphql/users/Query";
import { useNavigate } from "react-router-dom";
import { GET_TRANSACTIONS } from "~/modules/AccountModule/segments/TransactionsList/graphql/Query";
import TransactionsTable from "~/modules/AccountModule/segments/TransactionsList/components/TransactionsTable";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import NewPassengerCard from "./components/PassengersCard/NewPassengerCard";
import InquiryTabPanel from "./components/InquiryTabPanel";
import InquiryTabs from "./components/InquiryTabs";
import NewSuggestionCard from "./components/SuggestionCard";
import NewInvoice from "./components/Invoices/NewInvoice";
import CarsCard from "./components/CarsCard";
import WhatsAppInqComp from "./components/WhatsAppInqComp";

import EmailInqComp from "./components/EmailInqComp";

export default function InquiryDetail({ inquiry_id }: any) {
  const defaultRole = useUserDefaultRole();
  const user_id = useUserId();
  const { travelHouse }: any = travelHouseStore();

  const navigate = useNavigate();

  const [value, setValue] = useState<number>(0);
  // get data based on query
  const { loading, data, error } = useSubscription(GET_INQUIRY, {
    variables: { inquiry_no: inquiry_id },
  });

  useEffect(() => {
    if (
      data?.inq_list?.[0]?.thp_id &&
      data?.inq_list?.[0]?.thp_id !== travelHouse?.id
    ) {
      navigate("/admin");
    }
  }, [data?.inq_list?.[0]?.thp_id]);

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );
  const [showInbox, setShowInbox] = useState(false);

  const {
    loading: uil,
    data: uid,
    error: uie,
  } = useQuery(GET_ALL_USER_INQUIRIES, {
    variables: {
      user_id: data?.inq_list?.[0]?.user_id,
      brd_id: data?.inq_list?.[0]?.brd_id,
    },
  });

  const condition = { inq_list: { inquiry_no: { _eq: inquiry_id } } };

  const { data: accTransactions, refetch: refetchTrans } = useQuery(
    GET_TRANSACTIONS,
    {
      variables: { condition },
    }
  );

  // return loading while loading data
  if (loading) return <div className="dark:text-dark-primary">Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  const inquiryDetails = data?.inq_list[0]; // Access the first inquiry

  const tabs = [
    { label: "Hotel", details: inquiryDetails.stay_inq_details },
    { label: "Cars", details: inquiryDetails.car_inq_details },
    { label: "Train", details: inquiryDetails.train_inq_details },
    { label: "Visa", details: inquiryDetails.visa_inq_details },
    {
      label: "Flights",
      details: [
        {
          from: inquiryDetails.from,
          to: inquiryDetails.to,
          arrival_datetime: inquiryDetails.arrival_datetime,
          departure_datetime: inquiryDetails.departure_datetime,
          trip_type: inquiryDetails.trip_type,
          cabin_class: inquiryDetails.cabin_class,
          inq_passengers: inquiryDetails?.inq_passengers,
        },
      ],
    },
  ].filter((tab) => {
    if (tab.label === "Flights") {
      return tab.details[0]?.from !== null || tab.details[0]?.to !== null;
    }
    return Array.isArray(tab.details) && tab.details.length > 0;
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const customerId = data?.inq_list[0]?.users?.id;

  return (
    <>
      <h1 className="text-gray-700 dark:text-dark-primary uppercase text-[16px] md:text-[18px] flex  font-bold border-b border-[#E7E3FC61] mb-2 justify-between">
        <span className="flex items-center">
          Inquiry # {data.inq_list[0].inquiry_no}
          {travelHouseData?.thp_list[0]?.email_refresh_token && (
            <FaEnvelopeOpenText
              className="ml-[10px] cursor-pointer text-[16px] md:text-[18px] text-[#3a3939] dark:text-[#CDC7E1]"
              onClick={() => setShowInbox(true)}
            />
          )}
        </span>
        {showInbox && (
          <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 ">
            <div className="modal mx-auto my-16 bg-white rounded-lg w-[80%] max-h-[80vh] p-6 overflow-y-auto">
              <button
                className="text-white ml-auto whitespace-nowrap mb-3 bg-[#135737] hover:bg-[#256a4a] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-[#256a4a] dark:hover:bg-[#135737] focus:outline-none dark:focus:ring-blue-800"
                onClick={() => setShowInbox(false)}
              >
                Close
              </button>
              <Inbox defaultEmail={data?.inq_list[0]?.users?.email} />
            </div>
          </div>
        )}
        <span className="text-right">
          <span className=" text-[12px] md:text-[15px]">
            {getTimeReadableFormat(data.inq_list[0]?.created_at)}
          </span>
          <br />
          <span className=" text-[12px] md:text-[15px] font-normal">
            {moment(data.inq_list[0]?.created_at).format(
              "DD MMM, YYYY hh:mm A"
            )}
          </span>
        </span>
      </h1>
      <Accordion>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Inquiries</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="">
            <table className="w-full bg-[white] dark:bg-[#28183D] rounded-2xl shadow ">
              {uid?.users?.[0]?.inq_lists?.map((inq: any) => {
                return (
                  <InquiryRow
                    inquiry={inq}
                    hideEyeBtn={true}
                    hide={true}
                    currInq={data?.inq_list?.[0]}
                  />
                );
              })}
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
      <InquiryTabs
        value={value}
        tabs={tabs}
        handleChange={handleChange}
        inquiryId={inquiryDetails?.id}
        inquiryPassengers={inquiryDetails?.inq_passengers}
      />

      <div className="mt-[30px]">
        <div className="w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[30px] ">
            <div className="self-stretch flex flex-row flex-wrap items-stretch justify-start gap-[30px] mb-5 text-xs ">
              <div className="flex-1 flex-shrink-0 w-[48%] rounded-2xl bg-white dark:bg-dark-secondary shadow border-none">
                <UserCard
                  transactions={accTransactions?.acc_transactions}
                  inquiry={data.inq_list[0]}
                  brands={true}
                  pickedBy={data?.picked_by}
                  user={data.inq_list[0].users}
                  editAble={
                    data.inq_list[0].status !== "completed" &&
                    (user_id === data?.inq_list[0]?.picked_by ||
                      defaultRole === "admin")
                      ? true
                      : false
                  }
                />
              </div>

              <div className="flex-1 flex-shrink-0 w-[48%] rounded-2xl bg-white dark:bg-dark-secondary shadow border-none">
                <InquiryTabPanel
                  value={value}
                  tabs={tabs}
                  inquiry={inquiryDetails}
                  className=""
                />
              </div>
            </div>
          </div>
          <div className="flex items-center dark:text-primary my-[30px]">
            <hr className="w-[2%] dark:border-[#E7E3FC61]" />
            <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
              SUGGESTIONS
            </span>

            <hr className="w-full dark:border-[#E7E3FC61]" />
          </div>

          <NewSuggestionCard
            value={value}
            tabs={tabs}
            inquiry={data.inq_list[0]}
          />

          <NewPassengerCard tabs={[tabs[value]]} inquiry={data.inq_list[0]} />
          {tabs?.[value]?.label === "Cars" && (
            <CarsCard inquiry={data.inq_list[0]} />
          )}

          <NewInvoice
            inquiry={data.inq_list[0]}
            tab={[tabs[value]]}
            inquiry_id={inquiry_id}
          />
          <PaymentsCard inquiry={data.inq_list[0]} />
          {/* Ticket Request */}
          {defaultRole !== "user" ? (
            <TicketRequest
              inquiry={data.inq_list[0]}
              tabs={[tabs[value]]}
              inquiry_id={inquiry_id}
            />
          ) : (
            <>
              {data?.inq_list[0]?.status === "completed" && (
                <TicketRequest
                  inquiry={data.inq_list[0]}
                  tabs={[tabs[value]]}
                  inquiry_id={inquiry_id}
                />
              )}
            </>
          )}
          <InquiryComments inquiry={data?.inq_list?.at(0)} />
          <TransactionsTable
            transactions={accTransactions?.acc_transactions}
            compType="inquirSection"
            refetchTrans={refetchTrans}
          />
          <WhatsAppInqComp customerId={customerId} />
          <EmailInqComp customerId={customerId} />
        </div>
      </div>
    </>
  );
}
