import React, { useState } from "react";
import SuggestionSelect from "./components/SuggestionSelect";
import SuggestionUnSelect from "./components/SuggestionUnSelect";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import NewSuggestionUnSelect from "./components/NewSuggestionUnSelect";
import NewSuggestionSelect from "./components/SuggestionSelect/NewSuggestionSelect";

export default function SelectButton({
  selected,
  suggestion,
  inquiry_id,
  inquiry,
  user_email,
  brand_url,
  brand_name,
  brand_domain,
  selected_suggestion_id,
  picked_by,
  user_id,
  tab,
  activeSuggestion
}: any) {

  const userRole = useUserDefaultRole();
  const userLoggedIn = useUserId();

  return (
    <>
      {selected_suggestion_id?.includes(suggestion?.id) &&
        (userLoggedIn === inquiry?.picked_by || userRole === "admin") ? (
        <NewSuggestionUnSelect
          inquiry={inquiry}
          inquiry_id={inquiry_id}
          suggestion_id={suggestion?.id}
          picked_by={picked_by}
          user_id={user_id}
          tab={tab}
          activeSuggestion={activeSuggestion}
        />
      ) : (
        !selected_suggestion_id?.includes(suggestion?.id) &&
        (userLoggedIn === inquiry?.picked_by || userRole === "admin") && (
          <NewSuggestionSelect
            inquiry={inquiry}
            inquiry_id={inquiry_id}
            user_email={user_email}
            brand_url={brand_url}
            brand_domain={brand_domain}
            suggestion={suggestion}
            brand_name={brand_name}
            suggestion_id={suggestion?.id}
            picked_by={picked_by}
            user_id={user_id}
            tab={tab}
            activeSuggestion={activeSuggestion}
          />
        )
      )}
    </>
  );
}
