import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import {
  CAR_EDIT_INQUIRY,
  EDIT_INQUIRY,
  STAY_EDIT_INQUIRY,
  TRAIN_EDIT_INQUIRY,
  VISA_EDIT_INQUIRY
} from "../../../../../graphql/Mutation";
import { toast } from "react-toastify";
import useNotifications from "hooks/useNotifications";
import {
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import Select from "../../../../../../../../../assets/img/Select.svg";
import SelectDark from "../../../../../../../../../assets/img/Select-dark.svg";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import useEmail from "~/hooks/emails/useEmail";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import { SEND_EMAIL } from "~/config/Mutation";

interface NewSuggestionSelectProps {
  inquiry_id: string;
  user_id: string;
  picked_by: string;
  suggestion_id: string;
  suggestion: any; // Replace `any` with the actual suggestion type if available
  inquiry: any; // Replace `any` with the actual inquiry type if available
  tab: string;
  activeSuggestion: any
}

export default function NewSuggestionSelect({
  inquiry_id,
  user_id,
  picked_by,
  suggestion_id,
  suggestion,
  inquiry,
  tab,
  activeSuggestion
}: NewSuggestionSelectProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const emailSend = useEmail();
  const userUserName = useUserDisplayName();
  const userRole = useUserDefaultRole();
  const userId = useUserId();
  const client = useApolloClient();
  const alert = useNotifications();

  const { travelHouse }: any = travelHouseStore();

  const [sendEmail] = useMutation(SEND_EMAIL);
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [editStaySuggestion] = useMutation(STAY_EDIT_INQUIRY);
  const [editCarSuggestion] = useMutation(CAR_EDIT_INQUIRY);
  const [editVisaSuggestion] = useMutation(VISA_EDIT_INQUIRY)
  const [editTrainSuggestion] = useMutation(TRAIN_EDIT_INQUIRY);
  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const { data: travelHouseData, error: travelHouseError } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);

    const handleStorageChange = () => {
      const updatedData = localStorage.getItem("darkMode");
      setDarkMode(updatedData ? JSON.parse(updatedData) : false);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleConfirm = async (event: any) => {
    event.stopPropagation();
    setIsLoading(true);

    const invoiceItems: { amount: number; description: string }[] = [];
    try {
      if (!suggestion) {
        toast.error("No suggestion data found.");
        return;
      }

      // Populate invoice items
      const suggestionCosts = (() => {
        if (tab === "Stay") return suggestion.stay_inq_suggestion_costs;
        if (tab === "Cars") return suggestion.car_inq_suggestion_costs;
        if (tab === "Train") return suggestion.train_inq_suggestion_costs;
        // if (tab === "Visa") return suggestion.visa_inq_suggestion_costs
        return suggestion?.inq_suggestion_costs || [];
      })();

      suggestionCosts.forEach((cost: any) => {
        invoiceItems.push({
          amount: cost.sale_price,
          description: `${cost?.inq_passenger?.first_name || "N/A"} ${cost?.inq_passenger?.last_name || "N/A"
            } (${cost?.inq_passenger?.type || "N/A"})`,
        });
      });

      let mutation = editInquiry;
      let suggestionType = "Suggestion";
      let dynamicId = inquiry_id;

      if (tab === "Hotel") {
        mutation = editStaySuggestion;
        suggestionType = "Stay Suggestion";
        dynamicId = activeSuggestion?.stay_inq_id || inquiry_id;
      } else if (tab === "Cars") {
        mutation = editCarSuggestion;
        suggestionType = "Car Suggestion";
        dynamicId = inquiry?.car_inq_details?.[0]?.id || inquiry_id;
      } else if (tab === "Visa") {
        mutation = editVisaSuggestion
        suggestionType = "Visa Suggestion"
        dynamicId = inquiry?.visa_inq_details?.[0]?.id || inquiry_id;
      } else if (tab === "Train") {
        mutation = editTrainSuggestion;
        suggestionType = "Train Suggestion";
        dynamicId = inquiry?.train_inq_details?.[0]?.id || inquiry_id;
      }

      const response = await updateInqListUser({
        variables: { id: inquiry.id, updated_by: userId },
      });

      const res = await mutation({
        variables: { id: dynamicId, selected_suggestion_id: suggestion_id },
      });

      if (!res?.data) {
        throw new Error("Mutation response is invalid.");
      }

      toast.success(`${suggestionType} selected successfully`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      await client.resetStore();
      setModalOpen(false);
    } catch (error) {
      console.error("Error selecting suggestion:", error);
      toast.error("An error occurred while processing your request.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <img
        src={darkMode ? SelectDark : Select}
        alt="Select Suggestion"
        title="Select Suggestion"
        onClick={() => {
          if (inquiry?.invoice_id) {
            toast.error("First Void Your Invoice");
          } else {
            setModalOpen(true);
          }
        }}
        className="cursor-pointer"
      />

      {modalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative bg-white dark:bg-dark-primary rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <h2 className="mb-6 text-2xl font-bold text-gray-700 dark:text-white">
              Are you sure you want to select this suggestion?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => setModalOpen(false)}
                className="px-5 py-1 font-semibold text-basic border border-basic rounded-lg"
              >
                No
              </button>
              <button
                onClick={handleConfirm}
                className="px-5 py-1 font-semibold text-white bg-basic rounded-lg"
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
