import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Typography,
  FormLabel,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface Car {
  adults: number;
  children: number;
}

const CarForm: React.FC<{
  value: Car[];
  onChange: (newValue: Car[]) => void;
  errors: any;
  setValue?: any;
  fieldName?: string;
  label?: string;
  inquiryPassengers: any;
}> = ({
  value = [{ adults: 1, children: 0 }],
  onChange,
  label,
  inquiryPassengers,
}) => {
  const [cars, setCars] = useState<Car[]>(value);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const id = "car-popover";
  const popoverWidth = "300px";

  useEffect(() => {
    if (Array.isArray(inquiryPassengers) && inquiryPassengers.length > 0) {
      const adultsCount = inquiryPassengers.filter(
        (passenger: any) =>
          passenger.type === "adult" || passenger.type === "youth"
      ).length;

      const childrenCount = inquiryPassengers.filter(
        (passenger: any) => passenger.type === "child"
      ).length;

      const updatedCars = [
        {
          adults: adultsCount,
          children: childrenCount,
        },
      ];

      setCars(updatedCars);
      onChange(updatedCars);
    }
  }, [inquiryPassengers, onChange]);

  const handleCarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleCarClose = () => setOpen(false);

  const handleAddCar = () => {
    const totalAdults = cars.reduce((sum, car) => sum + car.adults, 0);
    const totalChildren = cars.reduce((sum, car) => sum + car.children, 0);

    const maxAdults = inquiryPassengers.filter(
      (passenger: any) =>
        passenger.type === "adult" || passenger.type === "youth"
    ).length;

    const maxChildren = inquiryPassengers.filter(
      (passenger: any) => passenger.type === "child"
    ).length;

    if (totalAdults + 1 <= maxAdults && totalChildren <= maxChildren) {
      const newCars = [...cars, { adults: 1, children: 0 }];
      setCars(newCars);
      onChange(newCars);
    }
  };

  const handleRemoveCar = (index: number) => {
    if (cars.length <= 1) return;

    const removedCar = cars[index];
    const remainingCars = cars.filter((_, i) => i !== index);

    const newCars = remainingCars.map((car, i) => {
      if (i === 0) {
        return {
          adults: car.adults + removedCar.adults,
          children: car.children + removedCar.children,
        };
      }
      return car;
    });

    setCars(newCars);
    onChange(newCars);
  };

  const handleCarChange = (
    index: number,
    type: "adults" | "children",
    value: number
  ) => {
    const totalAdults = cars.reduce((sum, car) => sum + car.adults, 0);
    const totalChildren = cars.reduce((sum, car) => sum + car.children, 0);

    const maxAdults = inquiryPassengers.filter(
      (passenger: any) =>
        passenger.type === "adult" || passenger.type === "youth"
    ).length;

    const maxChildren = inquiryPassengers.filter(
      (passenger: any) => passenger.type === "child"
    ).length;

    const newTotal =
      type === "adults"
        ? totalAdults - cars[index].adults + value
        : totalChildren - cars[index].children + value;

    if (
      (type === "adults" && newTotal <= maxAdults) ||
      (type === "children" && newTotal <= maxChildren)
    ) {
      const newCars = [...cars];

      if (type === "children") {
        if (cars.length === 1 && value < cars[index].children) {
          return;
        }

        if (cars.length > 1 && value === 0) {
          const childrenToRedistribute = newCars[index].children;
          let remainingChildren = childrenToRedistribute;
          newCars[index].children = 0;

          for (let i = 0; i < newCars.length && remainingChildren > 0; i++) {
            if (i !== index) {
              const canAdd = maxChildren - newCars[i].children;
              const toAdd = Math.min(canAdd, remainingChildren);
              newCars[i].children += toAdd;
              remainingChildren -= toAdd;
            }
          }

          if (remainingChildren > 0) {
            newCars[index].children += remainingChildren;
          }
        } else {
          newCars[index].children = value;
        }
      } else if (type === "adults") {
        newCars[index].adults = Math.max(1, value);
      }

      setCars(newCars);
      onChange(newCars);
    }
  };

  const canAddCar = () => {
    const totalAdults = cars.reduce((sum, car) => sum + car.adults, 0);
    const maxAdults = inquiryPassengers.filter(
      (passenger: any) =>
        passenger.type === "adult" || passenger.type === "youth"
    ).length;

    return totalAdults < maxAdults;
  };

  return (
    <div className="w-full">
      <TextField
        aria-describedby={id}
        label={label || "Travelers"}
        value={`Cars: ${cars.length}`}
        onClick={handleCarClick}
        aria-readonly
        fullWidth
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{ style: { width: popoverWidth } }}
      >
        <Box className="p-4 max-w-md w-full md:max-w-xl overflow-auto max-h-96">
          {cars.map((car, index) => (
            <Box key={index} className="mb-4 p-4 rounded-lg mx-w-md w-full">
              <FormLabel sx={{ fontWeight: "bold" }}>{`Car ${
                index + 1
              }`}</FormLabel>

              <Box className="flex gap-4 mt-2 justify-between">
                <Typography>Adults:</Typography>
                <div className="flex items-center justify-center gap-2">
                  <IconButton
                    onClick={() =>
                      handleCarChange(
                        index,
                        "adults",
                        Math.max(1, cars[index].adults - 1)
                      )
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>

                  <span className="text-center w-12 text-lg font-medium">
                    {cars[index].adults}
                  </span>

                  <IconButton
                    onClick={() =>
                      handleCarChange(index, "adults", cars[index].adults + 1)
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Box>

              <Box className="flex gap-4 mt-2 justify-between">
                <Typography>Children:</Typography>
                <div className="flex items-center justify-center gap-2">
                  <IconButton
                    onClick={() =>
                      handleCarChange(
                        index,
                        "children",
                        Math.max(0, cars[index].children - 1)
                      )
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>

                  <span className="text-center w-12 text-lg font-medium">
                    {cars[index].children}
                  </span>

                  <IconButton
                    onClick={() =>
                      handleCarChange(
                        index,
                        "children",
                        cars[index].children + 1
                      )
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Box>

              {cars.length > 1 && (
                <div className="flex w-full justify-end mt-2">
                  <Button onClick={() => handleRemoveCar(index)}>
                    Remove Car
                  </Button>
                </div>
              )}
            </Box>
          ))}
          {canAddCar() && <Button onClick={handleAddCar}>Add Car</Button>}
        </Box>
      </Popover>
    </div>
  );
};

export default CarForm;
