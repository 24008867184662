import { gql } from "@apollo/client";

export const GET_INQUIRY = gql`
  subscription GetInquiry($inquiry_no: String!) {
    inq_list(where: { inquiry_no: { _eq: $inquiry_no } }) {
      direct_flight
      flexible_arrival
      flexible_departure
      approx_budget
      from
      inquiry_no
      preferred_airline
      source
      status
      to
      trip_type
      arrival_datetime
      created_at
      departure_datetime
      updated_at
      brd_id
      id
      payment_method_id
      picked_by
      suggestion_id
      invoice_id
      thp_id
      user_id
      cabin_class
      flightInq_seatSelections {
        inq_id
        cost_price
        sale_price
        seat_no
      }
      flightInq_extraBaggages {
        inq_id
        cost_price
        sale_price
        weight
      }
      visa_inq_details {
        selected_visa_suggestion_id
        selected_visa_suggestion {
          visa_inq_suggestion_costs {
            cost_price
            sale_price
          }
        }
      }
      stay_inq_details(order_by: { created_at: asc }) {
        location
        start_date
        end_date
        rooms
        inq_id
        id
        adults
        children
        selected_stay_suggestion_id
        selected_stay_suggestion {
          id
          location
          check_in
          check_out
          hotel_name
          reservation_id
          stay_inq_suggestion_costs {
            id
            room_rate
            sale_price
            room_tax
            stay_room_id
            stay_inq_room {
              id
              room_no
              room_type
              stay_inq_id
            }
          }
        }
        stay_inq_rooms {
          id
          stay_inq_id
          room_type
          room_no
          inq_passengers {
            id
          }
          stay_inq_suggestion_costs {
            id
            room_rate
            room_tax
            sale_price
            stay_suggestion_id
            stay_room_id
          }
        }
        stay_inq_suggestions {
          id
          location
          check_in
          check_out
          hotel_name
          reservation_id
          stay_inq_id
          stay_suggestion_pdf
          stay_inq_suggestion_costs {
            id
            room_rate
            room_tax
            sale_price
            stay_suggestion_id
            stay_room_id
            stay_inq_room {
              id
              room_no
              room_type
              stay_inq_id
            }
          }
        }
      }
      train_inq_details {
        id
        inq_id
        origin
        destination
        date
        selected_train_suggestion_id
        selected_train_suggestion {
          train_inq_suggestion_costs {
            id
            train_suggestion_id
            inq_passenger_id
            basic_fare
            tax_price
            sale_price
            inq_passenger {
              id
              type
              first_name
              last_name
              gender
              dob
              nationality
              passport_no
              passport_expiry
            }
          }
          pnr
        }
        train_inq_suggestions {
          id
          train_inq_id
          origin
          destination
          cabin_class
          train_name
          start_date
          end_date
          time
          pnr
          train_suggestion_pdf
          train_inq_suggestion_costs {
            id
            basic_fare
            tax_price
            sale_price
            train_suggestion_id
            inq_passenger_id
            inq_passenger {
              id
              type
              first_name
              last_name
              gender
              dob
              nationality
              passport_no
              passport_expiry
            }
          }
        }
      }
      car_inq_details {
        origin
        destination
        pick_date_time
        inq_id
        id
        selected_car_suggestion_id
        selected_car_suggestion {
          car_inq_suggestion_costs {
            basic_fare
            sale_price
            tax_price
            id
            car_id
          }

          pnr
        }
        car_inq_suggestions {
          id
          created_at
          updated_at
          car_inq_id
          origin
          destination
          car_type
          start_date
          end_date
          pnr

          car_suggestion_pdf
          car_inq_suggestion_costs {
            id
            created_at
            updated_at
            basic_fare
            tax_price
            sale_price
            car_suggestion_id
            car_id
          }
        }
      }
      visa_inq_details {
        id
        name
        country
        selected_visa_suggestion_id
        visa_inq_suggestions {
          id
          visa_inq_id
          name
          country
          visa_expiry_date
          visa_inq_suggestion_costs {
            id
            visa_suggestion_id
            cost_price
            sale_price
            inq_passenger_id
            inq_passenger {
              type
              first_name
              last_name
            }
          }
        }
      }

      brd_list {
        name
        domain
        subdomain
        gmail_refresh_token
        brd_contact_details {
          address1
          country
          city
          email
          phone
        }
        brd_details {
          logo
          fav_icon
          secondary_color
          primary_color
        }
      }
      users {
        id
        email
        displayName
        metadata
        phoneNumber
        avatarUrl
        defaultRole
        brd_whatsapp_conversation {
          id
        }
        brd_users {
          user_id
          brd_id
          thp_id
        }
      }
      picked_user {
        id
        avatarUrl
        metadata
        email
        displayName
        phoneNumber
        thp_users_profile {
          commission
        }
      }
      inq_passengers {
        type
        first_name
        dob
        created_at
        gender
        id
        last_name
        nationality
        passport_expiry
        passport_no
        updated_at
        inq_id
        inq_suggestion_costs {
          basic_fare
          created_at
          atol_price
          id
          passenger_id
          sale_price
          suggestion_id
          tax_price
          updated_at
        }
      }
      inq_suggestions {
        due_date
        fare_expiry
        id
        pnr
        bookingFee
        inq_suggestion_costs {
          basic_fare
          atol_price
          id
          created_at
          passenger_id
          sale_price
          tax_price
          updated_at
          inq_passenger {
            first_name
            last_name
            id
            gender
            type
            passport_no
            passport_expiry
            nationality
            inq_id
            dob
          }
        }
        inq_suggestion_legs {
          cabin
          hand_carry
          id
          created_at
          luggage
          type
          updated_at
          inq_suggestion_leg_stops {
            airline_iata
            arrival
            arrival_datetime
            created_at
            depart
            depart_datetime
            flight_no
            id
            ordering
          }
        }
      }
      inq_transections {
        amount
        created_at
        id
        payment_method_id
        receipt
        ref_no
        status
        transactions_no
        charge_id
        inq_id
        brd_id
        refund_amount
        acc_payment_method {
          id
          type
          name
          account_title
          account_no
          swift_code
          card_terminal_type
          stripe_secret
          status
        }
      }
      selected_suggestion {
        id
        inq_suggestion_costs {
          basic_fare
          atol_price
          sale_price
          tax_price
          id
          passenger_id

          inq_passenger {
            first_name
            last_name
            id
            gender
            type
            passport_no
            passport_expiry
            nationality
            inq_id
            dob
          }
        }
        due_date
        fare_expiry
        pnr
        bookingFee
      }
    }
  }
`;
