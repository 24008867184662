import { useUserDefaultRole, useUserId } from "@nhost/react";
import React, { useState } from "react";
import SideDrawer from "~/components/common/sideDrawer";
import CarSuggestionForm from "../SuggestionCard/components/AddSuggestion/components/SuggestionForm/Cars";
import AddCar from "./components/AddCar";

const CarsCard = ({ inquiry }: any) => {
  const user_id = useUserId();

  const [showCarForm, setShowCarForm] = useState(false);

  const userDefaultRole = useUserDefaultRole();

  return (
    <div className="w-full">
      <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
        <div className="self-stretch flex items-center dark:text-primary mt-[30px]">
          <hr className="w-[2%] dark:border-[#E7E3FC61]" />
          <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
            Cars
          </span>
          <hr className="w-full dark:border-[#E7E3FC61]" />
          {inquiry.status !== "completed" && (
            <div className="min-w-fit pl-2">
              {!inquiry?.selected_suggestion &&
                (user_id === inquiry?.picked_by ||
                  userDefaultRole === "admin") && (
                  <button
                    onClick={() => {
                      setShowCarForm(true);
                      console.log("showCarForm", showCarForm);
                    }}
                    className="block border border-basic rounded-full px-3 py-1 hover:text-basic cursor-pointer font-medium bg-basic dark:hover:bg-dark-primary hover:bg-white text-white"
                  >
                    Add Cars
                  </button>
                )}
            </div>
          )}
        </div>
      </div>
      {showCarForm && (
        <SideDrawer
          setSidePopup={setShowCarForm}
          sidePopup={showCarForm}
          component={<AddCar />}
        />
      )}
    </div>
  );
};

export default CarsCard;
