import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  STAY_ADD_SUGGESTION_COST,
  STAY_UPDATE_SUGGESTION,
  EDIT_STAY_SUGGESTION_ROOM,
  EDIT_ROOM_SUGGESTION_COST,
} from "../../EditSuggestion/components/EditSuggestionForm/graphql/Mutation";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { FormControl } from "@mui/material";
import CityBox from "~/components/FormInputs/CityBox/CityBox";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HotelNames from "~/components/FormInputs/HotelsName";
import dayjs, { Dayjs } from "dayjs";
import NewPassengerEditCost from "../components/EditSuggestionForm/components/NewPassengerEditCost";
import useEmail from "~/hooks/emails/useEmail";
import { useUserDisplayName, useUserId } from "@nhost/react";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import useNotifications from "~/hooks/useNotifications";
import { toast } from "react-toastify";
import { RoomTypeSelector } from "../../../../AddSuggestion/components/SuggestionForm/Stay/components/RoomType";
import StayPassengerEditCost from "../components/EditSuggestionForm/components/NewPassengerEditCost/StayPassengerEditCost";
import StayRoomsCost from "../../../../AddSuggestion/components/SuggestionForm/components/PassengersCost/StayRoomsCost";

const EditStaySuggestionBox = ({
  defaultValue,
  inquiry_id,
  inquiry,
  suggestion,
  user_id,
  passengers,
  suggestion_cost,
  setShowAddModal,
  tab,
}: any) => {
  const [editSuggestion] = useMutation(STAY_UPDATE_SUGGESTION);
  const [editRoomSuggestion] = useMutation(EDIT_STAY_SUGGESTION_ROOM);
  const [editRoomSuggestionCost] = useMutation(EDIT_ROOM_SUGGESTION_COST);
  const [addSuggestionCost] = useMutation(STAY_ADD_SUGGESTION_COST);
  const userName = useUserDisplayName();
  const emailSend = useEmail();

  const userId = useUserId();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  const client = useApolloClient();
  //button loading state
  const [isLoading, setIsloading] = useState<any>(false);
  // Define react hooks form
  const alert = useNotifications();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
    unregister,
  } = useForm({
    defaultValues: defaultValue,
  });

  const onSubmit = async (data: any) => {
    setIsloading(true);
    let isSuccess = false;

    try {
      const startDate = data?.stay?.dates?.[0]
        ? moment(data.stay.dates[0]?.$d).format("YYYY-MM-DD HH:mm:ss")
        : defaultValue.checkIn;
      const endDate = data?.stay?.dates?.[1]
        ? moment(data.stay.dates[1]?.$d).format("YYYY-MM-DD HH:mm:ss")
        : defaultValue.checkOut;

      const updatedSuggestionData: any = {
        location: data?.location || defaultValue?.location,
        hotel_name: data?.hotelName || defaultValue?.hotelName,
        reservation_id: null,
        check_in: startDate,
        check_out: endDate,
      };

      // Update suggestion
      const res = await editSuggestion({
        variables: { id: data?.id, suggestion: updatedSuggestionData },
      });

      if (res?.data?.update_stay_inq_suggestion?.returning?.id) {
        isSuccess = true;
      }

      const roomCostPromises = data.stay.suggestion_cost.map((cost: any) =>
        editRoomSuggestionCost({
          variables: {
            costId: cost.costId,
            roomRate: cost.roomRate,
            roomTax: cost.roomTax,
            salePrice: cost.salePrice,
          },
        })
      );

      const roomCostResponses = await Promise.all(roomCostPromises);

      if (
        roomCostResponses.every(
          (res) =>
            res?.data?.update_stay_inq_suggestion_cost?.returning?.[0]?.id
        )
      ) {
        isSuccess = true;
      }

      if (isSuccess) {
        toast.success(`Stay Suggestion Updated Successfully!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setShowAddModal(false);
      }
    } catch (err) {
      toast.error(`Failed to edit suggestions: ${err}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setIsloading(false);
    }

    // setIsloading(true);
    // if (!isLoading) {
    //   // Add inquiry id to formData
    //   formData["id"] = suggestion.id;

    //   try {
    //     // Prepare suggestion data for update
    //     const startDate =
    //       formData?.stay?.dates && formData.stay.dates[0]
    //         ? formData.stay.dates[0]
    //         : defaultValue.start_date;

    //     const endDate =
    //       formData?.stay?.dates && formData.stay.dates[1]
    //         ? formData.stay.dates[1]
    //         : defaultValue.end_date;
    //     const inq_suggestion: any = {
    //       where_to: formData.where_to,
    //       hotel_name: formData.hotel_name,
    //       room_type: formData.room_type,
    //       start_date: startDate,
    //       end_date: endDate,
    //     };

    //     // Update the suggestion itself

    //     // Check if there is an existing suggestion cost
    //     if (
    //       !formData.suggestion_cost ||
    //       formData.suggestion_cost.length === 0
    //     ) {
    //       // No existing cost: add new suggestion cost
    //       let suggestion_cost_add: any = [];

    //       // Prepare new cost data
    //       formData.suggestion_cost_add.map((sca: any) => {
    //         sca["stay_suggestion_id"] = formData.id; // Add the suggestion ID
    //         suggestion_cost_add.push(sca);
    //       });

    //       // Add new suggestion cost
    //       const resSuggestionCostAdd = await addSuggestionCost({
    //         variables: { suggestion_cost: suggestion_cost_add },
    //       });
    //     } else {
    //       formData.suggestion_cost.map(async (cost: any) => {
    //         const suggestionCost = { ...cost };
    //         const id = suggestionCost["id"];

    //         // Update existing cost
    //         const resSuggestionCost = await editSuggestionCost({
    //           variables: { id: id, suggestion_cost: suggestionCost },
    //         });
    //       });
    //     }

    //     // Reset the Apollo Client store and send confirmation email
    //     await client.resetStore();

    //     const variables: any = {
    //       inquiryNo: inquiry?.inquiry_no,
    //       brandName: inquiry?.brd_list?.name,
    //       userFirstName: inquiry?.users?.displayName,
    //       consultantName: userName,
    //     };

    //     await emailSend(
    //       4,
    //       inquiry.brd_id,
    //       inquiry.users?.id,
    //       inquiry.users?.email,
    //       inquiry.users?.phoneNumber,
    //       variables
    //     );

    //     // Success toast notification
    //     toast.success(`Suggestions edited successfully`, {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //     });

    //     // Notification logic for users
    //     await alert.newNotification(
    //       userId,
    //       `Your consultant updated a suggestion. Please review it in the portal.`,
    //       "",
    //       ``
    //     );

    //     setIsloading(false);
    //     setShowAddModal(false);
    //   } catch (error) {
    // toast.error(`Failed to edit suggestions`, {
    //   position: toast.POSITION.BOTTOM_RIGHT,
    // });
    //   } finally {
    //     setIsloading(false);
    //   }
    // }
  };

  const handleDateRangeChange = (newValue: [Dayjs | null, Dayjs | null]) => {
    const [start, end] = newValue;
    // Update the form state only if both dates are provided
    if (start && end) {
      setValue("stay.dates", [start, end]); // Update using ISO string format
    } else {
      setValue("stay.dates", []); // Set to an empty array if either date is null
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!inquiry?.invoice_id && (
        <div className="bg-gray-100 rounded-lg p-2 dark:bg-[#28243D]">
          <div className="grid grid-cols-2 gap-4 mx-3">
            <FormControl className="mx-3 mt-3" fullWidth>
              <Controller
                control={control}
                name="location"
                rules={{ required: true }}
                render={({ field }) => (
                  <CityBox
                    {...field}
                    label="Where to"
                    errors={errors}
                    register={register}
                    control={control}
                    setValue={setValue}
                    unregister={unregister}
                    getValues={getValues}
                  />
                )}
              />
            </FormControl>
            <Controller
              control={control}
              name="stay.dates"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                const startDate = dayjs(defaultValue?.check_in);
                const endDate = dayjs(defaultValue?.check_out);
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["SingleInputDateRangeField"]}
                      sx={{ margin: 0, padding: 0 }}
                    >
                      <DateRangePicker
                        value={[startDate, endDate]} // Controlled value
                        onChange={(newValue) => {
                          onChange(newValue); // Update the form value
                          handleDateRangeChange(newValue); // Call the custom handler
                        }}
                      />
                      {error && <span className="error">{error.message}</span>}{" "}
                    </DemoContainer>
                  </LocalizationProvider>
                );
              }}
            />
            <FormControl className="mx-3" fullWidth>
              <Controller
                control={control}
                name="hotelName"
                rules={{ required: true }}
                render={({ field }) => (
                  <HotelNames
                    {...field}
                    label="Hotel Name"
                    errors={errors}
                    register={register}
                    control={control}
                    setValue={setValue} // Use the new function
                    unregister={unregister}
                    getValues={getValues}
                  />
                )}
              />
            </FormControl>
          </div>
        </div>
      )}
      <hr className="border-gray-300 border-dashed my-4" />
      <StayRoomsCost
        errors={errors}
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        // type={type}
        roomCost={defaultValue?.rooms}
      />
      <button
        className={` ${
          isLoading ? "bg-basic cursor-wait" : "bg-basic"
        } h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
        type="submit"
      >
        {isLoading ? "Updating.." : "Update Suggestion"}
      </button>
    </form>
  );
};

export default EditStaySuggestionBox;
