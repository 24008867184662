import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";

import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import CityBox from "~/components/FormInputs/CityBox/CityBox";
import RoomForm from "~/components/FormInputs/RoomForm";

const StayComponent = ({ control, register, setValue, errors, index, inquiryPassengers }: any) => {
  const handleDateRangeChange = (newValue: [Dayjs | null, Dayjs | null]) => {
    // Custom logic
  };

  const [selectedCityName, setSelectedCityName] = useState("");

  return (
    <>
      {/* Where To Field */}
      <Controller
        control={control}
        name={`stay[${index}].whereTo`} // Unique name based on index
        rules={{ required: "This is required" }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <CityBox
              value={value} // Ensure value is initialized correctly
              onChange={onChange} // Make sure onChange is directly connected to form state
              setValue={setValue}
              fieldName={`stay[${index}].whereTo`} // Use the same dynamic field name
              label="Where to"
              errors={errors}
              setSelectedCityName={setSelectedCityName}
            />
            {error && (
              <span className="text-red-500 text-xs">{error.message}</span>
            )}
          </>
        )}
      />
      <Controller
        control={control}
        name={`stay[${index}].dates`} // Unique name based on index
        rules={{
          validate: (value) =>
            value && value[0] && value[1] ? true : "This field is required",
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["SingleInputDateRangeField"]}
                  sx={{ margin: 0, padding: 0 }}
                >
                  <DateRangePicker
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue); // Update the form value
                      handleDateRangeChange(
                        newValue as [Dayjs | null, Dayjs | null]
                      ); // Custom handler
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {error && (
                <span className="text-red-500 text-xs">{error.message}</span>
              )}
            </>
          );
        }}
      />
      <Controller
        control={control}
        name={`stay[${index}].Travelers`} // Unique name based on index
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <RoomForm
            value={value || [{ adults: 1, children: 0 }]}
            onChange={onChange}
            setValue={setValue}
            fieldName={`stay[${index}].Travelers`} // Use the same dynamic field name
            label="Travelers"
            errors={error}
            inquiryPassengers={inquiryPassengers}
          />
        )}
      />
    </>
  );
};

export default StayComponent;

