import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_BRAND_DETAILS } from "./graphql/Query";
import SeoSettings from "./components/SeoSettings";
import PostDetail from "./components/PostDetail";
import { useFileUpload, useUserId } from "@nhost/react";
import { INSERT_POST } from "./components/PostDetail/graphql/Mutation";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import BrandPostDetail from "~/components/common/BrandPostDetail";

const PostAdd = () => {
  const { brd_id } = useParams();
  const { loading, data } = useQuery(GET_BRAND_DETAILS, {
    variables: { brd_id: brd_id },
  });
  const brandData = data?.brd_list?.at(0);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const userID = useUserId();
  const navigate = useNavigate();

  const [logoFile, setLogoFile] = useState<any | null>(null);
  const [content, setContent] = useState("");

  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const [insertPost] = useMutation(INSERT_POST);
  const client = useApolloClient();

  const addPost = async (inpData: any) => {
    // return 0;
    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["image"] = logoUploadRes?.id || null;
    }

    const res = await insertPost({
      variables: {
        data: {
          author_id: userID,
          brd_id: brandData.id,
          slug: inpData?.post_title?.replaceAll(" ", "-").toLowerCase(),
          post_title: inpData?.post_title,
          canonical_url: inpData?.canonical_url,
          featured_image: inpData?.image || null,
          content: content,
          show_in_flight_page: inpData?.show_in_flight_page,
          iata: inpData?.destination?.iata_code || null,
          airline_iata: inpData?.airline?.iata || null,
          excerpt: inpData?.excerpt,
          is_google_indexed: inpData?.is_google_indexed,
        },
      },
    });
    if (res?.data?.insert_brd_posts_one?.id) {
      reset();
      toast.success("Post added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.resetStore();
      navigate(`/admin/brands/${brandData.id}/posts`);
    }
  };

  if (loading) return <p>Loading ...</p>;
  // ;

  if (brandData) {
    return (
      <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 ">
        <div className="mb-4 col-span-full xl:mb-2">
          <BrandPostDetail
            setLogoFile={setLogoFile}
            addPost={addPost}
            content={content}
            setContent={setContent}
            register={register}
            control={control}
            handleSubmit={handleSubmit}
            errors={errors}
            setType=""
            pageData={null}
            setValue={() => {}}
          />
        </div>
      </div>
    );
  } else {
    return <p>No brand details found</p>;
  }
};

export default PostAdd;
