import React from "react";
import StayRoomCostRow from "./components/StayRoomCostRow";
import { error } from "console";

export default function StayRoomsCost({
  errors,
  register,
  control,
  setValue,
  watch,
  type,
  roomCost
}: any) {

  return (
    <div>
      <div className="bg-gray-100 dark:bg-[#28243D] rounded-lg p-2 mt-4">
        <div className="flex flex-col border-b border-gray-300 w-full">
          {/* Section Heading */}
          <h1 className="flex-1 w-full font-bold text-left text-primary dark:text-dark-primary">
            Rooms Cost
          </h1>
        </div>
        {roomCost.length > 0 ? (
          <div className="mt-2">
            {roomCost.map((room: any, index: any) => {
              return (
                <>
                  <StayRoomCostRow
                    room={room}
                    register={register}
                    errors={errors}
                    index={index}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    type={type}
                  />
                  <hr className="border-gray-300  border-dashed my-2" />
                </>
              );
            })}
          </div>
        ) : (
          <div className="mt-2">
            <h1 className="pl-2 text-sm">Select any location</h1>
          </div>
        )}
      </div>
    </div>
  );
}
