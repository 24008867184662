import React from "react";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";

const TrainPassengerCostRow = ({
  passenger,
  passengers,
  onRemove,
  control,
  errors,
  index,
  setSelectedPsngr,
  selectedPsngr,
  costs,
}: any) => {
  return (
    <div className="flex flex-col gap-3 p-4 border rounded mb-2">
      <div className="flex flex-col gap-3">
        {/* Select Person */}
        <FormControl className="w-60">
          <InputLabel id={`passenger-select-label-${index}`}>
            Select Person
          </InputLabel>
          <Controller
            name={`train.passengers[${index}].id`}
            control={control}
            defaultValue={passenger?.inq_passenger_id || ""}
            rules={{ required: "Please select a person" }}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  labelId={`passenger-select-label-${index}`}
                  label="Select Person"
                  error={!!errors?.passengers?.[index]?.id}
                  value={field.value || ""}
                  onChange={(e) => {
                    const selectedId = e.target.value;
                    setSelectedPsngr((prevSelectedPsngr: any) => {
                      const updatedPsngr = [...prevSelectedPsngr];
                      if (updatedPsngr[index] === selectedId) {
                        return updatedPsngr;
                      } else {
                        updatedPsngr[index] = selectedId;
                        return updatedPsngr;
                      }
                    });
                    field.onChange(selectedId);
                  }}
                  renderValue={(value) => {
                    const selected = passengers.find(
                      (p: any) => p?.id === value
                    );

                    return selected
                      ? `${selected.first_name} ${selected.last_name}`
                      : "Select Person";
                  }}
                >
                  {(costs
                    ? passengers
                    : passengers?.filter(
                        (p: any) => !selectedPsngr.includes(p.id)
                      )
                  )?.map((p: any) => (
                    <MenuItem key={p.id} value={p.id}>
                      {`${p.first_name} ${p.last_name}`}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          {errors?.passengers?.[index]?.id && (
            <span className="text-red-500 text-xs">
              {errors.passengers[index].id.message}
            </span>
          )}
        </FormControl>

        {/* Offer Price and Cost Price */}
        <div className="flex flex-1 gap-4">
          <Controller
            name={`train.passengers[${index}].basicFare`}
            control={control}
            defaultValue={passenger?.basic_fare || ""}
            rules={{ required: "Basic Fare is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Basic Fare"
                type="number"
                placeholder="Basic Fare"
                error={!!errors?.passengers?.[index]?.basicFare}
                helperText={errors?.passengers?.[index]?.basicFare?.message}
                fullWidth
              />
            )}
          />

          <Controller
            name={`train.passengers[${index}].taxPrice`}
            control={control}
            defaultValue={passenger?.tax_price || ""}
            rules={{ required: "Tax Price is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Tax Price"
                type="number"
                placeholder="Tax Price"
                error={!!errors?.passengers?.[index]?.taxPrice}
                helperText={errors?.passengers?.[index]?.taxPrice?.message}
                fullWidth
              />
            )}
          />

          <Controller
            name={`train.passengers[${index}].salePrice`}
            control={control}
            defaultValue={passenger?.sale_price || ""}
            rules={{ required: "Sale Price is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Sale Price"
                type="number"
                placeholder="Sale Price"
                error={!!errors?.passengers?.[index]?.salePrice}
                helperText={errors?.passengers?.[index]?.salePrice?.message}
                fullWidth
              />
            )}
          />
        </div>
      </div>
      {index !== 0 && (
        <div className="flex justify-end">
          <Button
            variant="outlined"
            color="error"
            onClick={onRemove}
            className="ml-4"
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};

export default TrainPassengerCostRow;
