import React, { useEffect } from "react";
import InputField from "~/components/FormInputs/InputField";
import { FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
import { RoomTypeSelector } from "../../../../Stay/components/RoomType";

export default function StayRoomCostRow(
  {
    key,
    room,
    register,
    errors,
    setValue,
    index,
    control,
    watch,
    type,
  }: any
) {

  const roomRate = watch(`stay.suggestion_cost.${index}.roomRate`);
  const roomTax = watch(`stay.suggestion_cost.${index}.roomTax`);
  const totalCost = parseFloat(roomRate) + parseFloat(roomTax);

  useEffect(() => {
    // Set default values if available
    if (room?.costs && room?.costs.length > 0) {
      const defaultCost = room.costs[0]; // Use the first cost by default
      setValue(`stay.suggestion_cost.${index}.costId`, defaultCost.id);
      setValue(`stay.suggestion_cost.${index}.roomRate`, defaultCost.roomRate);
      setValue(`stay.suggestion_cost.${index}.roomTax`, defaultCost.roomTax);
      setValue(`stay.suggestion_cost.${index}.salePrice`, defaultCost.salePrice);
    } else {
      // Set only the room ID for new values
      setValue(`stay.suggestion_cost.${index}.roomId`, room.id);
    }
  }, [room, setValue, index]);

  // useEffect(() => {
  //   if (room.roomType) {
  //     setValue(`stay.suggestion_cost.${index}.roomType`, room.roomType || "");
  //     setValue(`stay.suggestion_cost.${index}.roomNo`, room.roomNo || "");
  //     setValue(
  //       `stay.suggestion_cost.${index}.basic_fare`,
  //       room.roomSuggestionCost?.basic_fare || ""
  //     );
  //     setValue(
  //       `stay.suggestion_cost.${index}.sale_price`,
  //       room.roomSuggestionCost?.sale_price || ""
  //     );
  //   }
  // }, [room, setValue, index]);

  return (
    <div className="flex flex-row gap-4 items-center">
      <div className="w-[70%] pt-[30px] text-primary dark:text-dark-primary">
        {room?.roomType || room?.room_type ? (
          <>Room Type - {(room.roomType || room.room_type).toUpperCase()}</>
        ) : (
          <>Room Type - N/A</>
        )}
      </div>
      <InputField
        label="Room Rate"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Room Rate"
        fieldName={`stay.suggestion_cost.${index}.roomRate`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      <InputField
        label="Room Tax"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Room Tax"
        fieldName={`stay.suggestion_cost.${index}.roomTax`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      <InputField
        label={`Sale Price (Cost - ${totalCost ? totalCost : 0} )`}
        register={register}
        errors={errors}
        type="number"
        placeHolder="Sale Price"
        fieldName={`stay.suggestion_cost.${index}.salePrice`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
    </div>
  );
}
