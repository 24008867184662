import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
// Import components
import { SEND_EMAIL } from "~/config/Mutation";
import { CustomerTemplates } from "~/utils/TemplatesAll";
import { sendGenericEmail } from "~/utils/sendEmail";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserData, useUserDisplayName, useUserId } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import { URLs } from "~/config/enums";
import { pdf } from "@react-pdf/renderer";
import { nhost } from "~/lib/nhost";
import { ADD_STAY_SUGGESTION } from "../graphql/Mutation";
import useNotifications from "~/hooks/useNotifications";
import SuggestionPDF from "../components/SuggestionPDF";
import { stayFormatData } from "../../../utils/stayFormatData";
import FlightType from "../components/FlightType";
import FlightLeg from "../components/FlightLeg";
import FlightDates from "../components/FlightDates"; // Ensure you have this component
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import FlightPrices from "../components/FlightPrices";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PassengersCost from "../components/PassengersCost";
import SuggestionModelWraper from "../../../../SuggestionNav/components/SuggestionModelWraper";
import HotelNames from "~/components/FormInputs/HotelsName";
import { RoomTypeSelector } from "./components/RoomType";
import StaySuggestionPDF from "./components/SuggestionPDF";
import CityBox from "~/components/FormInputs/CityBox/CityBox";
import CityName from "~/components/FormInputs/CityName";
import StayRoomsCost from "../components/PassengersCost/StayRoomsCost";
import moment from "moment";
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  Button,
} from "@mui/material";

export default function StaySuggestionForm({
  defaultValue = {
    whereTo: "",
    stay: {
      dates: [null, null],
    },
  },
  inquiry,
  rooms,
  setShowAddModal,
  setActive,
  showModal,
  onCloseModal,
  type,
}: any) {
  const [addStaySuggestion] = useMutation(ADD_STAY_SUGGESTION);
  const [selectedStayId, setSelectedStayId] = useState(null);
  const client = useApolloClient();
  const { travelHouse }: any = travelHouseStore();
  const [sendEmail] = useMutation(SEND_EMAIL);
  const [isLoading, setIsloading] = useState<any>(false);
  const userData = useUserData();
  const userName = useUserDisplayName();
  const emailSend = useEmail();
  const today = dayjs();
  const userId = useUserId();
  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  const alert = useNotifications();
  const [logo, setLogo] = useState<any>();
  const [selectedCityName, setSelectedCityName] = useState("");
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
  });

  const filteredRooms = rooms?.filter(
    (room: any) => room.stay_inq_id === selectedStayId
  );

  async function convertLogo() {
    try {
      const response = await fetch(
        URLs.FILE_URL + inquiry?.brd_list?.brd_details?.[0]?.fav_icon
      );
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {}
  }

  useEffect(() => {
    if (inquiry?.brd_list?.brd_details?.[0]?.fav_icon) {
      convertLogo();
    }
  }, [inquiry?.brd_list?.brd_details?.[0]?.fav_icon]);

  const uploadPdfToNhost = async (file: any) => {
    try {
      const response = await nhost.storage.upload({
        file,
      });

      if (response.error) {
        throw new Error(response.error.message);
      }

      return response.fileMetadata.id;
    } catch (error) {
      throw error;
    }
  };

  const getPdfId = async (formData: any) => {
    const blob = await pdf(
      <StaySuggestionPDF inquiry={inquiry} logo={logo} suggestions={formData} />
    ).toBlob();

    const file = new File([blob], "invoice.pdf", {
      type: "application/pdf",
    });

    const pdfId = await uploadPdfToNhost(file);
    return pdfId;
  };

  const onSubmit = async (data: any) => {
    setIsloading(true);

    try {
      const payload = {
        location: data?.stay?.whereTo,
        check_in: moment(data?.stay?.dates[0]?.$d).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        check_out: moment(data?.stay?.dates[1]?.$d).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        hotel_name: data?.stay?.HotelName,
        reservation_id: null,
        stay_inq_id: data?.stay?.id,
        stay_inq_suggestion_costs: {
          data: data?.stay?.suggestion_cost.map((room: any) => ({
            room_rate: room?.roomRate,
            room_tax: room?.roomTax,
            sale_price: room?.salePrice,
            stay_room_id: room?.roomId,
          })),
        },
      };

      const res = await addStaySuggestion({
        variables: {
          suggestions: payload,
        },
      });

      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: userId },
      });

      if (res?.data?.insert_stay_inq_suggestion?.affected_rows > 0) {
        setIsloading(false);
        toast.success(`Suggestions Added Successfully!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setShowAddModal(false);
        await client.refetchQueries({
          include: "all",
        });
      }
    } catch (err) {
      toast.error(`Error Creating Suggestion ${err}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    // formData.stay_inq_details = formData.stay_inq_details || {};
    // formData.stay_inq_details.inq_id = inquiry?.stay_inq_details[0]?.id;

    // const pdfId = await getPdfId(stayFormatData(formData, null, inquiry));

    // setIsloading(true);
    // if (!isLoading) {
    //   try {
    //     // Create Suggestion function call

    // await updateInqListUser({
    //   variables: { id: inquiry.id, updated_by: userId },
    // });

    // const res = await addSuggestion({
    //   variables: {
    //     suggestions: stayFormatData(formData, pdfId, inquiry),
    //   },
    // });

    //     if (res?.data?.insert_stay_inq_suggestion?.returning?.length > 0) {
    //       setActive(res?.data?.insert_stay_inq_suggestion?.returning?.[0]?.id);
    //       toast.success(`Suggestions added successfully`, {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //       });

    // await client.refetchQueries({
    //   include: "all", // Consider using "active" instead!
    // });
    //     }
    //     setIsloading(false); // change button loading state
    //     setShowAddModal(false);
    //   } catch (e) {
    //     setIsloading(false); // change button loading state
    //     setShowAddModal(false);
    //   }
    // }
  };

  return (
    <SuggestionModelWraper isVisible={showModal} onClose={onCloseModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-gray-100 rounded-lg p-2 dark:bg-[#28243D]">
          <div className="grid grid-cols-2 gap-4 mx-3">
            <Controller
              control={control}
              name="stay.id"
              rules={{
                required: "Please select a location",
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <Select
                    value={value || ""}
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      onChange(selectedId);
                      setSelectedStayId(selectedId); // Update the selected stay_inq_id
                    }}
                    displayEmpty
                    fullWidth
                    renderValue={(selected) =>
                      selected
                        ? inquiry?.stay_inq_details?.find(
                            (detail: any) => detail.id === selected
                          )?.location
                        : "Select a location"
                    }
                  >
                    {inquiry?.stay_inq_details?.map((detail: any) => (
                      <MenuItem key={detail.id} value={detail.id}>
                        {detail.location}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && (
                    <span className="text-red-500 text-xs">
                      {error.message}
                    </span>
                  )}
                </>
              )}
            />
            {/* Where To Field */}
            <FormControl className="mx-3 mt-3" fullWidth>
              <Controller
                control={control}
                name="stay.whereTo"
                rules={{ required: "This is required" }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <CityName
                      value={value} // Ensure value is initialized correctly
                      onChange={(city: any) => {
                        onChange(city);
                        setSelectedCityName(city);
                      }}
                      setValue={setValue} // Optional, if additional logic is needed
                      fieldName="stay.whereTo"
                      label="Where to"
                      errors={errors}
                    />
                    {error && (
                      <span className="text-red-500 text-xs">
                        {error.message}
                      </span> // Extract the error message here
                    )}
                  </>
                )}
              />
            </FormControl>

            {/* Dates Field */}
            <FormControl className="mx-3" fullWidth>
              <Controller
                control={control}
                name="stay.dates"
                rules={{
                  validate: (value) =>
                    value && value[0] && value[1]
                      ? true
                      : "This field is required", // Ensure both dates are selected
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["SingleInputDateRangeField"]}
                          sx={{ margin: 0, padding: 0 }}
                        >
                          <DateRangePicker
                            value={value || [null, null]}
                            onChange={(newValue) => {
                              onChange(newValue);
                            }}
                            minDate={today}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {error && (
                        <span className="text-red-500 text-xs">
                          {error.message}
                        </span> // Extract the error message here
                      )}
                    </>
                  );
                }}
              />
            </FormControl>
            <FormControl className="mx-3 " fullWidth>
              <Controller
                control={control}
                name="stay.HotelName"
                rules={{ required: "This is required" }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <HotelNames
                      value={value} // Ensure value is initialized correctly
                      onChange={onChange} // Make sure onChange is directly connected to form state
                      setValue={setValue} // Optional, if additional logic is needed
                      fieldName="stay.HotelName"
                      label="Hotel Name"
                      errors={errors}
                      cityName={selectedCityName}
                    />
                    {error && (
                      <span className="text-red-500 text-xs">
                        {error.message}
                      </span> // Extract the error message here
                    )}
                  </>
                )}
              />
            </FormControl>
          </div>
        </div>
        <hr className="border-gray-300 border-dashed my-4" />
        <StayRoomsCost
          errors={errors}
          register={register}
          control={control}
          setValue={setValue}
          watch={watch}
          type={type}
          roomCost={filteredRooms}
        />
        {/* Submit form button */}
        <button
          className={` ${
            isLoading ? "bg-basic cursor-wait" : "bg-basic"
          } h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
          type="submit"
        >
          {isLoading ? "Creating.." : "Create Suggestion"}
        </button>
      </form>
    </SuggestionModelWraper>
  );
}
