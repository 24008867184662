import { gql } from "@apollo/client";

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethod($brd_id: uuid!) {
    acc_payment_methods(
      where: { brd_id: { _eq: $brd_id } }
      order_by: { created_at: desc }
    ) {
      account_no
      account_title
      created_at
      id
      name
      stripe_pub
      default
      stripe_secret
      swift_code
      thp_id
      type
      updated_at
      card_terminal_type
      status
    }
  }
`;
