import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useUserDisplayName, useUserId } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import { URLs } from "~/config/enums";
import { pdf } from "@react-pdf/renderer";
import { nhost } from "~/lib/nhost";
import { ADD_CAR_SUGGESTION } from "../graphql/Mutation";
import useNotifications from "~/hooks/useNotifications";
import CityBox from "~/components/FormInputs/CityBox/CityBox";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import FlightPrices from "../components/FlightPrices";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PassengersCost from "../components/PassengersCost";
import { FormControl } from "@mui/material";

import SuggestionModelWraper from "../../../../SuggestionNav/components/SuggestionModelWraper";
import { carFormatData } from "../../../utils/carFromatData";
import { CarTypeSelector } from "./components/CarType";
import CarSuggestionPDF from "./components/SuggestionPDF";
export default function CarSuggestionForm({
  defaultValue = {
    whereTo: "",
    stay: {
      dates: [null, null],
    },
  },
  inquiry,
  user_id,
  user_phone,
  user_email,
  brand_url,
  brand_name,
  passengers,
  setShowAddModal,
  setActive,
  showModal,
  onCloseModal,
  type,
}: any) {
  const [addSuggestion] = useMutation(ADD_CAR_SUGGESTION);
  const client = useApolloClient();
  const [isLoading, setIsloading] = useState<any>(false);
  const userName = useUserDisplayName();
  const emailSend = useEmail();
  const today = dayjs();
  const userId = useUserId();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  const alert = useNotifications();
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
  });

  const [logo, setLogo] = useState<any>();

  async function convertLogo() {
    try {
      const response = await fetch(
        URLs.FILE_URL + inquiry?.brd_list?.brd_details?.[0]?.fav_icon
      );
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {}
  }

  useEffect(() => {
    if (inquiry?.brd_list?.brd_details?.[0]?.fav_icon) {
      convertLogo();
    }
  }, [inquiry?.brd_list?.brd_details?.[0]?.fav_icon]);

  const uploadPdfToNhost = async (file: any) => {
    try {
      const response = await nhost.storage.upload({
        file,
      });

      if (response.error) {
        throw new Error(response.error.message);
      }

      return response.fileMetadata.id;
    } catch (error) {
      throw error;
    }
  };

  const getPdfId = async (formData: any) => {
    const blob = await pdf(
      <CarSuggestionPDF inquiry={inquiry} logo={logo} suggestions={formData} />
    ).toBlob();

    const file = new File([blob], "invoice.pdf", {
      type: "application/pdf",
    });
    const pdfURL = URL.createObjectURL(blob);

    const pdfId = await uploadPdfToNhost(file);
    return pdfId;
  };

  const onSubmit = async (formData: any) => {
    formData.car_inq_details = formData.car_inq_details || {};
    formData.car_inq_details.inq_id = inquiry?.car_inq_details[0]?.id;
    const pdfId = await getPdfId(carFormatData(formData, null));
    setIsloading(true);
    console.log("formData", carFormatData(formData, pdfId));
    if (!isLoading) {
      try {
        console.log("Updating inquiry list user...");
        await updateInqListUser({
          variables: { id: inquiry.id, updated_by: userId },
        });
        console.log("Adding suggestion...");
        const formattedData = carFormatData(formData, pdfId);
        console.log("Formatted Data:", formattedData);
        const res = await addSuggestion({
          variables: {
            suggestions: formattedData,
          },
        });
        console.log("Suggestion added:", res);

        if (res?.data?.insert_car_inq_suggestion?.returning?.length > 0) {
          setActive(res?.data?.insert_car_inq_suggestion?.returning?.[0]?.id);
          const variables = {
            from: {
              th_name: brand_name,
            },
            to: {
              email: user_email,
            },
            variables: {
              phone: user_phone,
              url: brand_url,
            },
          };
          const emailVariables: any = {
            inquiryNo: inquiry?.inquiry_no,
            brandName: inquiry?.brd_list?.name,
            userFirstName: inquiry?.users?.displayName,
            userLastName: "",
            consultantName: userName,
          };
          await emailSend(
            3,
            inquiry.brd_id,
            inquiry.users?.id,
            inquiry.users?.email,
            inquiry.users?.phoneNumber
          );
        }
      } catch (error) {
        console.error("Error executing query:", error);
      } finally {
        setIsloading(false);
      }
    }
  };

  const handleDateRangeChange = (newValue: [Dayjs | null, Dayjs | null]) => {
    // Custom logic
  };
  return (
    <SuggestionModelWraper isVisible={showModal} onClose={onCloseModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-gray-100 rounded-lg p-2 dark:bg-[#28243D]">
          <div className="grid grid-cols-2 gap-4 mx-3">
            {/* Where To Field */}
            <FormControl className="mx-3 mt-3" fullWidth>
              <Controller
                control={control}
                name="cars.pickUp"
                rules={{ required: "This is required" }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <CityBox
                      value={value} // Ensure value is initialized correctly
                      onChange={onChange} // Make sure onChange is directly connected to form state
                      setValue={setValue} // Optional, if additional logic is needed
                      fieldName="pickUp"
                      label="Origin"
                      errors={errors}
                    />
                    {error && (
                      <span className="text-red-500 text-xs">
                        {error.message}
                      </span> // Extract the error message here
                    )}
                  </>
                )}
              />
            </FormControl>
            <FormControl className="mx-3" fullWidth>
              <Controller
                control={control}
                name="cars.dropOff"
                rules={{ required: "This is required" }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <CityBox
                      value={value} // Ensure value is initialized correctly
                      onChange={onChange} // Make sure onChange is directly connected to form state
                      setValue={setValue} // Optional, if additional logic is needed
                      fieldName="dropOff"
                      label="Destination"
                      errors={errors}
                    />
                    {error && (
                      <span className="text-red-500 text-xs">
                        {error.message}
                      </span> // Extract the error message here
                    )}
                  </>
                )}
              />
            </FormControl>

            {/* Dates Field */}
            <FormControl className="mx-3" fullWidth>
              <Controller
                control={control}
                rules={{ required: "This is required" }}
                name="cars.dates"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  // Set to min date as both start and end
                  return (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["SingleInputDateRangeField"]}
                          sx={{ margin: 0, padding: 0 }}
                        >
                          <DateRangePicker
                            value={value} // Controlled value from form state
                            onChange={(newValue) => {
                              onChange(newValue); // Update the form value
                              handleDateRangeChange(
                                newValue as [Dayjs | null, Dayjs | null]
                              ); // Custom handler
                            }}
                            minDate={today}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {error && (
                        <span className="text-red-500 text-xs">
                          {error.message}
                        </span> // Extract the error message here
                      )}
                    </>
                  );
                }}
              />
            </FormControl>

            <FormControl className="mx-3 " fullWidth>
              <Controller
                name="cars.carType" // The name for the input
                control={control}
                rules={{ required: "This is required" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <CarTypeSelector
                      {...field} // Spread the field props to connect with react-hook-form
                      register={register}
                      errors={errors}
                      onChange={(value: any) => {
                        // Update the value on change

                        field.onChange(value); // Update the form state
                      }}
                    />
                    {error && (
                      <span className="text-red-500 text-xs">
                        {error.message}
                      </span> // Extract the error message here
                    )}
                  </>
                )}
              />
            </FormControl>
          </div>
        </div>
        <hr className="border-gray-300 border-dashed my-4" />

        <FlightPrices errors={errors} register={register} control={control} />

        <PassengersCost
          passengers={passengers}
          errors={errors}
          register={register}
          control={control}
          setValue={setValue}
          watch={watch}
          type={type}
        />
        {/* Submit form button */}
        <button
          className={` ${
            isLoading ? "bg-basic cursor-wait" : "bg-basic"
          } h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
          type="submit"
        >
          {isLoading ? "Creating.." : "Create Suggestion"}
        </button>
      </form>
    </SuggestionModelWraper>
  );
}
