import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_INQ_INVOICE_WITH_USER_ID } from "~/graphql/inq_invoices/Query";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import moment from "moment";

const REACT_APP_NHOST_STORAGE_URL = process.env.REACT_APP_NHOST_STORAGE_URL;

export default function Invoices() {
  const { customerId } = useParams();

  const { loading, error, data } = useQuery(GET_INQ_INVOICE_WITH_USER_ID, {
    variables: { userId: customerId },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const invoices = data?.inq_list?.[0]?.inq_invoices || [];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Invoice No</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Invoice PDF</TableCell>
            <TableCell>Invoice Sign</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice: any) => (
            <TableRow key={invoice.invoice_no}>
              <TableCell>{invoice.invoice_no}</TableCell>
              <TableCell>{invoice.amount}</TableCell>
              <TableCell>
                {moment(invoice.created_at).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell>
                {moment(invoice.due_date).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const pdfUrl = `${REACT_APP_NHOST_STORAGE_URL}${invoice.invoice_pdf}`;
                    window.open(pdfUrl, "_blank");
                  }}
                >
                  View PDF
                </Button>
              </TableCell>
              <TableCell>
                {invoice.invoice_sign
                  ? invoice.invoice_sign
                  : "Awaiting for sign"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
