import { useEffect, useState } from "react";
import UserBox from "./components/UserBox";
import UserEdit from "./components/UserEdit";
import { generateRandomId } from "~/utils/generateRandomId";
import SideDrawer from "../common/sideDrawer";

const UserCard = ({
  user,
  className,
  brands,
  editAble,
  pickedBy,
  inquiry,
  transactions,
}: any) => {
  const [edit, setEdit] = useState(false);
  const [online, setOnline] = useState<any>(false);

  return (
    <>
      <div
        className={`relative shadow dark:bg-dark-secondary rounded-2xl flex flex-col items-center justify-end text-left text-xs text-darkslategray h-[380px] ${className}`}
      >
        <UserBox
          user={user}
          setEdit={setEdit}
          editAble={editAble}
          pickedBy={pickedBy}
          inquiry={inquiry}
          transactions={transactions}
        />
        {edit && (
          <SideDrawer
            setSidePopup={setEdit}
            sidePopup={edit}
            component={
              <UserEdit user={user} setEdit={setEdit} brands={brands} />
            }
          />
        )}
      </div>
    </>
  );
};

UserCard.defaultProps = {
  className: "",
  brands: false,
  editAble: true,
};
export default UserCard;
