import React, { useEffect, useState } from "react";
import SidePopup from "~/components/common/SidePopup";
import Detail from "./components/Detail";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_ADS } from "./graphql/Query";
import { useQuery } from "@apollo/client";

import { useUserId } from "@nhost/react";
import Add from "./components/Add/Add";
import brandsStore from "~/store/brandsStore/brandsStore";

export default function GoogleAds() {
  const { brands } = brandsStore();

  const { data } = useQuery(GET_ADS, {
    variables: { brd_ids: brands.map((brand: any) => brand.id) },
  });

  return (
    <>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 divide-gray-200  dark:divide-gray-700 mb-2">
        Google Ads
      </h1>
      {data?.acc_google_ads && data?.acc_google_ads.length > 1 && (
        <Detail data={data?.acc_google_ads} />
      )}
      <SidePopup component={<Add />} text="Add Account" />
    </>
  );
}
