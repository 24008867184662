import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import SuggestionNav from "./components/SuggestionNav";
import TabContentRenderer from "./components/TabContentRenderer";

const TabPanel = ({ children, value, index, ...other }: any) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-full"
    >
      {value === index && <Box sx={{ p: 0, height: "100%" }}>{children}</Box>}
    </div>
  );
};

interface InquiryTabPanelProps {
  value: number;
  tabs: { label: string; details: any[] }[];
  inquiry: any;
}

const NewSuggestionCard: React.FC<InquiryTabPanelProps> = ({
  value,
  tabs,
  inquiry,
}) => {
  const user_id = useUserId();
  const defaultRole = useUserDefaultRole();
  const [active, setActive] = useState<any>(tabs?.[0]?.label);
  const [hotelTabIndex, setHotelTabIndex] = useState(0);

  // Function to get suggestions based on the active tab
  const getSuggestionsForTab = (tabLabel: string) => {
    switch (tabLabel) {
      case "Hotel":
        return (
          inquiry?.stay_inq_details?.[hotelTabIndex]?.stay_inq_suggestions || []
        );

      case "Cars":
        return inquiry?.car_inq_details?.[0]?.car_inq_suggestions || [];
      case "Train":
        return inquiry?.train_inq_details?.[0]?.train_inq_suggestions || [];
      case "Visa":
        return inquiry?.visa_inq_details?.[0]?.visa_inq_suggestions || [];
      case "Flights":
      default:
        return inquiry?.inq_suggestions || [];
    }
  };

  return (
    <>
      {tabs.map((tab, index) => {
        const filteredSuggestions = getSuggestionsForTab(tab.label);

        return (
          <TabPanel key={index} value={value} index={index}>
            <div className="flex flex-col h-full">
              {tab.label === "Hotel" &&
                inquiry?.stay_inq_details?.length > 1 && (
                  <>
                    <Tabs
                      value={hotelTabIndex}
                      onChange={(e, newIndex) => setHotelTabIndex(newIndex)}
                      aria-label="Hotel Sub Tabs"
                      className="mb-4"
                    >
                      {inquiry?.stay_inq_details.map(
                        (detail: any, idx: number) => (
                          <Tab
                            key={idx}
                            label={`Stay ${idx + 1}`}
                            id={`sub-tab-${idx}`}
                            aria-controls={`sub-tabpanel-${idx}`}
                          />
                        )
                      )}
                    </Tabs>
                    <div className="mb-2 pl-2 text-md text-basic dark:text-dark-primary">
                      {`Location: ${
                        inquiry?.stay_inq_details?.[hotelTabIndex]?.location ||
                        "N/A"
                      }`}
                    </div>
                  </>
                )}

              <SuggestionNav
                suggestions={filteredSuggestions}
                active={active}
                setActive={setActive}
                inquiry={inquiry}
                tab={tab.label}
              />

              <div className="self-stretch flex flex-col items-start justify-start">
                <TabContentRenderer
                  tabLabel={tab.label}
                  suggestions={filteredSuggestions}
                  inquiry={inquiry}
                  active={active}
                  setActive={setActive}
                />
              </div>
            </div>
          </TabPanel>
        );
      })}
    </>
  );
};

export default NewSuggestionCard;
