import React, { useEffect, useState } from "react";
import { useQuery, useSubscription } from "@apollo/client";
// import gql queries
import {
  GET_SUB_UNIQUE_INQUIRIES_COUNT,
  GET_UNIQUE_INQUIRIES,
  GET_UNIQUE_INQUIRIES_COUNT,
} from "./graphql/Query";

import InquiryTable from "~/modules/InquiriesModule/components/InquiryTable";
import moment from "moment";
import { Button, Chip, Grow } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import brandsStore from "~/store/brandsStore/brandsStore";
export default function InquiresList({
  selectedStatus,
  selectedFlags,
  dates,
  search,
  allFlags,
  selectedConsultant,
  selectedCustomer,
  source,
  locations,
  travelHouse,
}: any) {
  const [searchParam] = useSearchParams();

  const urlType: any = searchParam.get("type");

  const { brands } = brandsStore();

  const startDateTime = moment(dates?.[0]?.$d).startOf("day").toISOString();
  const endDateTime = moment(dates?.[1]?.$d).endOf("day").toISOString();

  const limit = 20;
  const [page, setPage] = useState(0);

  // const [selectedStatusArr, setSelectedStatusArr] = useState<any>([]);
  const [selectedFlagsArr, setSelectedFlagsArr] = useState<any>([]);
  const [selectedBrandArr, setSelectedBrandArr] = useState<any>([]);

  const condition = {
    _and: [
      selectedStatus?.value !== "completed"
        ? {
            main_inq: {
              created_at: { _gte: startDateTime, _lte: endDateTime },
            },
          }
        : {},
      urlType === "issuedBookings"
        ? {
            main_inq: {
              inq_tickets: {
                status: { _eq: "issued" },
              },
            },
          }
        : {},
      urlType === "totalPending"
        ? {
            main_inq: {
              status: { _nin: ["completed", "refunded"] },
              inq_transections: {
                status: { _in: ["paid", "partiallyRefunded"] },
              },
            },
          }
        : {},
      urlType === "totalBookings"
        ? {
            main_inq: {
              inq_transections: {
                status: { _in: ["paid", "partiallyRefunded"] },
              },
            },
          }
        : {},
      urlType === "todayBookings"
        ? {
            main_inq: {
              inq_transections: {
                status: { _in: ["paid", "partiallyRefunded"] },
                updated_at: {
                  _gte: dayjs().startOf("day").toISOString(),
                  _lte: dayjs().endOf("day").toISOString(),
                },
              },
            },
          }
        : {},
      selectedStatus?.value === "completed"
        ? {
            main_inq: {
              completed_date: { _gte: startDateTime, _lte: endDateTime },
              status: { _eq: selectedStatus?.value },
            },
          }
        : {},
      selectedStatus?.value && selectedStatus?.value !== "completed"
        ? { main_inq: { status: { _eq: selectedStatus?.value } } }
        : {},
      locations?.from ? { main_inq: { from: { _eq: locations.from } } } : {},
      locations?.to ? { main_inq: { to: { _eq: locations.to } } } : {},
      source?.source !== "Organic" && source?.source !== null
        ? { main_inq: { source: { _eq: source?.source } } }
        : {},
      source?.source === "Organic"
        ? { main_inq: { source: { _is_null: true } } }
        : {},
      source?.source_place
        ? { main_inq: { source_place: { _eq: source?.source_place } } }
        : {},
      source?.source_position
        ? { main_inq: { source_position: { _eq: source?.source_position } } }
        : {},
      source?.source_keyword !== "Organic" && source?.source_keyword !== null
        ? { main_inq: { source_keyword: { _eq: source?.source_keyword } } }
        : {},
      source?.source_keyword === "Organic"
        ? { main_inq: { source_keyword: { _is_null: true } } }
        : {},
      source?.source_add_id !== "Organic" && source?.source_add_id !== null
        ? { main_inq: { source_add_id: { _eq: source?.source_add_id } } }
        : {},
      source?.source_add_id === "Organic"
        ? { main_inq: { source_add_id: { _is_null: true } } }
        : {},

      selectedConsultant?.id
        ? { main_inq: { picked_by: { _eq: selectedConsultant?.id } } }
        : {},
      { main_inq: { thp_id: { _eq: travelHouse.id } } },
      { main_inq: { inquiry_no: { _like: `%${search}%` } } },
      { main_inq: { flag_id: { _in: selectedFlagsArr } } },
      { main_inq: { brd_id: { _in: selectedBrandArr } } },
      selectedCustomer?.[0]?.id
        ? { main_inq: { user_id: { _eq: selectedCustomer?.[0].id } } }
        : {},
    ],
  };

  const {
    loading,
    data,
    error,
    refetch: refetchInqs,
  } = useQuery(GET_UNIQUE_INQUIRIES, {
    variables: {
      condition: condition,
      limit,
      offset: page * limit,
    },
    skip: !dates?.[1]?.$d,
  });

  const { data: countData, refetch: refetchInqsCount } = useQuery(
    GET_UNIQUE_INQUIRIES_COUNT,
    {
      variables: {
        condition: condition,
      },
      skip: !dates?.[1]?.$d,
    }
  );

  useEffect(() => {
    const selectedFlagArrLet: any = [];
    if (selectedFlags.length > 0 && selectedFlags[0]?.id) {
      selectedFlags.map((flag: any) => {
        selectedFlagArrLet.push(flag?.id);
      });
    } else {
      allFlags.map((flag: any) => {
        selectedFlagArrLet.push(flag?.id);
      });
    }
    setSelectedFlagsArr([...selectedFlagArrLet]);
  }, [selectedFlags, allFlags]);

  useEffect(() => {
    const brdIds = brands.map((item: any) => {
      return item.id;
    });

    setSelectedBrandArr(brdIds);
  }, [brands]);

  const { data: countSubData } = useSubscription(
    GET_SUB_UNIQUE_INQUIRIES_COUNT,
    {
      variables: {
        condition: condition,
      },
      skip: !dates?.[1]?.$d,
    }
  );

  useEffect(() => {
    refetchInqs();
  }, [countSubData]);

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // if (countLoading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  const inquires = data?.inq_list_unique || [];

  return (
    <>
      {/* <div
        style={
          countSubData?.inq_list_unique_aggregate?.aggregate?.count >
          countData?.inq_list_unique_aggregate?.aggregate?.count
            ? { height: "25px", marginTop: "15px" }
            : { height: "0px" }
        }
        className="transition-all ease-in-out w-full flex justify-center"
      >
        <Grow
          in={
            countSubData?.inq_list_unique_aggregate?.aggregate?.count >
            countData?.inq_list_unique_aggregate?.aggregate?.count
          }
          style={{ transformOrigin: "0 0 0" }}
          {...(countSubData?.inq_list_unique_aggregate?.aggregate?.count >
          countData?.inq_list_unique_aggregate?.aggregate?.count
            ? { timeout: 1000 }
            : {})}
        >
          <Chip
            onClick={() => {
              refetchInqs();
              refetchInqsCount();
            }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#6A0DAD", // Dark purple color
              },
            }}
            color="primary"
            label={`${
              countSubData?.inq_list_unique_aggregate?.aggregate?.count -
              countData?.inq_list_unique_aggregate?.aggregate?.count
            } New Inquiries`}
          />
        </Grow>
      </div> */}

      <InquiryTable
        inquires={inquires || []}
        page={page}
        total={countData?.inq_list_unique_aggregate?.aggregate?.count}
        setPage={setPage}
        travelHouse={travelHouse}
        hide={false}
      />
    </>
  );
}
