import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import { HASURA_KEYS } from "~/config/enums";
import { toast } from "react-toastify";
import brandsStore from "~/store/brandsStore/brandsStore";
import { TextField, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useMutation } from "@apollo/client";
import { ADD_IMPORT_REPORT } from "~/graphql/inq_import_report/Mutation";
import { useUserId } from "@nhost/react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddCsvInq = () => {
  const [userDetail, setUserDetail] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);
  const [timeInterval, setTimeInterval] = useState<any>(1);

  const userId = useUserId();

  const { brands } = brandsStore();
  const { travelHouse }: any = travelHouseStore();

  const [addImportData] = useMutation(ADD_IMPORT_REPORT);

  const handleFileUpload = async (e: any) => {
    try {
      const file1 = e?.target?.files?.[0];
      Papa.parse(file1, {
        header: true,
        complete: (results) => {
          setUserDetail(results.data);
        },
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const processData = async () => {
    setLoading(true);

    if (userDetail.length === 0) {
      toast.error("No data to process");
      setLoading(false);
      return;
    }

    if (!selectedBrand) {
      toast.error("Please select a brand");
      setLoading(false);
      return;
    }

    const now = new Date();
    const usedTimes = new Set(); // To store unique schedule times

    const getUniqueScheduleTime = () => {
      let randomMinutes;
      let uniqueTime;
      do {
        // Generate a random number of minutes between 0 and 180 (3 hours)
        randomMinutes = Math.floor(Math.random() * timeInterval);
        const tempTime = new Date(now);
        tempTime.setMinutes(tempTime.getMinutes() + randomMinutes);
        uniqueTime = tempTime.toISOString(); // Convert to ISO string for uniqueness
      } while (usedTimes.has(uniqueTime)); // Ensure time is unique

      usedTimes.add(uniqueTime); // Add to the set of used times
      return uniqueTime;
    };

    try {
      const res = await addImportData({
        variables: {
          data: {
            total_import: userDetail.length,
            brd_id: selectedBrand?.id,
            created_by: userId,
          },
        },
      });

      for (const user of userDetail) {
        const eventPayload = {
          type: "create_scheduled_event",
          args: {
            webhook:
              "https://www.flygency.com/api/oneOffSheduleEvents/oldInqCreate",
            schedule_at: getUniqueScheduleTime(), // Schedule at current time + 1 minute
            payload: {
              name: user.name,
              email: user.email,
              phone: user.phone,
              brd_id: selectedBrand?.id,
              thp_id: travelHouse.id,
              from: user.from,
              to: user.to,
              departDateTime: user.departDateTime,
              returnDateTime: user.returnDateTime,
              source: user.source,
              season: user.season,
              reportId: res.data?.insert_inq_import_report?.returning?.[0]?.id,
            },
            comment: "One-off scheduled event for Old Inq Creation",
          },
        };

        const response = await axios.post(
          process.env.REACT_APP_NHOST_HASURA_METADATA_URL as string,
          eventPayload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-hasura-admin-secret": HASURA_KEYS.SECRET,
            },
          }
        );

        if (response.data.message === "success") {
          toast.success("Inquiries scheduled successfully");
        }

        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data || error.message);
    }
  };

  return (
    <div className="flex flex-col gap-10 h-full w-full">
      <div className="flex items-center gap-5">
        <Autocomplete
          fullWidth
          value={selectedBrand}
          onChange={(event: any, newValue: string | null) => {
            setSelectedBrand(newValue);
          }}
          getOptionLabel={(option: any) => option?.name}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option?.name}
            </Box>
          )}
          id="controllable-states-demo"
          options={brands || []}
          renderInput={(params) => (
            <TextField {...params} label="Select Brand" />
          )}
        />
        <TextField
          onChange={(e: any) => setTimeInterval(Number(e.target.value))}
          value={timeInterval}
          label="Time Interval (In Minutes)"
        />
      </div>

      <div className="flex items-center justify-center gap-5">
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          fullWidth
          sx={{ minWidth: "200px" }}
        >
          Upload CSV file
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            onChange={handleFileUpload}
          />
        </Button>
        <Button
          fullWidth
          sx={{ maxWidth: "300px" }}
          variant="outlined"
          onClick={() => {
            processData();
          }}
        >
          {loading ? "Adding..." : "Add Data"}
        </Button>
      </div>
    </div>
  );
};

export default AddCsvInq;
