export const PassengersType = [
  {
    id: 1,
    title: "Adult",
    value: "adult",
  },
  {
    id: 2,
    title: "Youth",
    value: "youth",
  },
  {
    id: 3,
    title: "Child",
    value: "child",
  },
  {
    id: 4,
    title: "Infant",
    value: "infant",
  },
];

export const PassengersGenders = [
  {
    id: 1,
    title: "Male",
    value: "male",
  },
  {
    id: 2,
    title: "Female",
    value: "female",
  },
];

export const RoomTypes = [
  {
    id: 1,
    title: "Single",
    value: "single"
  },
  {
    id: 2,
    title: "Double",
    value: "double"
  },
  {
    id: 3,
    title: "Deluxe",
    value: "deluxe"
  },
  {
    id: 4,
    title: "Suite",
    value: "suite"
  },
]
