import React, { useState } from "react";
import { Box, FormControl, Tab, Tabs, Typography } from "@mui/material";
import add from "../../assets/img/Add.svg";
import SideDrawer from "~/components/common/sideDrawer";
import ExtendInquiry from "../../../InquiryAdd/NewInquiryAdd/ExtendInquiry";
import GeneralModelWrapper from "~/components/common/GeneralModelWrapper";
import { options } from "../../../InquiryAdd/data/options";

const InquiryTabs = ({ value, tabs, handleChange, inquiryId, inquiryPassengers }: any) => {
  const [show, setShow] = useState(false);

  const handleExtended = () => {
    setShow(true);
  };

  const handleExtendClose = () => setShow(false);

  const optionNames = options.map((option) => option.name);

  const allOptionsInTabs = optionNames.every((name) =>
    tabs.some((tab: any) => tab.label === name)
  );

  return (
    <>
      <FormControl fullWidth>
        <div className="w-full flex items-center justify-between bg-white dark:bg-dark-secondary shadow mt-4 p-1">
          {tabs.length === 0 ? (
            <Typography className="p-3">No Details Available</Typography>
          ) : (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="inquiry tabs"
              sx={{ flexGrow: 1 }}
            >
              {tabs.map((tab: any, index: any) => (
                <Tab key={index} label={tab.label} />
              ))}
            </Tabs>
          )}
          {!allOptionsInTabs && (
            <img
              src={add}
              alt="Add Suggestion"
              title="Add Suggestion"
              className="cursor-pointer"
              style={{
                marginRight: "10px",
              }}
              onClick={handleExtended}
            />
          )}
        </div>
      </FormControl>
      {show && (
        <SideDrawer
          component={
            <ExtendInquiry defaultTabs={tabs} inquiryId={inquiryId} inquiryPassengers={inquiryPassengers} setSidePopup={setShow} />
          }
          sidePopup={show}
          setSidePopup={setShow}
        />
      )}
    </>
  );
};

export default InquiryTabs;
