import { gql } from "@apollo/client";

export const DELETE_PASSENGER = gql`
  mutation DeletePassenger($id: uuid!) {
    delete_inq_passengers_by_pk(id: $id) {
      id
    }
  }
`;

export const DELETE_STAY_ROOMS = gql`
  mutation DeleteStayRoom($id: uuid!) {
    delete_stay_inq_rooms_by_pk(id: $id) {
      id
    }
  }
`;
