import { gql } from "@apollo/client";

export const GET_BRAND_DETAIL = gql`
  query GetBrdList($brd_id: uuid) {
    brd_list(where: { id: { _eq: $brd_id } }) {
      gmail_refresh_token
      id
      whatsapp_token
      phoneNumberId
      gmail_refresh_token
      brand_type
      domain
      subdomain
      brd_secrets {
        gsc_private_key
        gsc_email
      }
    }
  }
`;

export const GET_BRD_WITH_TYPES = gql`
  query getBrdWithTypes($thpId: uuid, $brdTypeId: uuid) {
    brd_list(
      where: { thp_id: { _eq: $thpId }, brand_type: { _eq: $brdTypeId } }
    ) {
      id
      name
      phoneNumberId
    }
  }
`;
