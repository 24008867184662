import { useEffect, useState } from "react";
import { Divider, TextField, Button } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm, Controller } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import TravelersPopover from "~/components/FormInputs/TravelersForm";
import {
  INSERT_TRAIN_PASSENGER,
  DELETE_TRAIN_PASSENGER,
} from "~/graphql/train_inq_passengers/Mutation";
import { UPDATE_TRAIN_INQUIRY_DETAIL } from "~/graphql/train_inq_detail/Mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

type TravelerCategory = "children" | "infantsOnLap" | "infantsInSeat";

const EditTrain = ({
  setSidePopup,
  trainId,
  inqId,
  origin,
  destination,
  date,
  passengers,
}: any) => {
  console.log("jsdd", trainId);
  console.log("sdnjd", passengers);
  const today = dayjs();

  const Adults = passengers.filter(
    (passenger: any) => passenger.type === "adult"
  ).length;
  const Children = passengers.filter(
    (passenger: any) => passenger.type === "child"
  ).length;
  const Infants = passengers.filter(
    (passenger: any) => passenger.type === "infant"
  ).length;

  const [updateTrainDetail] = useMutation(UPDATE_TRAIN_INQUIRY_DETAIL);
  const [insertTrainPassenger] = useMutation(INSERT_TRAIN_PASSENGER);
  const [deleteTrainPassenger] = useMutation(DELETE_TRAIN_PASSENGER);

  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [adults, setAdults] = useState(Adults);
  const [children, setChildren] = useState(Children);
  const [infantsOnLap, setInfantsOnLap] = useState(Infants);
  const [travelers, setTravelers] = useState<{
    [key in TravelerCategory]: string[];
  }>({
    children: Array(children).fill(""),
    infantsOnLap: Array(infantsOnLap).fill(""),
    infantsInSeat: [],
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      train: {
        Origin: origin || "",
        Destination: destination || "",
        Date: date ? dayjs(date) : null,
        Travelers: {
          adults: Adults,
          children: Children,
          infantsOnLap: Infants,
        },
      },
    },
  });

  useEffect(() => {
    // Reset form values when props change
    reset({
      train: {
        Origin: origin || "",
        Destination: destination || "",
        Date: date ? dayjs(date) : null,
        Travelers: {
          adults: Adults,
          children: Children,
          infantsOnLap: Infants,
        },
      },
    });

    setAdults(Adults);
    setChildren(Children);
    setInfantsOnLap(Infants);
    setTravelers({
      children: Array(Children).fill(""),
      infantsOnLap: Array(Infants).fill(""),
      infantsInSeat: [],
    });
  }, [origin, destination, date, Adults, Children, Infants, reset]);

  const handleTravelersClick = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleTravelersClose = () => {
    setAnchorEl2(null);
  };

  const handleAgeChange = (
    category: TravelerCategory,
    index: number,
    value: string
  ) => {
    setTravelers((prevState) => {
      const updatedArray = [...prevState[category]];
      updatedArray[index] = value;
      return { ...prevState, [category]: updatedArray };
    });

    // setValue(`train.Travelers.${category}.${index}`, value);
  };

  const updateTravelerCount = (
    type: "adults" | "children" | "infantsOnLap" | "youth",
    action: "increment" | "decrement"
  ) => {
    let newCount;
    if (type === "adults") {
      newCount = action === "increment" ? adults + 1 : Math.max(adults - 1, 0);
      setAdults(newCount);
      setValue(`train.Travelers.adults`, newCount);
    } else if (type === "children") {
      newCount =
        action === "increment" ? children + 1 : Math.max(children - 1, 0);
      setChildren(newCount);
      setValue(`train.Travelers.children`, newCount);
    } else if (type === "infantsOnLap") {
      newCount =
        action === "increment"
          ? infantsOnLap + 1
          : Math.max(infantsOnLap - 1, 0);
      setInfantsOnLap(newCount);
      setValue(`train.Travelers.infantsOnLap`, newCount);
    }

    setTravelers({
      children: Array(newCount).fill(""),
      infantsOnLap: Array(newCount).fill(""),
      infantsInSeat: [],
    });
  };

  const onSubmit = async (data: any) => {
    const updatedTravelers = data.train.Travelers;
    const newAdults = updatedTravelers.adults;
    const newChildren = updatedTravelers.children;
    const newInfants = updatedTravelers.infantsOnLap;

    const deletePromises: any[] = [];
    const insertPromises: any[] = [];

    if (newAdults < Adults) {
      const adults = passengers.filter(
        (passenger: any) => passenger.type === "adult"
      );
      const filteredAdults = adults
        .slice(0, Adults - newAdults)
        ?.filter((item: any) => item.id);
      const idsToDelete = filteredAdults.map((item: any) => item.id);

      deletePromises.push(
        deleteTrainPassenger({
          variables: {
            ids: idsToDelete,
          },
        })
      );
    }

    if (newChildren < Children) {
      const children = passengers.filter(
        (passenger: any) => passenger.type === "child"
      );
      const filteredChildren = children
        .slice(0, Children - newChildren)
        ?.filter((item: any) => item.id);
      const idsToDeleteChildren = filteredChildren.map((item: any) => item.id);

      deletePromises.push(
        deleteTrainPassenger({
          variables: {
            ids: idsToDeleteChildren,
          },
        })
      );
    }

    if (newInfants < Infants) {
      const infants = passengers.filter(
        (passenger: any) => passenger.type === "infant"
      );
      const filteredInfants = infants
        .slice(0, Infants - newInfants)
        ?.filter((item: any) => item.id);
      const idsToDeleteInfants = filteredInfants.map((item: any) => item.id);

      deletePromises.push(
        deleteTrainPassenger({
          variables: {
            ids: idsToDeleteInfants,
          },
        })
      );
    }

    if (newAdults > Adults) {
      const newAdultRows = Array(newAdults - Adults)
        .fill(null)
        .map(() => ({
          type: "adult",
          train_inq_id: trainId,
        }));

      insertPromises.push(
        insertTrainPassenger({
          variables: {
            data: newAdultRows,
          },
        })
      );
    }

    if (newChildren > Children) {
      const newChildRows = Array(newChildren - Children)
        .fill(null)
        .map(() => ({
          train_inq_id: trainId,
          type: "child",
        }));

      insertPromises.push(
        insertTrainPassenger({
          variables: {
            data: newChildRows,
          },
        })
      );
    }

    if (newInfants > Infants) {
      const newInfantRows = Array(newInfants - Infants)
        .fill(null)
        .map(() => ({
          train_inq_id: trainId,
          type: "infant",
        }));

      insertPromises.push(
        insertTrainPassenger({
          variables: {
            data: newInfantRows,
          },
        })
      );
    }

    try {
      await Promise.all(deletePromises);
      await Promise.all(insertPromises);

      const res = await updateTrainDetail({
        variables: {
          id: trainId,
          origin: data.train.Origin,
          destination: data.train.Destination,
          date: data.train.Date?.$d,
        },
      });

      if (res?.data?.update_train_inq_detail?.affected_rows > 0) {
        toast.success("Train Details Updated Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setSidePopup(false);
      }
    } catch (error) {
      toast.error("Error Updating Train Details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.error("Error:", error);
    }
  };

  return (
    <div className="min-w-[500px] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
          Edit Train Details
        </h2>
        <Divider />
        <div className="grid grid-cols-1 mt-10 gap-5">
          <Controller
            control={control}
            name="train.Origin"
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Origin"
                variant="outlined"
                {...field}
                error={!!error}
                helperText={error ? "This field is required" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="train.Destination"
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Destination"
                variant="outlined"
                {...field}
                error={!!error}
                helperText={error ? "This field is required" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="train.Date"
            rules={{ required: "This field is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Date"
                  value={value}
                  onChange={(newValue) => onChange(newValue)}
                  minDate={today}
                />
                {error && (
                  <span className="text-red-500 text-xs">{error.message}</span>
                )}
              </LocalizationProvider>
            )}
          />
          <Controller
            control={control}
            name="train.Travelers"
            render={({ field }) => (
              <TravelersPopover
                {...field}
                open={Boolean(anchorEl2)}
                anchorEl={anchorEl2}
                handleClose={handleTravelersClose}
                handleClick={handleTravelersClick}
                adults={adults}
                setAdults={setAdults}
                setChildren={setChildren}
                setInfantsOnLap={setInfantsOnLap}
                children={children}
                infantsOnLap={infantsOnLap}
                travelers={travelers}
                handleAgeChange={handleAgeChange}
                ageOptions={Array.from({ length: 18 }, (_, i) => i.toString())}
                infantOptions={["0", "1"]}
                popoverWidth="400px"
                label="Travelers"
                updateTravelerCount={updateTravelerCount}
              />
            )}
          />
        </div>
        <div className="mt-5">
          <Button fullWidth variant="contained" type="submit" color="primary">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditTrain;
