import { gql } from "@apollo/client";

export const INSERT_BRD_GSC_PAGE_INFO = gql`
  mutation MyMutation($brd_id: uuid!, $page_type: String!, $page_url: String!) {
    insert_brd_gsc_page_info(
      objects: { brd_id: $brd_id, page_type: $page_type, page_url: $page_url }
    ) {
      affected_rows
    }
  }
`;
