import { Avatar, Box, Button, Paper, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import SideDrawer from "~/components/common/sideDrawer";
import AddPaymentMethod from "../PaymentMethodAdd/addPaymentMethod";

const PaymentMethodTable = ({ data }: any) => {
  const [sidePopup, setSidePopup] = useState(false);
  const [defData, setDefData] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "brand",
      headerName: "BRAND",
      width: 150,
    },
    {
      field: "paymentMethodType",
      headerName: "TYPE",
      width: 200,
    },
    {
      field: "PaymentMethodName",
      headerName: "NAME",
      width: 200,
    },
    {
      field: "accountTitle",
      headerName: "TITLE",
      width: 250,
    },
    {
      field: "accountNumber",
      headerName: "ACCOUNT NUMBER",
      width: 200,
    },
    {
      field: "sortCode",
      headerName: "SORT CODE",
      width: 200,
    },
    {
      field: "stripePublicKey",
      headerName: "STRIPE PK",
      width: 150,
    },
    {
      field: "stripeSecretKey",
      headerName: "STRIPE SK",
      width: 150,
    },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "actions",
      headerName: "ACTIONS",
      width: 150,
      renderCell: (params: any) => (
        <div className="flex items-center justify-center gap-x-10">
          <Button
            variant="outlined"
            onClick={() => {
              //
              setDefData(params?.row?.actions);
              setSidePopup(true);
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const rows = [
    ...data.map((user: any, index: any) => ({
      id: index + 1,
      brand: user?.brd_list?.name,
      paymentMethodType: user?.type,
      PaymentMethodName: user?.name,
      accountTitle: user?.account_title,
      accountNumber: user?.account_no,
      sortCode: user?.swift_code,
      stripePublicKey: user?.stripe_pub,
      stripeSecretKey: user?.stripe_secret,
      status: user.status,
      actions: user,
    })),
  ];

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <Box>
      <DataGrid
        sx={
          darkMode
            ? {
                border: "none",
                backgroundColor: "#312D4B",
                boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
                borderRadius: "6px",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                outline: "none",
              }
            : {
                border: "none",
                backgroundColor: "white",
                boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
                borderRadius: "6px",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                outline: "none",
              }
        }
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
      <SideDrawer
        setSidePopup={setSidePopup}
        sidePopup={sidePopup}
        component={<AddPaymentMethod type="edit" defData={defData} />}
      />
    </Box>
  );
};

export default PaymentMethodTable;
