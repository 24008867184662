import React from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { INSERT_BRD_SECRETS, UPDATE_BRD_SECRETS } from "./graphql/Mutation";
import { toast } from "react-toastify";

const BrdSecrets = ({ brand }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [insertBrdSecrets] = useMutation(INSERT_BRD_SECRETS);
  const [updateBrdSecrets] = useMutation(UPDATE_BRD_SECRETS);

  const onSubmit = async (data: any) => {
    try {
      let response;
      if (brand?.brd_secrets?.[0]) {
        // Update existing secrets
        response = await updateBrdSecrets({
          variables: {
            id: brand.brd_secrets[0].brd_id,
            data: {
              gsc_email: data.gsc_email,
              gsc_private_key: data.gsc_private_key,
            },
          },
        });

        if (response.data.update_brd_secrets?.affected_rows > 0) {
          toast.success("Secrets updated successfully!");
        } else {
          toast.error("Failed to update secrets.");
        }
      } else {
        // Insert new secrets
        response = await insertBrdSecrets({
          variables: {
            data: {
              brd_id: brand?.id,
              gsc_email: data.gsc_email,
              gsc_private_key: data.gsc_private_key,
            },
          },
        });

        if (response.data.insert_brd_secrets?.affected_rows > 0) {
          toast.success("Secrets inserted successfully!");
        } else {
          toast.error("Failed to insert secrets.");
        }
      }
    } catch (error) {
      toast.error("An error occurred while saving secrets.");
      console.error(error);
    }
  };

  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
      <h1 className="text-xl">Integrate Secrets</h1>
      <div className="mt-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-5">
            <TextField
              defaultValue={brand?.brd_secrets?.[0]?.gsc_email || ""}
              placeholder="Enter your GSC Email here"
              error={errors["gsc_email"] ? true : false}
              {...register("gsc_email", { required: true })}
              label="GSC Email"
              type="text"
              fullWidth
            />
            <TextField
              defaultValue={brand?.brd_secrets?.[0]?.gsc_private_key || ""}
              placeholder="Enter your GSC Private Key here"
              error={errors["gsc_private_key"] ? true : false}
              {...register("gsc_private_key", { required: true })}
              label="GSC Private Key"
              type="text"
              fullWidth
            />
          </div>
          <Button variant="contained" type="submit" sx={{ marginTop: "20px" }}>
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default BrdSecrets;
