import moment from "moment";
import { FunctionComponent, useState } from "react";
import { URLs } from "~/config/enums";
import AirlineLogoImage from "~/utils/airlineLogoImage";
import vector81 from "./images/dottedImg.svg";
import vector720 from "./images/vector-720.svg";
import vector721 from "./images/vector-721.svg";
import vector1 from "./images/vector-1.svg";
import vector from "./images/vector.svg";
import airlineData from "~/components/FormInputs/AirlineBox/data/airlines.json";
import airportData from "~/components/FormInputs/LocationBox/data/airports.json";
import InvoiceSuggestion from "./components/FlightDetails";
import FlightInvoiceSuggestion from "./components/FlightDetails";
import StayInvoiceDetails from "./components/StayDetails";
import CarInvoiceDetails from "./components/CarDetails";
import TrainInvocieDetails from "./components/TrainDetails";
import InvoiceDetailPage from "./components/InvoiceDetailsPage";
import InvoiceCostDetail from "./components/InvoiceCostDetail";

const NewInvoiceP = ({
  className = "",
  inquiry,
  invoice,
  signedImage,
  tab,
}: any) => {
  const [sales, setSales] = useState<number[]>([]);
  const timeDuration = (timestamp1: any, timestamp2: any) => {
    const start = moment(timestamp1);
    const end = moment(timestamp2);
    const duration = moment.duration(end.diff(start));

    // Extract hours and minutes
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    // Output
    // ;
    return { hours, minutes };
  };

  const flightCosts =
    invoice?.invoice_suggestions
      ?.filter((suggestion: any) => suggestion.type === "flights")
      ?.flatMap((suggestion: any) => suggestion?.inq_suggestion || []) || [];
  return (
    <div
      className={`w-full bg-[#F9FAFC] max-w-full overflow-hidden flex flex-col items-start justify-start py-6 px-4 box-border gap-[19px] leading-[normal] tracking-[normal] ${className}`}
    >
      <header className="self-stretch flex flex-row flex-wrap items-start justify-start max-w-full text-left text-3xs text-gray-600 font-inter">
        <div className="flex-1 flex flex-row flex-wrap items-center justify-between max-w-full gap-[-2px]">
          <div className="w-[219px] flex flex-row items-center justify-start gap-[20px]">
            <img
              className="h-12 w-12 relative"
              loading="lazy"
              alt=""
              src={
                URLs.FILE_URL + inquiry?.brd_list?.brd_details?.[0]?.fav_icon
              }
            />
            <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
              <a
                style={{
                  color: inquiry?.brd_list?.brd_details?.[0]?.secondary_color,
                }}
                className="[text-decoration:none] self-stretch relative text-[18px] font-semibold  whitespace-nowrap"
              >
                {inquiry?.brd_list?.name}
              </a>
              <div className="relative leading-[14px] inline-block min-w-[102px]">
                www.{inquiry?.brd_list?.domain}
              </div>
              <div className="relative leading-[14px] inline-block min-w-[128px] whitespace-nowrap">
                {inquiry?.brd_list?.brd_contact_details?.[0]?.email}
              </div>
              <div className="relative leading-[14px] inline-block min-w-[75px] whitespace-nowrap">
                {inquiry?.brd_list?.brd_contact_details?.[0]?.phone}
              </div>
            </div>
          </div>
          <div className="w-[200px] flex flex-row items-center justify-end max-w-full text-right mq750:hidden mr-[10px]">
            <div className="relative leading-[14px]">
              <p className="m-0">
                {inquiry?.brd_list?.brd_contact_details?.[0]?.address1}
              </p>
              <p className="m-0">
                {inquiry?.brd_list?.brd_contact_details?.[0]?.city}{" "}
                {inquiry?.brd_list?.brd_contact_details?.[0]?.country}
              </p>
            </div>
          </div>
        </div>
      </header>
      <section className="break-after-page self-stretch rounded-xl bg-white flex flex-col items-start justify-start py-[19px] px-3.5 gap-[26px] text-left text-3xs text-gray-600 font-inter border-[0.5px] border-solid border-gray-100">
        <div className="self-stretch flex flex-row flex-wrap items-start justify-between gap-[20px]">
          <div className="flex flex-col items-start justify-start gap-[4px]">
            <a className="[text-decoration:none] relative leading-[14px] font-medium text-[inherit] inline-block min-w-[39px]">
              Billed to
            </a>
            <div className="flex flex-col items-start justify-start gap-[2px]">
              <div className="relative leading-[14px] font-semibold text-gray-900">
                <p className="m-0">{inquiry?.users?.displayName}</p>
              </div>
              <div className="relative leading-[14px]">
                <p className="m-0">
                  {inquiry?.users?.metadata?.addressLine1}{" "}
                  {inquiry?.users?.metadata?.addressLine2}{" "}
                  {inquiry?.users?.metadata?.postalCode}{" "}
                  {inquiry?.users?.metadata?.city}{" "}
                </p>
              </div>
              <div className="relative leading-[14px] inline-block min-w-[71px]">
                {inquiry?.users?.phoneNumber}
              </div>
              <div className="relative leading-[14px] inline-block min-w-[71px]">
                {inquiry?.users?.email}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-between min-h-[102px]">
            <div className="flex flex-col items-start justify-start gap-[4px]">
              <div className="relative leading-[14px] font-medium inline-block min-w-[74px]">
                Invoice number
              </div>
              <b className="relative leading-[14px] font-semibold text-gray-900 inline-block min-w-[56px]">
                #{invoice?.invoice_no}
              </b>
            </div>
            {invoice?.invoice_suggestion?.pnr && (
              <div className="flex flex-col items-start justify-start gap-[4px]">
                <div className="relative leading-[14px]">
                  <p className="m-0 font-medium">Booking Confirmation</p>
                  <p className="m-0">
                    <span className="font-medium">{`Number `}</span>
                    <b className="font-inter">(PNR)</b>
                  </p>
                </div>
                <b className="w-[41px] relative leading-[14px] font-semibold text-gray-900 inline-block">
                  {invoice?.invoice_suggestion?.pnr}
                </b>
              </div>
            )}
          </div>
          <div className="flex flex-col items-end justify-start gap-[2px] text-right">
            <div className="w-[78px] relative leading-[14px] font-medium inline-block min-w-[78px]">
              Invoice of (GBP)
            </div>
            <b
              style={{
                color: inquiry?.brd_list?.brd_details?.[0]?.secondary_color,
              }}
              className="relative text-[20px] leading-[28px] font-bold inline-block min-w-[105px] whitespace-nowrap mq450:text-base mq450:leading-[22px]"
            >
              £{invoice?.amount}
            </b>
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-center justify-between gap-[20px]">
          <div className="w-[127px] flex flex-col items-start justify-start gap-[4px]">
            <div className="relative leading-[14px] font-medium inline-block min-w-[37px]">{`Subject `}</div>
            <div className="self-stretch relative leading-[14px] font-semibold text-gray-900">
              Booking Confirmation Invoice 
            </div>
          </div>
          <div className="w-[104px] flex flex-col items-start justify-start py-0 pr-5 pl-0 box-border gap-[4px]">
            <div className="relative leading-[14px] font-medium inline-block min-w-[59px]">
              Invoice date
            </div>
            <div className="relative leading-[14px] font-semibold text-gray-900 inline-block min-w-[65px]">
              {moment(invoice?.created_at).format("DD MMM YYYY")}
            </div>
          </div>
          <div className="flex flex-col items-end justify-start py-0 pr-0 pl-[38px] gap-[4px] text-right">
            <div className="relative leading-[14px] font-medium inline-block min-w-[44px]">
              Due date
            </div>
            <div className="relative leading-[14px] font-semibold text-gray-900 inline-block min-w-[67px]">
              {moment(invoice?.due_date).format("DD MMM YYYY")}
            </div>
          </div>
        </div>
        {/* footer part */}
        <footer className="self-stretch flex flex-col items-end justify-start gap-[12px] text-left text-3xs text-gray-900 font-inter ">
          <div className="self-stretch flex flex-col items-end justify-start pt-2 px-0 pb-[7px] gap-[7px] border-b-[0.5px] border-solid border-[#D7DAE0]">
            {/* <div className="self-stretch flex flex-row items-start justify-between py-1 px-0 gap-[20px] text-5xs text-gray-600 border-t-[0.5px] border-solid border-[#D7DAE0] border-b-[0.5px] mq450:flex-wrap">
              <b className="relative tracking-[0.04em] min-w-[50px] leading-[12px] uppercase font-semibold inline-block ">
                Passanger
              </b>
              <b className="relative tracking-[0.04em]  min-w-[60px] leading-[12px] uppercase font-semibold inline-block ">
                Type
              </b>
              <b className="relative tracking-[0.04em]  min-w-[50px] leading-[12px] uppercase font-semibold inline-block ">
                Service
              </b>

              <b className="relative tracking-[0.04em] leading-[12px] min-w-[50px] uppercase font-semibold text-right inline-block shrink-0">
                Amount
              </b>
            </div> */}
            <div className="self-stretch grid grid-cols-4 items-start justify-between py-1 px-0 gap-[20px] text-5xs text-gray-600 border-t-[0.5px] border-solid border-[#D7DAE0] border-b-[0.5px]">
              <b className="relative tracking-[0.04em] min-w-[50px] leading-[12px] uppercase font-semibold inline-block">
                Passanger
              </b>
              <b className="relative tracking-[0.04em] min-w-[60px] leading-[12px] uppercase font-semibold inline-block">
                Type
              </b>
              <b className="relative tracking-[0.04em] min-w-[50px] leading-[12px] uppercase font-semibold inline-block">
                Service
              </b>
              <b className="relative tracking-[0.04em] leading-[12px] min-w-[50px] uppercase font-semibold text-right inline-block shrink-0">
                Amount
              </b>
            </div>
            <InvoiceCostDetail invoice={invoice} />
          </div>

          <div className="w-60 flex flex-col items-start justify-start gap-[7px]">
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
              <div className="relative leading-[14px] font-medium inline-block min-w-[41px]">
                Subtotal
              </div>
              <div className="relative leading-[14px] font-medium text-right inline-block min-w-[50px] whitespace-nowrap">
                £{/* {sales.reduce((a, b) => a + b, 0)} */}
                {invoice?.amount - flightCosts?.[0]?.bookingFee}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
              <div className="relative leading-[14px] font-medium inline-block min-w-[59px]">
                Booking Fee
              </div>
              <div className="relative leading-[14px] font-medium text-right inline-block min-w-[35px] whitespace-nowrap">
                £{flightCosts?.[0]?.bookingFee}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
              <div className="relative leading-[14px] font-medium inline-block min-w-[56px]">{`Grand Total `}</div>
              <div className="relative leading-[14px] font-medium text-right inline-block min-w-[48px] whitespace-nowrap">
                £{invoice?.amount}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
              <b className="relative leading-[14px] font-bold inline-block min-w-[22px]">
                Paid
              </b>
              <b className="relative leading-[14px] font-bold text-right inline-block min-w-[49px] whitespace-nowrap">
                £
                {inquiry?.inq_transections
                  ?.filter((transaction: any) =>
                    ["paid", "partiallyRefunded"].includes(transaction.status)
                  )
                  ?.map((transaction: any) =>
                    transaction.status === "partiallyRefunded"
                      ? transaction.amount - transaction.refund_amount
                      : transaction.amount
                  )
                  ?.reduce((sum: number, amount: number) => sum + amount, 0)}
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between pt-[5px] px-0 pb-1.5 gap-[20px] border-t-[0.5px] border-solid border-[#D7DAE0]">
              <b className="relative leading-[14px] font-bold inline-block min-w-[61px]">
                Balance Due
              </b>
              <b className="relative leading-[14px] font-bold text-right inline-block min-w-[50px] whitespace-nowrap">
                £
                {invoice?.amount -
                  inquiry?.inq_transections
                    ?.filter((transaction: any) =>
                      ["paid", "partiallyRefunded"].includes(transaction.status)
                    )
                    ?.map((transaction: any) =>
                      transaction.status === "partiallyRefunded"
                        ? transaction.amount - transaction.refund_amount
                        : transaction.amount
                    )
                    ?.reduce((sum: number, amount: number) => sum + amount, 0)}
              </b>
            </div>
          </div>
        </footer>
        {inquiry?.inq_transections?.filter((transaction: any) =>
          ["paid", "partiallyRefunded"].includes(transaction.status)
        )?.length > 0 && (
          <div className="self-stretch flex flex-col items-start justify-start gap-[7px] text-5xs">
            <div className="self-stretch flex flex-row items-center justify-between py-[7px] px-0 gap-[20px] border-t-[0.5px] border-solid border-[#D7DAE0] border-b-[0.5px] mq450:flex-wrap">
              <b className="relative tracking-[0.04em] leading-[12px] uppercase font-semibold inline-block min-w-[79px]">
                Transactions id
              </b>
              <b className="relative tracking-[0.04em] leading-[12px] uppercase font-semibold inline-block min-w-[69px]">
                Payment mode
              </b>
              <b className="w-[50px] relative tracking-[0.04em] leading-[12px] uppercase font-semibold text-right inline-block shrink-0">
                Amount
              </b>
            </div>
            {inquiry?.inq_transections
              ?.filter((transaction: any) =>
                ["paid", "partiallyRefunded"].includes(transaction.status)
              )
              .map((item: any) => {
                return (
                  <div
                    className="self-stretch flex flex-row items-center justify-between gap-[20px] text-3xs text-gray-900 mq450:flex-wrap"
                    key={item.transactions_no}
                  >
                    <div className="w-[79px] relative leading-[14px] flex items-center shrink-0">
                      #{item.transactions_no}
                    </div>
                    <div className="w-[69px] relative leading-[14px] flex items-center shrink-0">
                      {item.acc_payment_method.type}
                    </div>
                    <div className="w-[50px] relative leading-[14px] font-medium text-right flex items-center shrink-0 min-w-[50px] whitespace-nowrap">
                      £
                      {item.status === "partiallyRefunded"
                        ? item.amount - item.refund_amount
                        : item.amount}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </section>
      <>
        <InvoiceDetailPage invoice={invoice} />
      </>
      <section className="self-stretch rounded-xl bg-white box-border flex flex-col items-start justify-start py-[19px] px-3.5 max-w-full text-left text-3xs text-gray-600 font-inter border-[0.5px] border-solid border-gray-100">
        <div className="self-stretch flex flex-col items-start justify-start gap-[10px] max-w-full">
          <div className="relative leading-[14px] font-medium inline-block min-w-[94px]">{`Terms & Conditions`}</div>
          <div className="self-stretch flex flex-row items-center justify-center max-w-full text-gray-900">
            <div className="flex-1 relative leading-[14px] flex items-end max-w-full">
              <span>
                <p className="m-0">&nbsp;</p>
                <p className="mt-0">
                  Please read these carefully as the person making this booking
                  (either for him selves or for any other passenger) accepts all
                  the below terms and conditions.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  <b>{`DEPOSITS AND TICKETS ARE NEITHER REFUNDABLE NOR CHANGEABLE (Terms & Conditions May Apply).`}</b>
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Unless Specified, All the deposits paid and tickets purchased
                  / issued are non refundable in case of cancellation or no show
                  (Failure to arrive at departure airport on time) and non
                  changeable before or after departure (date change is not
                  permitted). Once flights reserved, bookings / tickets are
                  non-transferable to any other person means that name changes
                  are not permitted. Issued Ticket are also not re-routable.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  If you are reserving the flight by making the advance partial
                  payment (Initial deposit) then please note that fare/taxes may
                  increase at any time without the prior notice. Its means the
                  price is not guaranteed unless ticket is issued because
                  airline / consolidator has right to increase the price due to
                  any reason. In that case we will not be liable and passenger
                  has to pay the fare/tax difference. We always recommend you to
                  pay ASAP and get issue your ticket to avoid this situation.
                  Further more if you will cancel your reservation due to any
                  reason, then the paid deposit(s) will not be refunded.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Regardless of any reason, 75GBP per person will be charged, if
                  you will cancel your reservation before ticket issuance. After
                  issuance all payments are non-refundable.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  <b>{`CHECKING ALL FLIGHT DETAILS & PASSENGER NAME(S)`}</b>
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">{`It is your responsibility to check all the details are correct i.e. Passenger names (are same as appearing on passport / travel docs), Travelling dates, Transit Time, Origin & Destination, Stop Over, Baggage Allowance and other flight information. Once the ticket is issued then no changes can be made.`}</p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  <b>{`PASSPORT, VISA AND IMMIGRATION REQUIREMENTS `}</b>
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  You are responsible for checking all these items like
                  Passport, Visa (including Transit Visa) and other immigration
                  requirements. You must consult with the relevant Embassy /
                  Consulate, well before the departure time for the up to date
                  information as requirements may change time to time. We can
                  not accept any liability of any transit visa and if you are
                  refused the entry onto the flight or into any country due to
                  failure on your part to carry the correct passport, visa or
                  other documents required by any airline, authority or country.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  <b>RECONFIRMING RETURN/ONWARD FLIGHTS</b>
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  It is your responsibility to RECONFIRM your flights at least
                  72 hours before your departure time either with your travel
                  agent or the relevant Airline directly. The company will not
                  be liable for any additional costs due to your failure to
                  reconfirm your flights.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  <b>INSURANCE AND BAGGAGE LOSS</b>
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  We recommend that you purchase travel insurance. It is your
                  responsibility to ensure you have valid travel insurance that
                  covers your needs and also ensure that you have complied with
                  all the health and vaccination requirements for the countries
                  you are travelling. Advice can be obtained from your GP or
                  travel clinic. We don not accept any claim for the lost /
                  Stolen / Damaged Baggage. you have to contact the relevant
                  airline directly in that case.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  <b>SPECIAL REQUESTS AND MEDICAL PROBLEMS</b>
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  If you have any special requests like meal preference, Seat
                  Allocation and wheel chair request etc, please advise us at
                  time of issuance of ticket.We will try our best to ful fill
                  these by passing this request to relevant airline but we
                  cannot guarantee and failure to meet any special request will
                  not held us liable for any claim.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  <b>VERY IMPORTANT</b>
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  We do not accept responsibility for any financial loss if the
                  airline fail to operate. Passengers will be solely responsible
                  for that so it is highly recommended that separate travel
                  insurance must be arranged to protect yourself.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  We advise you to read our complete terms and conditions
                  mentioned at on our website. 
                </p>
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="self-stretch rounded-xl bg-white flex flex-col items-end justify-center py-[9px] px-[15px] text-left text-3xs text-black font-inter border-[0.5px] border-solid border-gray-100">
        <div className="w-[180px] flex flex-col items-center justify-center">
          <div className="self-stretch rounded-xl bg-whitesmoke flex flex-col items-center justify-center py-[5px] px-5">
            {invoice?.invoice_sign && (
              <img
                className="self-stretch h-[46px] relative max-w-full overflow-hidden shrink-0"
                alt=""
                src={URLs.FILE_URL + invoice?.invoice_sign}
              />
            )}
          </div>
          <div className="relative leading-[14px] inline-block min-w-[46px]">
            Signature
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewInvoiceP;
