import { gql } from "@apollo/client";

export const GET_INVOICE = gql`
  subscription GetInvoice($inquiry_no: String!) {
    inq_list(where: { inquiry_no: { _eq: $inquiry_no } }) {
      inq_invoice {
        id
        invoice_no
        due_date
        invoice_sign
        amount
        invoice_suggestions {
          id
          created_at
          updated_at
          invoice_id
          suggestion_id
          type
          train_inq_suggestion {
            id
            created_at
            updated_at
            train_inq_id
            origin
            destination
            cabin_class
            train_name
            start_date
            end_date
            time
            # pnr

            train_suggestion_pdf
            train_inq_suggestion_costs {
              id
              created_at
              updated_at
              basic_fare
              tax_price
              sale_price
              atol_price
              train_suggestion_id
              train_passenger_id
              train_inq_passenger {
                first_name
                last_name
                id
                gender
                type

                nationality
                train_inq_id
                dob
              }
            }
          }
          car_inq_suggestion {
            id
            created_at
            updated_at
            car_inq_id
            origin
            destination
            car_type
            start_date
            end_date
            pnr
            car_suggestion_pdf
            car_inq_suggestion_costs {
              id
              created_at
              updated_at
              basic_fare
              tax_price
              sale_price
              car_suggestion_id
              car_id
            }
          }
          inq_suggestion {
            due_date
            fare_expiry
            id
            pnr
            bookingFee
            inq_suggestion_costs {
              basic_fare
              atol_price
              id
              created_at
              passenger_id
              sale_price
              tax_price
              updated_at
              inq_passenger {
                first_name
                last_name
                id
                gender
                type
                passport_no
                passport_expiry
                nationality
                inq_id
                dob
              }
            }
            inq_suggestion_legs {
              cabin
              hand_carry
              id
              created_at
              luggage
              type
              updated_at
              inq_suggestion_leg_stops {
                airline_iata
                arrival
                arrival_datetime
                created_at
                depart
                depart_datetime
                flight_no
                id
                ordering
              }
            }
          }
          stay_inq_suggestion {
            id
            created_at
            updated_at

            where_to
            start_date
            end_date
            hotel_name
            room_type
            pnr
            stay_inq_id
            stay_suggestion_pdf
            stay_inq_suggestion_costs {
              id
              created_at
              updated_at
              basic_fare
              tax_price
              sale_price
              atol_price
              stay_suggestion_id
              stay_room_id
              stay_inq_room {
                room_number
                no_of_days
                room_type
                stay_inq_id
                id
                created_at
                updated_at
              }
            }
          }
        }
        invoice_suggestion {
          due_date
          fare_expiry
          pnr
          bookingFee
          id
          inq_suggestion_costs {
            basic_fare
            id
            created_at
            passenger_id
            sale_price
            tax_price
            atol_price
            updated_at
            inq_passenger {
              first_name
              last_name
              id
              gender
              type
              passport_no
              passport_expiry
              nationality
              inq_id
              dob
            }
          }
          inq_suggestion_legs {
            cabin
            hand_carry
            id
            created_at
            luggage
            type
            updated_at
            inq_suggestion_leg_stops {
              airline_iata
              arrival
              arrival_datetime
              created_at
              depart
              depart_datetime
              flight_no
              id
              ordering
            }
          }
        }
        inq_invoice_items {
          id
          description
          amount
          sign_invoice
        }
      }
      inq_invoices {
        id
        invoice_no
        due_date
        invoice_sign
        amount
        invoice_suggestions {
          id
          created_at
          updated_at
          invoice_id
          suggestion_id
          type
          train_inq_suggestion {
            id
            created_at
            updated_at
            train_inq_id
            origin
            destination
            cabin_class
            train_name
            start_date
            end_date
            time
            # pnr

            train_suggestion_pdf
            train_inq_suggestion_costs {
              id
              created_at
              updated_at
              basic_fare
              tax_price
              sale_price
              atol_price
              train_suggestion_id
              train_passenger_id
              train_inq_passenger {
                first_name
                last_name
                id
                gender
                type

                nationality
                train_inq_id
                dob
              }
            }
          }
          car_inq_suggestion {
            id
            created_at
            updated_at
            car_inq_id
            origin
            destination
            car_type
            start_date
            end_date
            pnr
            car_suggestion_pdf
            car_inq_suggestion_costs {
              id
              created_at
              updated_at
              basic_fare
              tax_price
              sale_price
              car_suggestion_id
              car_id
            }
          }
          inq_suggestion {
            due_date
            fare_expiry
            id
            pnr
            bookingFee
            inq_suggestion_costs {
              basic_fare
              atol_price
              id
              created_at
              passenger_id
              sale_price
              tax_price
              updated_at
              inq_passenger {
                first_name
                last_name
                id
                gender
                type
                passport_no
                passport_expiry
                nationality
                inq_id
                dob
              }
            }
            inq_suggestion_legs {
              cabin
              hand_carry
              id
              created_at
              luggage
              type
              updated_at
              inq_suggestion_leg_stops {
                airline_iata
                arrival
                arrival_datetime
                created_at
                depart
                depart_datetime
                flight_no
                id
                ordering
              }
            }
          }
          stay_inq_suggestion {
            id
            created_at
            updated_at

            where_to
            start_date
            end_date
            hotel_name
            room_type
            pnr
            stay_inq_id
            stay_suggestion_pdf
            stay_inq_suggestion_costs {
              id
              created_at
              updated_at
              basic_fare
              tax_price
              sale_price
              atol_price
              stay_suggestion_id
              stay_room_id
              stay_inq_room {
                room_number
                no_of_days
                booked_by
                stay_inq_id
                id
                created_at
                updated_at
              }
            }
          }
        }
        invoice_suggestion {
          due_date
          fare_expiry
          id
          pnr
          bookingFee
          inq_suggestion_costs {
            basic_fare
            id
            created_at
            passenger_id
            sale_price
            atol_price
            tax_price
            updated_at
            inq_passenger {
              first_name
              last_name
              id
              gender
              type
              passport_no
              passport_expiry
              nationality
              inq_id
              dob
            }
          }
          inq_suggestion_legs {
            cabin
            hand_carry
            id
            created_at
            luggage
            type
            updated_at
            inq_suggestion_leg_stops {
              airline_iata
              arrival
              arrival_datetime
              created_at
              depart
              depart_datetime
              flight_no
              id
              ordering
            }
          }
        }
        inq_invoice_items {
          id
          description
          amount
          sign_invoice
        }
      }
    }
  }
`;
