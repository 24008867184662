import React, { useEffect, useState } from "react";
import WhatsAppChat from "~/modules/BrandModule/modules/WhatsappModule/components/Whatsapp/WhatsAppChat";
import WhatsappNew from "~/modules/BrandModule/modules/WhatsappModule/components/Whatsapp/WhatsappNew";
import Filters from "./components/Filters";
import Statistics from "./components/Inquires";
import { useForm } from "react-hook-form";
import moment from "moment";
import { GET_ASSIGNED_BRANDS_WITH_ID_THP_ID_BRD_TYPE_ID } from "./graphql/Query";
import { useUserId } from "@nhost/react";
import { useQuery } from "@apollo/client";

import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import Charts from "./components/Charts";
import dayjs from "dayjs";
import useAssignedBrands from "~/hooks/useAssignedBrands";
import brandsStore from "~/store/brandsStore/brandsStore";
import {
  GET_ASSIGNED_BRANDS,
  GET_ASSIGNED_BRANDS_Dashboard,
} from "~/components/common/header/graphql/Query";

const DashboardStats = () => {
  const { brdIds } = useAssignedBrands();
  const userID = useUserId();
  const { travelHouse }: any = travelHouseStore();

  const { brands }: any = brandsStore();

  const [shouldQueryRun, setShouldQueryRun] = useState(false);

  const { loading, data, error } = useQuery(GET_ASSIGNED_BRANDS_Dashboard, {
    variables: {
      id: userID,
      thp_id: travelHouse?.id,
      brdTypeId: travelHouse?.active_brand,
    },
  });

  const today = dayjs();
  const currentMonth = [
    today.startOf("month").subtract(1, "month"),
    today.startOf("month"),
  ];

  const [dates, setDates] = useState(currentMonth);

  useEffect(() => {
    setSelectedBrands(brands);
  }, [brands]);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [brandsRec, setBrandsRec] = useState([]);

  useEffect(() => {
    const brdRec: any = [];
    if (data) {
      if (data?.brd_users?.length > 0) {
        data?.brd_users?.map((brd: any) => {
          brdRec.push({ id: brd?.brd_id, name: brd.brd_list.name });
        });
      } else {
        data?.thp_list?.[0]?.brd_lists?.map((brd: any) => {
          brdRec.push({ id: brd?.id, name: brd.name });
        });
      }

      setBrandsRec(brdRec);
    }
  }, [loading]);
  //
  const brd_ids: any = [];

  selectedBrands.map((brd: any) => {
    brd_ids.push(brd?.id);
  });

  const [source, setSource] = useState<any>({
    source: null,
    source_place: null,
    source_position: null,
    source_keyword: null,
    source_add_id: null,
  });

  if (loading) return <>Loading..</>;
  if (error) return <>{error.message}</>;

  return (
    <>
      <div className="flex border-b-2 border-gray-300 dark:border-[#E7E3FC61] flex-col mb-2 justify-between w-full">
        <h1 className="text-[#38333F] dark:text-dark-primary uppercase text-[18px] font-bold ">
          Dashboard
        </h1>
        <div>
          <Filters
            dates={dates}
            setDates={setDates}
            brands={brandsRec}
            setSource={setSource}
            source={source}
          />
        </div>
      </div>

      <div className="pt-4">
        {brandsRec?.length > 0 && (
          <Charts
            dates={dates}
            brands={selectedBrands}
            allBrand={brandsRec}
            source={source}
          />
        )}
      </div>
      {/* <div className="pt-4">
        <Inquires dates={dates} brands={brands} />
      </div>
      <div className="pt-4">
        <Payments dates={dates} brands={brands} />
      </div>
      <div className="pt-4">
        <VendorsPaidAmount dates={dates} brd_ids={brd_ids} />
      </div> */}
    </>
  );
};

export default DashboardStats;
