import { Divider, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import TicketRequestForm from "../TicketRequestForm";
import SeatSelection from "../SeatSelection";
import ExtraBaggage from "../ExtraBaggage";
import { useState } from "react";

const SelectTicketForm = ({ inquiry, ticekts, setSidePopup }: any) => {

    const [selectedForms, setSelectedForms] = useState<string[]>([]);
    let options: any = []

    const getOptions = () => {
        if (inquiry?.suggestion_id && inquiry?.suggestion_id !== null && !ticekts?.[0]?.inq_tickets?.[0]) {
            options.push("Flights")
        }
        if (inquiry?.stay_inq_details?.[0]?.selected_stay_suggestion_id && inquiry?.stay_inq_details?.[0]?.selected_stay_suggestion_id !== null && !ticekts?.[0]?.stay_inq_details?.[0]?.stay_inq_tickets?.[0]) {
            options.push("Hotel")
        }
        if (inquiry?.train_inq_details?.[0]?.selected_train_suggestion_id && inquiry?.train_inq_details?.[0]?.selected_train_suggestion_id !== null && !ticekts?.[0]?.train_inq_details?.[0]?.train_inq_tickets?.[0]) {
            options.push("Train")
        }
        if (inquiry?.visa_inq_details?.[0]?.selected_visa_suggestion_id && inquiry?.visa_inq_details?.[0]?.selected_visa_suggestzion_id !== null && !ticekts?.[0]?.visa_inq_details?.[0]?.visa_inq_tickets?.[0]) {
            options.push("Visa")
        }
        if (inquiry?.car_inq_details?.[0]?.selected_car_suggestion_id && inquiry?.car_inq_details?.[0]?.selected_car_suggestion_id !== null && !ticekts?.[0]?.car_inq_details?.[0]?.car_inq_tickets?.[0]) {
            options.push("Car")
        }
    }

    getOptions()

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        setSelectedForms((prev) =>
            checked ? [...prev, value] : prev.filter((form) => form !== value)
        );
    };

    return (
        <div className="min-w-[500px] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
            <h1 className="text-center text-3xl text-basic dark:text-dark-primary">
                Select Ticket Request Form
            </h1>
            <Divider />
            <h2 className="text-lg text-primary dark:text-dark-primary my-2">{options.length > 0 ? "Available Options" : "No Options Available."}</h2>
            {options.length > 0 ? (
                <FormGroup className="flex flex-row justify-center gap-4 mb-4">
                    {options.map((option: any) => (
                        <FormControlLabel
                            key={option}
                            control={
                                <Checkbox
                                    value={option}
                                    checked={selectedForms.includes(option)}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                />
                            }
                            label={option}
                        />
                    ))}
                </FormGroup>
            ) : (
                <p className="text-primary dark:text-dark-primary mt-2">
                    No suggestion selected. Select any suggestion first.
                </p>
            )}
            {selectedForms.map((form) => (
                <div key={form} className="mt-4">
                    <h1 className="text-xl text-center text-basic dark:text-dark-basic">
                        {`${form} Ticket Request Form`}
                    </h1>
                    <TicketRequestForm inquiry={{ ...inquiry, type: form }} ticekts={ticekts} setSidePopup={setSidePopup} />
                </div>
            ))}
        </div>
    );
};

export default SelectTicketForm;
