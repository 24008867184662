import { gql } from "@apollo/client";

export const ADD_IMPORT_REPORT = gql`
  mutation AddImportReport($data: [inq_import_report_insert_input!]!) {
    insert_inq_import_report(objects: $data) {
      returning {
        id
      }
    }
  }
`;
