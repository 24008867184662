import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import { GET_INQ_FLAGS } from "./graphql/Query";
import SimpleSelect from "~/components/FormInputs/SimpleSelect";
import { EDIT_INQ, INSERT_COMMENT } from "./graphql/Mutation";
import { useUserId } from "@nhost/react";
import { toast } from "react-toastify";
import moment from "moment";
import { DateTimeBox } from "~/components/FormInputs/DateTimeBox";
import { Button } from "@mui/material";

const AddComment = ({ inquiry }: any) => {
  const [insertComment] = useMutation(INSERT_COMMENT);
  const [editInq] = useMutation(EDIT_INQ);
  const [isLoading, setIsLoading] = useState(false);
  const [flagsOptions, setFlagsOptions] = useState<any>(null);
  const userID = useUserId();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm();
  const { loading, data: flagsData } = useQuery(GET_INQ_FLAGS);

  const getNotified = watch("get_notified");

  useEffect(() => {
    const flags = flagsData?.thp_inquiry_flags;
    const finalObj = [
      {
        title: "Choose Flag",
        value: "",
      },
    ];
    flags?.map((item: any): any => {
      const obj = {
        title: item.name,
        value: item.id,
      };

      finalObj.push(obj);
    });
    setFlagsOptions(finalObj);
  }, [flagsData]);

  const onSubmit = async (inpData: any) => {
    setIsLoading(true);

    const resInq = await editInq({
      variables: {
        id: inquiry.id,
        flag_id: inpData?.flag_id || 1,
      },
    });

    const res = await insertComment({
      variables: {
        data: {
          flag_id: inpData?.flag_id || 1,
          user_id: userID,
          brd_id: inquiry?.brd_id,
          inq_id: inquiry?.id,
          comment: inpData?.comment,
          timestamp: moment(inpData?.timestamp).format("YYYY-MM-DD HH:mm:ss"),
          reminder_time: getNotified
            ? moment(inpData?.reminder_time).format("YYYY-MM-DD HH:mm:ss")
            : null,
        },
      },
    });

    if (res?.data?.insert_inq_comments_one?.id) {
      toast.success("Comment added successfully");
      reset();
    } else {
      toast.error("Something went wrong!");
    }
    setIsLoading(false);
  };
  if (loading) return <p>loading...</p>;

  return (
    <div className="bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary shadow rounded-lg p-[20px] w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-5">
          <div className="flex-1 flex flex-col gap-5">
            <SimpleSelect
              setValue={setValue}
              register={register}
              options={flagsOptions}
              required={true}
              errors={errors}
              fieldName="flag_id"
              classNames={{
                input: `border  sm:text-sm rounded-[5px] block w-full p-2.5 py-3 focus:outline-none focus:border-basic bg-white`,
              }}
            />
            <DateTimeBox
              control={control}
              setValue={setValue}
              errors={errors}
              fieldName={`timestamp`}
              labelClasses="text-primary dark:text-dark-primary text-base font-normal"
              inputClasses={
                "border bg-white sm:text-sm rounded-[5px] text-primary dark:text-dark-primary bg-primary focus:border-basic focus:outline-none dark:bg-dark-primary block w-full p-2.5 py-3"
              }
              defaultValue={moment().toDate()}
            />
            <InputField
              register={register}
              errors={errors}
              label="Want to be notified?"
              fieldName="get_notified"
              type="checkbox"
            />
            {getNotified && (
              <DateTimeBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName={`reminder_time`}
                label="Remind Me"
                labelClasses="text-base font-normal text-primary dark:text-dark-primary"
                inputClasses={
                  "bg-white border focus:border-basic focus:outline-none text-primary dark:text-dark-primary sm:text-sm rounded-[5px] focus:ring-primary-500 focus:border-basic block w-full p-2.5 py-3 dark:bg-dark-primary dark:focus:ring-primary-500"
                }
                minDate={moment().format("YYYY-MM-DD")}
              />
            )}
            <Button
              variant="contained"
              size="medium"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? "Updating..." : "Add Comment"}
            </Button>
          </div>
          <div className="flex-1 flex flex-col gap-5">
            <textarea
              {...register("comment")}
              id="comment"
              placeholder="Write your comment..."
              className="border text-primary dark:text-dark-primary bg-white dark:bg-dark-primary sm:text-sm rounded-[5px] block w-full h-full p-2.5 focus:outline-none focus:border-basic"
            ></textarea>
          </div>
        </div>
        {/* <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <SimpleSelect
              setValue={setValue}
              register={register}
              label="Choose Flag"
              options={flagsOptions}
              required={true}
              errors={errors}
              fieldName="flag_id"
              classNames={{
                input: `border  sm:text-sm rounded-[5px] block w-full p-2.5 py-3 focus:outline-none focus:border-basic bg-white`,
              }}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 pt-[30px]">
            <InputField
              register={register}
              errors={errors}
              label="Want to be notified?"
              fieldName="get_notified"
              type="checkbox"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <DateTimeBox
              control={control}
              setValue={setValue}
              errors={errors}
              fieldName={`timestamp`}
              label="Action Time"
              labelClasses="text-primary dark:text-dark-primary text-base font-normal"
              inputClasses={
                "border bg-white sm:text-sm rounded-[5px] text-primary dark:text-dark-primary bg-primary focus:border-basic focus:outline-none dark:bg-dark-primary block w-full p-2.5 py-3"
              }
              defaultValue={moment().toDate()}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            {getNotified && (
              <DateTimeBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName={`reminder_time`}
                label="Remind Me"
                labelClasses="text-base font-normal text-primary dark:text-dark-primary"
                inputClasses={
                  "bg-white border focus:border-basic focus:outline-none text-primary dark:text-dark-primary sm:text-sm rounded-[5px] focus:ring-primary-500 focus:border-basic block w-full p-2.5 py-3 dark:bg-dark-primary dark:focus:ring-primary-500"
                }
                minDate={moment().format("YYYY-MM-DD")}
              />
            )}
          </div>

          <div className="col-span-full">
            <textarea
              {...register("comment")}
              id="comment"
              placeholder="Write your comment..."
              className="border text-primary dark:text-dark-primary bg-white dark:bg-dark-primary sm:text-sm rounded-[5px] block w-full p-2.5 focus:outline-none focus:border-basic"
            ></textarea>
          </div>
          <div className="col-span-6 sm:col-full">
            <Button
              variant="contained"
              size="medium"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? "Updating..." : "Add Comment"}
            </Button>
          </div>
        </div> */}
      </form>
    </div>
  );
};

export default AddComment;
