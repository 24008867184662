import { gql } from "@apollo/client";

export const GET_POST_DETAILS = gql`
  query MyQuery($id: uuid!) {
    brd_posts_by_pk(id: $id) {
      author_id
      brd_id
      content
      country
      iata
      airline_iata
      created_at
      excerpt
      canonical_url
      featured_image
      id
      is_google_indexed
      post_title
      show_in_flight_page
      slug
      placement
      updated_at
    }
  }
`;
