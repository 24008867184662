import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

import InquiryTable from "~/modules/InquiriesModule/components/InquiryTable";

import { useParams } from "react-router-dom";
import {
  GET_ALL_INQUIRIES,
  GET_ALL_INQUIRIES_COUNT,
  GET_UNIQUE_INQUIRIES,
  GET_UNIQUE_INQUIRIES_COUNT,
} from "~/modules/InquiriesModule/segments/Inquiries/components/InquiresList/graphql/Query";
export default function Inquires({ dates }: any) {
  const { customerId } = useParams();

  const limit = 20;
  const { travelHouse }: any = travelHouseStore();
  const [page, setPage] = useState(0);

  const condition = {
    _and: [
      { main_inq: { thp_id: { _eq: travelHouse.id } } },
      { main_inq: { user_id: { _eq: customerId } } },
    ],
  };

  const { loading, data, error } = useQuery(GET_UNIQUE_INQUIRIES, {
    variables: {
      condition: condition,
      limit,
      offset: page * limit,
    },
  });

  const {
    loading: countLoading,
    error: countError,
    data: countData,
  } = useQuery(GET_UNIQUE_INQUIRIES_COUNT, {
    variables: {
      condition: condition,
    },
  });
  // return loading while loading data
  if (loading) return <div></div>;
  if (countLoading) return <div></div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  const inquires = data?.inq_list_unique || [];
  return (
    <InquiryTable
      inquires={inquires || []}
      page={page}
      total={countData?.inq_list_unique_aggregate?.aggregate?.count}
      setPage={setPage}
      userCol={false}
    />
  );
}
