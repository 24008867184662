import { gql } from "@apollo/client";

export const ADD_PASSENGER = gql`
  mutation MyMutation($data: [inq_passengers_insert_input!]!) {
    insert_inq_passengers(objects: $data) {
      affected_rows
    }
  }
`;

export const ADD_STAY_ROOMS = gql`
  mutation MyMutation($data: [stay_inq_rooms_insert_input!]!) {
    insert_stay_inq_rooms(objects: $data) {
      affected_rows
    }
  }
`;

export const EDIT_PASSENGER = gql`
  mutation EditPassenger(
    $id: uuid
    $gender: String
    $type: String
    $first_name: String
    $last_name: String
    $dob: date
    $nationality: String
    $passport_no: String
    $passport_expiry: date
  ) {
    update_inq_passengers(
      where: { id: { _eq: $id } }
      _set: {
        type: $type
        gender: $gender
        first_name: $first_name
        last_name: $last_name
        dob: $dob
        nationality: $nationality
        passport_no: $passport_no
        passport_expiry: $passport_expiry
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export const STAY_EDIT_ROOM = gql`
  mutation EditPassenger(
    $id: uuid
    $roomNo: String
    $roomType: String!
  ) {
    update_stay_inq_rooms(
      where: { id: { _eq: $id } }
      _set: {
        room_type: $roomType
        room_no: $roomNo
      }
    ) {
      returning {
        id
      }
    }
  }
`;
