import React from "react";
import { useForm } from "react-hook-form";

import { Divider } from "@mui/material";
import InputField from "components/FormInputs/InputField";
import { CarTypeSelector } from "../../../SuggestionCard/components/AddSuggestion/components/SuggestionForm/Cars/components/CarType";

const AddCar = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const onSubmit = async (data: any) => {
    console.log("data::", data);
  };

  return (
    <div className="self-stretch flex-1 min-w-[500px] h-full bg-white dark:bg-dark-primary flex flex-col p-[25px] items-center gap-[20px] text-left text-mini text-darkslategray shadow">
      <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
        Add Car
      </h2>
      <Divider />
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-start">
          <div className="flex-1 rounded-8xs flex flex-col gap-2 items-start justify-start">
            <CarTypeSelector
              name="carType"
              register={register}
              errors={errors}
              value=""
              onChange={(value: any) => setValue("carType", value)}
              setValue={setValue}
              fieldName="carType"
              defaultValue=""
              required={true}
              label="Car Type"
            />
            <InputField
              label="Car Number"
              register={register}
              type="text"
              errors={errors}
              placeHolder="Car Number"
              fieldName={`car_no`}
              inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal"
            />
          </div>
        </div>

        <div className="flex justify-end mt-5">
          <button
            type="submit"
            className="w-full btn bg-basic text-white rounded-md p-2"
          >
            Add Car
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCar;
