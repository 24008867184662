import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import {
  LocalizationProvider,
  DateRangePicker,
  DatePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import TravelersPopover from "~/components/FormInputs/TravelersForm";

const TrainComponent = ({ control, register, setValue, errors, inquiryPassengers }: any) => {
  // Initialize state based on the incoming inquiryPassengers array
  const initialPassengers = {
    adults: 0,
    children: 0,
    infantsOnLap: 0,
    youth: 0,
  };

  // Populate initial state based on inquiryPassengers
  inquiryPassengers.forEach((passenger: any) => {
    switch (passenger.type) {
      case "adult":
        initialPassengers.adults += 1;
        break;
      case "child":
        initialPassengers.children += 1;
        break;
      case "youth":
        initialPassengers.youth += 1;
        break;
      case "infantOnLap":
        initialPassengers.infantsOnLap += 1;
        break;
      default:
        break;
    }
  });

  const [adults, setAdults] = useState(initialPassengers.adults);
  const [children, setChildren] = useState(initialPassengers.children);
  const [infantsOnLap, setInfantsOnLap] = useState(initialPassengers.infantsOnLap);
  const [youth, setYouth] = useState(initialPassengers.youth);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);

  // Initialize travelers state
  const [travelers, setTravelers] = useState<{
    [key in "children" | "youth" | "infantsOnLap"]: string[];
  }>({
    youth: Array(youth).fill(""),
    children: Array(children).fill(""),
    infantsOnLap: Array(infantsOnLap).fill(""),
  });

  const handleTravelersClick = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleTravelersClose = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    setTravelers((prevState) => ({
      ...prevState,
      infantsOnLap: Array(infantsOnLap).fill(""),
    }));
  }, [infantsOnLap]);

  const handleAgeChange = (
    category: "children" | "youth" | "infantsOnLap",
    index: number,
    value: string
  ) => {
    setTravelers((prevState) => {
      const updatedArray = [...prevState[category]];
      updatedArray[index] = value; // Update only the specific index
      return { ...prevState, [category]: updatedArray };
    });

    setValue(`train.Travelers.${category}.${index}`, value);
  };

  // const updateTravelerCount = (
  //   type: "adults" | "youth" | "children" | "infantsOnLap",
  //   action: "increment" | "decrement"
  // ) => {
  //   let newCount;

  //   if (action === "increment") {
  //     // Prevent increment if max capacity is reached
  //     if (type === "adults" && adults >= inquiryPassengers.filter((p: any) => p.type === 'adult').length) return;
  //     if (type === "youth" && youth >= inquiryPassengers.filter((p: any) => p.type === 'youth').length) return;
  //     if (type === "children" && children >= inquiryPassengers.filter((p: any) => p.type === 'child').length) return;
  //     if (type === "infantsOnLap" && infantsOnLap >= inquiryPassengers.filter((p: any) => p.type === 'infant').length) return;
  //   }

  //   if (type === "adults") {
  //     newCount = action === "increment" ? adults + 1 : Math.max(adults - 1, 0);
  //     setAdults(newCount);
  //     setValue(`train.Travelers.adults`, newCount);
  //   } else if (type === "youth") {
  //     newCount = action === "increment" ? youth + 1 : Math.max(youth - 1, 0);
  //     setYouth(newCount);
  //     setValue(`train.Travelers.youth`, newCount);
  //   }
  //   else if (type === "children") {
  //     newCount = action === "increment" ? children + 1 : Math.max(children - 1, 0);
  //     setChildren(newCount);
  //     setValue(`train.Travelers.children`, newCount);
  //   } else if (type === "infantsOnLap") {
  //     newCount = action === "increment" ? infantsOnLap + 1 : Math.max(infantsOnLap - 1, 0);
  //     setInfantsOnLap(newCount);
  //     setValue(`train.Travelers.infantsOnLap`, newCount);
  //   }

  //   // Update the travelers state
  //   setTravelers({
  //     youth: Array(youth).fill(""),
  //     children: Array(children).fill(""),
  //     infantsOnLap: Array(infantsOnLap).fill(""),
  //   });
  // };

  const updateTravelerCount = (
    type: "adults" | "youth" | "children" | "infantsOnLap",
    action: "increment" | "decrement"
  ) => {
    let newCount;

    if (action === "increment") {
      if (
        (type === "adults" && adults >= inquiryPassengers.filter((p: any) => p.type === "adult").length) ||
        (type === "youth" && youth >= inquiryPassengers.filter((p: any) => p.type === "youth").length) ||
        (type === "children" && children >= inquiryPassengers.filter((p: any) => p.type === "child").length) ||
        (type === "infantsOnLap" && infantsOnLap >= inquiryPassengers.filter((p: any) => p.type === "infant").length)
      ) return;
    }

    if (action === "increment") {
      newCount = type === "adults" ? adults + 1 : type === "youth" ? youth + 1 : type === "children" ? children + 1 : infantsOnLap + 1;
    } else {
      newCount = type === "adults" ? Math.max(adults - 1, 0) :
        type === "youth" ? Math.max(youth - 1, 0) :
          type === "children" ? Math.max(children - 1, 0) :
            Math.max(infantsOnLap - 1, 0);
    }

    if (type === "adults") {
      setAdults(newCount);
      setValue("train.Travelers.adults", newCount);
    } else if (type === "youth") {
      setYouth(newCount);
      setValue("train.Travelers.youth", newCount);
    } else if (type === "children") {
      setChildren(newCount);
      setValue("train.Travelers.children", newCount);
    } else if (type === "infantsOnLap") {
      setInfantsOnLap(newCount);
      setValue("train.Travelers.infantsOnLap", newCount);
    }

    // Update the travelers state
    setTravelers({
      youth: Array(youth).fill(""),
      children: Array(children).fill(""),
      infantsOnLap: Array(infantsOnLap).fill(""),
    });
  };

  useEffect(() => {
    setValue("train.Travelers.adults", adults);
    setValue("train.Travelers.youth", youth);
    setValue("train.Travelers.children", children);
    setValue("train.Travelers.infantsOnLap", infantsOnLap);
  }, [adults, youth, children, infantsOnLap, setValue]);


  return (
    <>
      {/* Origin */}
      <Controller
        control={control}
        name={`train.Origin`}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => {
          return (
            <TextField
              id={`outlined-basic-Origin`}
              label="Origin"
              variant="outlined"
              {...field}
              error={!!error}
              helperText={error ? "This field is required" : ""}
            />
          );
        }}
      />
      {/* Destination  */}
      <Controller
        control={control}
        name={`train.Destination`}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => {
          return (
            <TextField
              id={`outlined-basic-Destination}`}
              label="Destination"
              variant="outlined"
              {...field}
              error={!!error}
              helperText={error ? "This field is required" : ""}
            />
          );
        }}
      />
      {/* Date Range Picker */}
      <Controller
        control={control}
        name={`train.Date`}
        rules={{
          validate: (value) => (value ? true : "This field is required"),
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Date"
                value={value}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
              />
              {error && (
                <span className="text-red-500 text-xs">{error.message}</span>
              )}
            </LocalizationProvider>
          );
        }}
      />

      {/* Travelers Selection */}
      <Controller
        control={control}
        name={"train.Travelers"}
        defaultValue={{ adults: 1, youth: 0, children: 0, infantsOnLap: 0 }}
        render={({ field }) => (
          <TravelersPopover
            {...field}
            open={Boolean(anchorEl2)}
            anchorEl={anchorEl2}
            handleClose={handleTravelersClose}
            handleClick={handleTravelersClick}
            adults={adults}
            setAdults={setAdults}
            youth={youth}
            setYouth={setYouth}
            setChildren={setChildren}
            setInfantsOnLap={setInfantsOnLap}
            children={children}
            infantsOnLap={infantsOnLap}
            travelers={travelers}
            handleAgeChange={handleAgeChange}
            ageOptions={Array.from({ length: 18 }, (_, i) => i.toString())}
            infantOptions={["0", "1"]}
            popoverWidth="400px"
            label="Travelers"
            updateTravelerCount={updateTravelerCount}
          />
        )}
      />
    </>
  );
};

export default TrainComponent;
