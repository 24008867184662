import React, { useEffect, useState } from "react";
import CostRow from "../../PassengersCost/components/CostRow";
import CostRowAdd from "../../PassengersCost/components/CostRowAdd";

export default function CarPassengerEditCost({
  passengers,
  errors,
  register,
  control,
  setValue,
  suggestion_cost,
  watch,
  inquiry,
  type,
}: any) {
  const [firstPassengerId, setFirstPassengerId] = useState<string | null>(null);
  const [matchingCost, setMatchingCost] = useState<any>(null);

  // Store the first passenger's ID and their matching cost if it exists
  useEffect(() => {
    if (type === "Cars" && suggestion_cost.length > 0) {
      const firstCost = suggestion_cost[0]; // Get first suggestion cost
      setFirstPassengerId(firstCost.car_id); // Store the first ID

      // Find the cost that matches the car_suggestion_id
      const costForPassenger = suggestion_cost.find(
        (cost: any) => cost.car_suggestion_id === firstCost.car_suggestion_id
      );

      setMatchingCost(costForPassenger); // Store matching cost entry
    }
  }, [suggestion_cost, type]);

  // Find the first matching passenger from the passengers list
  const filteredPassenger = passengers.find(
    (passenger: any) => passenger.id === firstPassengerId
  );

  // Debugging
  // Debugging

  return (
    <div>
      <div className="bg-gray-100 rounded-lg dark:bg-[#28243D] p-2 mt-4">
        <div className="flex flex-col border-b border-gray-300 w-full">
          {/* Section Heading */}
          <h1 className="flex-1 w-full font-bold text-primary dark:text-dark-primary text-left">
            Passenger Cost
          </h1>
        </div>

        <div className="mt-2">
          {filteredPassenger && (
            <React.Fragment key={0}>
              {/* If a matching cost exists, show CostRow for editing */}
              {matchingCost ? (
                <CostRow
                  suggestion_cost_detail={matchingCost} // Matching cost
                  register={register}
                  errors={errors}
                  index={0}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  inquiry={inquiry}
                  type={type}
                />
              ) : (
                /* If no matching cost, show CostRowAdd for adding */
                <CostRowAdd
                  passenger={filteredPassenger} // Passenger to add cost
                  register={register}
                  errors={errors}
                  index={0}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  type={type}
                />
              )}
              <hr className="border-gray-300 border-dashed my-2" />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
