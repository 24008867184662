import React from "react";
import { TextField, Box } from "@mui/material";
import { Controller } from "react-hook-form";

const Visa = ({
  control,
  errors,
  inquiryPassengers
}: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <Controller
        control={control}
        name={`visa.name`}
        rules={{ required: "Name is required" }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            label="Name"
            variant="outlined"
            {...field}
            error={!!error}
          />
        )}
      />

      <Controller
        control={control}
        name={`visa.country`}
        rules={{ required: "Country is required" }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            label="Country"
            variant="outlined"
            {...field}
            error={!!error}
          />
        )}
      />
    </Box>
  );
};

export default Visa;
