import React, { useEffect, useState, useRef } from "react";
import { TextField, InputAdornment, IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check"; // New icon for check
import { GET_BRAND_DETAIL } from "~/graphql/brd_list/Query";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import brandsStore from "~/store/brandsStore/brandsStore";
import { GET_GMAIL_FILTERED_MESSAGES } from "~/graphql/getGmailFilteredMessages/Mutation";
import { GET_GMAIL_MESSAGES } from "~/graphql/getGmailMessages/Query";
import base64 from "base64-js";

function decodeBase64(data: string): string {
  return new TextDecoder().decode(base64.toByteArray(data));
}

const extractOtp = (decodedData: string): string | null => {
  const otpMatch = decodedData.match(
    /<strong>One time password \(OTP\): (\d+)<\/strong>/
  );
  return otpMatch ? otpMatch[1] : null;
};

const OtpComp: React.FC = () => {
  const [messagesData, setMessagesData] = useState<string[]>([]);
  const [response, setResponse] = useState<any[]>([]);
  const [otpCopied, setOtpCopied] = useState<boolean[]>([false, false]);
  const { brands }: any = brandsStore();
  const brd_id = brands.length > 0 ? brands[0].id : null;
  const searchWords: string[] = ["BS7134", "BS8049"];

  const otpRegex = /One time password \(OTP\): (\d+)/;
  const otpArray: string[] = [];

  messagesData.forEach((message) => {
    const otpMatch = message.match(otpRegex);

    if (otpMatch && otpMatch[1]) {
      const otp = otpMatch[1];
      otpArray.push(otp);
    } else {
      console.error("OTP not found in message:", message);
    }
  });

  const client = useApolloClient();

  const { data, loading, error } = useQuery(GET_BRAND_DETAIL, {
    skip: brands.length > 1 || !brd_id,
    variables: brd_id ? { brd_id } : undefined,
  });
  const gmailRefreshToken = data?.brd_list?.[0]?.gmail_refresh_token;

  const [
    getFilteredMessages,
    { data: filterIds, loading: filteredIdsLoading, error: filteredIdsError },
  ] = useMutation(GET_GMAIL_FILTERED_MESSAGES);
  const hasFetchedOtp = useRef(false);

  useEffect(() => {
    if (response.length) {
      const fetchMessages = async () => {
        const data = await Promise.all(
          response.map(async (id) => {
            const res = await client.query({
              query: GET_GMAIL_MESSAGES,
              variables: {
                id: id,
                refresh_token: gmailRefreshToken,
              },
            });
            return res?.data?.getGmailMessages?.payload?.body?.data;
          })
        );
        const decodedData = data.map(decodeBase64);
        setMessagesData(decodedData);
      };

      fetchMessages();
    }
  }, [response, gmailRefreshToken]);

  useEffect(() => {
    if (gmailRefreshToken && !hasFetchedOtp.current) {
      Promise.all(
        searchWords.map((searchWord) =>
          getFilteredMessages({
            variables: {
              refreshToken: gmailRefreshToken,
              searchWord: searchWord,
              senderEmail: "otp@brightsunmails.com",
            },
          }).catch((error) => {
            console.error(`Error fetching data for ${searchWord}:`, error);
            return null;
          })
        )
      )
        .then((data) => {
          const filterIds = data.filter((item) => item !== null);

          const messageIds = filterIds
            .map(
              (item) =>
                item?.data?.getGmailFilteredMessages?.response?.messages?.[0]
                  ?.id
            )
            .filter((id) => id !== undefined);

          setResponse(messageIds);
        })
        .catch((error) => {
          console.error("Error during the requests:", error);
        });

      hasFetchedOtp.current = true;
    }
  }, [gmailRefreshToken, getFilteredMessages]);

  const copyToClipboard = (otp: string, index: number) => {
    navigator.clipboard.writeText(otp);
    const newOtpCopied = [...otpCopied];
    newOtpCopied[index] = true;
    setOtpCopied(newOtpCopied);

    setTimeout(() => {
      newOtpCopied[index] = false;
      setOtpCopied([...newOtpCopied]);
    }, 3000);
  };

  const reloadOtp = async (index: number) => {
    const res = await client.query({
      query: GET_GMAIL_MESSAGES,
      variables: {
        id: response[index],
        refresh_token: gmailRefreshToken,
      },
    });
    const newMessage = decodeBase64(
      res?.data?.getGmailMessages?.payload?.body?.data
    );
    const newMessagesData = [...messagesData];
    newMessagesData[index] = newMessage;
    setMessagesData(newMessagesData);
  };

  return (
    <div className="flex gap-2">
      {searchWords.slice(0, 2).map((word, index) => (
        <TextField
          key={index}
          variant="outlined"
          size="small"
          label={`${word}`}
          value={otpArray[index] || ""}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Reload OTP">
                  <IconButton onClick={() => reloadOtp(index)}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy OTP">
                  <IconButton
                    onClick={() =>
                      copyToClipboard(otpArray[index] || "", index)
                    }
                  >
                    {otpCopied[index] ? <CheckIcon /> : <ContentCopyIcon />}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={{ maxWidth: "200px", marginBottom: "10px" }}
        />
      ))}
    </div>
  );
};

export default OtpComp;
