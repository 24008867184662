import { gql } from "@apollo/client";

export const INSERT_BRD_SECRETS = gql`
  mutation InsertBrdSecrets($data: brd_secrets_insert_input!) {
    insert_brd_secrets(objects: [$data]) {
      affected_rows
    }
  }
`;

export const UPDATE_BRD_SECRETS = gql`
  mutation UpdateBrdSecrets($id: uuid!, $data: brd_secrets_set_input!) {
    update_brd_secrets(where: { brd_id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;
