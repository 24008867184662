import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { FaTimes } from "react-icons/fa";
import { useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import Edit from "../../../../assets/img/Vector.svg";
import SideDrawer from "~/components/common/sideDrawer";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { DELETE_STAY_ROOMS } from "../../components/PassengerBox/graphql/Mutation";
import EditRoom from "../../components/PassengerBox/components/NewPassengerEdit/EditRoom";

const StayRoomCard = ({
  room,
  inquiry,
  selected_suggestion_id,
  totalPassengers,
  hideActions,
}: any) => {

  const role = useUserDefaultRole();
  const [hideForm, setHideForm] = useState(false);
  const [deleteStayRoom] = useMutation(DELETE_STAY_ROOMS);
  const [wait, setWait] = useState(false);
  const client = useApolloClient();
  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const userDefaultRole = useUserDefaultRole();
  const user_id = useUserId();

  const DeleteRoom = async (id: any) => {
    try {
      setWait(true)
      const res = await deleteStayRoom({ variables: { id } });

      if (res?.data?.delete_stay_inq_rooms_by_pk?.id) {
        toast.success("Room Deleted Successfully!");
      }
    } catch (err) {
      toast.error(`Error Deleting Room ${err}`);
    } finally {
      setWait(false);
    }
  };

  return (
    <>
      <div className="pt-[25px] rounded-xl shadow-md relative bg-white dark:bg-dark-secondary">
        <button
          disabled={wait}
          onClick={() => DeleteRoom(room?.id)}
          className="bg-basic dark:bg-[#CFCCE6] h-[20px] w-[20px] rounded-full text-white flex justify-center items-center absolute top-[-5px] right-[-5px]"
        >
          {wait ? "..." : <FaTimes className="dark:text-[#312D4B]" />}
        </button>
        {/* )} */}
        <div className="self-stretch min-h-[300px] px-[25px] bg-white dark:bg-dark-secondary flex flex-col text-left text-mini text-darkslategray">
          <div className="self-stretch flex flex-row items-center justify-between text-sm mb-7">
            <div className="flex flex-row items-start justify-start text-">
              <div className="flex flex-row items-center justify-start">
                <b className="relative capitalize text-basic dark:text-dark-primary">
                  Type - {room.room_type || "N/A"}
                </b>
              </div>
            </div>
            {!inquiry?.selected_suggestion &&
              !hideActions &&
              (user_id === inquiry?.picked_by ||
                userDefaultRole === "admin") &&
              inquiry?.status !== "completed" && (
                // <img src={Edit} alt="" className="cursor-pointer h-4" onClick={() => setHideForm(true)} />
                <BiSolidMessageSquareEdit
                  className="cursor-pointer text-basic dark:text-dark-primary h-5 w-5"
                  onClick={() => setHideForm(true)}
                />
              )}
          </div>
          <div className="flex flex-col gap-7">
            <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-start border-b-[1px] border-basic dark:border-dark-primary">
              <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
                <div className="self-stretch relative text-basic dark:text-dark-primary flex flex-col gap-1 leading-[130%]">
                  <p className="m-0 font-medium">Booked By</p>
                  <p className="m-0 text-basic dark:text-dark-primary text-lg">
                    <p className="font-medium">
                      {room?.booked_by || (
                        <span className="text-[11px] px-2 inline-block italic">
                          N/A
                        </span>
                      )}{" "}
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row pt-0 justify-between px-0 border-b-[1px] border-basic dark:border-dark-primary">
              <div className="flex-1 flex flex-row items-center justify-start">
                <div className="relative text-basic dark:text-dark-primary flex flex-col gap-1">
                  <p className="m-0 font-medium">No. of Days</p>
                  <p className="m-0 text-basic dark:text-dark-primary text-lg">
                    <p className="font-medium">
                      {room?.no_of_days || 0}
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-between  border-b-[1px] border-basic dark:border-dark-primary">
              <div className="rounded-8xs flex flex-col items-start justify-start">
                <div className="self-stretch relative text-basic dark:text-dark-primary flex flex-col gap-1">
                  <p className="m-0 font-medium">Room No</p>
                  <p className="m-0 text-basic dark:text-dark-primary text-lg">
                    <p className="font-medium">
                      {room?.room_number || (
                        <span className="text-[11px] px-2 inline-block italic">
                          N/A
                        </span>
                      )}
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between bg-basic rounded-b-lg text-white px-[25px] py-[15px]">
          {role !== "user" && (
            <>
              <div className="flex flex-row items-start">
                <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                  <div className="self-stretch relative flex">
                    <p className="m-0">Cost:</p>
                    <p className="m-0 font-medium">
                      {room?.stay_inq_suggestion_costs?.[0]?.room_rate || (
                        <span className="text-[11px] px-2 inline-block italic">
                          N/A
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                  <div className="self-stretch relative flex">
                    <p className="m-0">Tax:</p>
                    <p className="m-0 font-medium">
                      {room?.stay_inq_suggestion_costs?.[0]?.room_tax || (
                        <span className="text-[11px] px-2 inline-block italic">
                          N/A
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                <div className="self-stretch relative flex">
                  <p className="m-0">Sale:</p>
                  <p className="m-0 font-medium">
                    {room?.stay_inq_suggestion_costs?.[0]?.sale_price || (
                      <span className="text-[11px] px-2 inline-block italic">
                        N/A
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {hideForm && (
        <SideDrawer
          setSidePopup={setHideForm}
          sidePopup={hideForm}
          component={
            <EditRoom
              setHideForm={setHideForm}
              room={room}
            />
          }
        />
      )}
    </>
  );
};

export default StayRoomCard;
