import React from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Popover,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface TravelersPopoverProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  adults: number;
  setAdults: React.Dispatch<React.SetStateAction<number>>;
  youth: number;
  setYouth: React.Dispatch<React.SetStateAction<number>>;
  children: number;
  setChildren: React.Dispatch<React.SetStateAction<number>>;
  infantsOnLap: number;
  setInfantsOnLap: React.Dispatch<React.SetStateAction<number>>;
  travelers: {
    children: string[];
    infantsOnLap: string[];
  };
  handleAgeChange: (
    category: "children" | "infantsOnLap",
    index: number,
    value: string
  ) => void;
  ageOptions: string[];
  infantOptions: string[];
  popoverWidth: string;
  label: string;
  updateTravelerCount: (
    type: "adults" | "youth" | "children" | "infantsOnLap",
    action: "increment" | "decrement"
  ) => void;
}

const TravelerSection: React.FC<{
  label: string;
  count: number;
  type: "adults" | "youth" | "children" | "infantsOnLap";
  updateTravelerCount: (type: "adults" | "youth" | "children" | "infantsOnLap", action: "increment" | "decrement") => void;
}> = ({ label, count, type, updateTravelerCount }) => (
  <Box className="flex gap-4 mt-2 justify-between">
    <div>
      <Typography variant="h6">{label}</Typography>
    </div>
    <div className="flex items-center justify-center gap-2">
      <IconButton
        onClick={() => updateTravelerCount(type, "decrement")}
        sx={{
          border: "1px solid #ccc",
          borderRadius: "9999px",
          padding: "4px",
          "&:hover": {
            backgroundColor: "#9e6dcf",
          },
        }}
      >
        <RemoveIcon />
      </IconButton>
      <span className="text-center w-12 text-lg font-medium">{count}</span>
      <IconButton
        onClick={() => updateTravelerCount(type, "increment")}
        sx={{
          border: "1px solid #ccc",
          borderRadius: "9999px",
          padding: "4px",
          "&:hover": {
            backgroundColor: "#9e6dcf",
          },
        }}
      >
        <AddIcon />
      </IconButton>
    </div>
  </Box>
);

const TravelersPopover: React.FC<TravelersPopoverProps> = ({
  open,
  anchorEl,
  handleClose,
  handleClick,
  adults,
  setAdults,
  youth,
  setYouth,
  children,
  setChildren,
  infantsOnLap,
  setInfantsOnLap,
  travelers,
  handleAgeChange,
  ageOptions,
  infantOptions,
  popoverWidth,
  label,
  updateTravelerCount,
}) => (
  <div>
    <TextField
      aria-describedby="travelers-popover"
      label={label}
      value={`${adults + youth + children + infantsOnLap} travelers`}
      onClick={handleClick}
      aria-readonly
      fullWidth
    />
    <Popover
      id="travelers-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: { width: popoverWidth, padding: "10px" },
      }}
    >
      <Box className="p-4 max-w-md w-full md:max-w-xl overflow-auto max-h-96">
        <TravelerSection
          label="Adults"
          count={adults}
          type="adults"
          updateTravelerCount={updateTravelerCount}
        />
        <TravelerSection
          label="Youth"
          count={youth}
          type="youth"
          updateTravelerCount={updateTravelerCount}
        />
        <TravelerSection
          label="Children"
          count={children}
          type="children"
          updateTravelerCount={updateTravelerCount}
        />
        <TravelerSection
          label="Infants on Lap"
          count={infantsOnLap}
          type="infantsOnLap"
          updateTravelerCount={updateTravelerCount}
        />
      </Box>
    </Popover>
  </div>
);

export default TravelersPopover;
