import { gql } from "@apollo/client";

export const SEND_GSC_DATA = gql`
  mutation SendGSCData(
    $action: String!
    $client_email: String!
    $private_key: String!
    $url: String!
  ) {
    GSC(
      arg1: {
        action: $action
        client_email: $client_email
        private_key: $private_key
        url: $url
      }
    ) {
      response
    }
  }
`;
