import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";
import { Divider } from "@mui/material";
import SelectBox from "components/FormInputs/SelectBox";
import { RoomTypes } from "../../../../../data/InitalDefaultValue";
// Import Components
import InputField from "components/FormInputs/InputField";
import useNotifications from "hooks/useNotifications";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import { STAY_EDIT_ROOM } from "../../../../../graphql/Mutation";

const EditRoom = ({
  room,
  setHideForm,
}: any) => {

  const [editRoom] = useMutation(STAY_EDIT_ROOM);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      room_type: room?.room_type || null,
      room_no: room?.room_no || null,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const res = await editRoom({
        variables: {
          id: room.id,
          roomNo: data.room_no ? data?.room_no : room?.room_no,
          roomType: data?.roomType ? data?.roomType : room?.room_type
        }
      });

      if (res?.data?.update_stay_inq_rooms?.returning?.[0]?.id) {
        toast.success("Room Updated Successfully!");
        setHideForm(false)
      }
    } catch (err) {
      toast.error(`Error Updating Room ${err}`);
    }
  };

  return (
    <>
      <div className="h-full min-w-[500px] bg-white dark:bg-dark-primary flex flex-col p-[25px] items-center gap-[20px] text-left text-mini shadow text-darkslategray">
        <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
          Edit Room
        </h2>
        <Divider />
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-start">
            <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
              <div className="self-stretch relative leading-[130%]">
                <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 mt-2 items-center w-full">
                  <SelectBox
                    errors={errors}
                    control={control}
                    fieldName={"roomType"}
                    label="Room Type"
                    options={RoomTypes}
                    selectedOption={
                      RoomTypes.filter(
                        (type: any) => type.value === room.room_type
                      )[0]
                    }
                    required={true}
                    optionContainerClasses="dark:bg-dark-secondary"
                    inputClasses="border rounded border-gray-200 pl-3 focus:outline-none focus:border-basic dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
                    labelClasses="dark:text-dark-primary"
                    checkIconClasses="text-basic"
                    optionClasses="text-primary dark:text-dark-primary"
                    optionHoverClasses="bg-primary dark:bg-dark-primary"
                  />
                  <InputField
                    label="Room Number"
                    register={register}
                    type="text"
                    errors={errors}
                    defaultValue={getValues("room_no")}
                    placeHolder="Room Number"
                    fieldName={`room_no`}
                    inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <button
              type="submit"
              className="w-full btn bg-basic text-white rounded-md p-2"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditRoom;
