import { Avatar, IconButton, Popover } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import SideBar from "../sidebar/SideBar";
import Profile from "./components/Profile";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import Brightness4OutlinedIcon from "@mui/icons-material/Brightness4Outlined";

import { useUserData, useUserDefaultRole } from "@nhost/react";

import { useNavigate } from "react-router-dom";

import WhatsappSVG from "~/assets/svg/WhatsappSVG";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Brandfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Brandfilter";

import { URLs } from "~/config/enums";
import OtpComp from "./components/OtpComp";

function stringAvatar(name: any) {
  return {
    sx: {
      bgcolor: "#9155FD",
    },
    children: `${
      name.trim().split(" ").length > 1
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${name[0]}`
    }`,
  };
}

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showEmailNotifications, setShowEmailNotifications] = useState(false);
  const [showNotificationBar, setShowNotificationBar] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const role = useUserDefaultRole();

  const userData = useUserData();

  const navigate = useNavigate();

  const storedDarkMode = localStorage.getItem("darkMode");

  const [darkMode, setDarkMode] = useState(
    storedDarkMode ? JSON.parse(storedDarkMode) : false
  );

  const root = document.documentElement;

  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

  useEffect(() => {
    const handleSystemColorChange = (e: any) => {
      if (e.matches) {
        root.classList.add("dark");
        localStorage.setItem("darkMode", JSON.stringify(true));
        window.dispatchEvent(new Event("storage"));
      } else {
        root.classList.remove("dark");
        localStorage.setItem("darkMode", JSON.stringify(false));
        window.dispatchEvent(new Event("storage"));
      }
    };
    mediaQuery.addEventListener("change", handleSystemColorChange);
  }, []);

  useEffect(() => {
    if (
      !("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      if (storedDarkMode && JSON.parse(storedDarkMode) === false) {
        root.classList.remove("dark");
        setDarkMode(false);
        localStorage.setItem("darkMode", JSON.stringify(false));
        window.dispatchEvent(new Event("storage"));
      } else if (storedDarkMode && JSON.parse(storedDarkMode) === true) {
        root.classList.add("dark");
        setDarkMode(true);
        localStorage.setItem("darkMode", JSON.stringify(true));
        window.dispatchEvent(new Event("storage"));
      } else {
        root.classList.add("dark");
        setDarkMode(true);
        localStorage.setItem("darkMode", JSON.stringify(true));
        window.dispatchEvent(new Event("storage"));
      }
    } else {
      if (storedDarkMode && JSON.parse(storedDarkMode) === false) {
        root.classList.remove("dark");
        setDarkMode(false);
        localStorage.setItem("darkMode", JSON.stringify(false));
        window.dispatchEvent(new Event("storage"));
      } else if (storedDarkMode && JSON.parse(storedDarkMode) === true) {
        root.classList.add("dark");
        setDarkMode(true);
        localStorage.setItem("darkMode", JSON.stringify(true));
        window.dispatchEvent(new Event("storage"));
      } else {
        root.classList.remove("dark");
        setDarkMode(false);
        localStorage.setItem("darkMode", JSON.stringify(false));
        window.dispatchEvent(new Event("storage"));
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
    window.dispatchEvent(new Event("storage"));
  }, [darkMode]);

  return (
    <>
      {/* min-h-[64px] */}
      <div className="w-[100%] text-primary bg-primary dark:bg-dark-primary flex flex-col md:flex-row items-center justify-between px-4 pt-5">
        <OtpComp />

        <div className="flex flex-col md:flex-row gap-2 items-center">
          <div className="min-w-[200px] mr-3">
            <Brandfilter />
          </div>
          <div className="w-full flex items-center justify-between md:w-auto md:flex-auto">
            <IconButton
              onClick={() => {
                if (!(root.className === "dark")) {
                  root.classList.add("dark");
                  setDarkMode(true);
                } else {
                  root.classList.remove("dark");
                  setDarkMode(false);
                }
              }}
            >
              <Brightness4OutlinedIcon />
            </IconButton>
            <IconButton>
              <AppsOutlinedIcon />
            </IconButton>

            <IconButton
              onClick={() => {
                navigate(`/${role}/emails`);
              }}
            >
              <EmailOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                navigate(`/${role}/whatsapp`);
              }}
            >
              <WhatsappSVG />
            </IconButton>

            <IconButton
              onClick={(e: any) => {
                if (showMenu) {
                  setShowMenu(!showMenu);
                  setShowUserDetails(!showUserDetails);
                  setShowEmailNotifications(!showEmailNotifications);
                } else if (showNotificationBar) {
                  setShowNotificationBar(!showNotificationBar);
                  setShowUserDetails(!showUserDetails);
                  setShowEmailNotifications(!showEmailNotifications);
                } else if (showEmailNotifications) {
                  setShowNotificationBar(!showNotificationBar);
                  setShowUserDetails(!showUserDetails);
                  setShowEmailNotifications(!showEmailNotifications);
                } else {
                  setShowUserDetails(!showUserDetails);
                }
                setAnchorEl(e.currentTarget);
              }}
            >
              {!userData?.avatarUrl ? (
                <Avatar
                  style={{
                    fontSize: "17px",
                    fontWeight: "bolder",
                    width: "30px",
                    height: "30px",
                  }}
                  {...stringAvatar(userData?.displayName?.toUpperCase())}
                />
              ) : (
                <img
                  className="h-[30px] w-[30px] rounded-full object-cover"
                  src={URLs.FILE_URL + userData?.avatarUrl}
                />
              )}

              <Popover
                sx={{
                  background: "transparent",
                  top: "5px",
                  right: "3px",
                }}
                anchorEl={anchorEl}
                open={showUserDetails}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Profile userData={userData} stringAvatar={stringAvatar} />
              </Popover>
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
