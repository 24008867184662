import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Button,
  Autocomplete,
  Box,
} from "@mui/material";
import { SEND_GSC_DATA } from "~/graphql/GSC/Mutation";
import { toast } from "react-toastify";
import _airlineData from "../../../components/FormInputs/AirlineBox/data/airlines.json";
import _airportsData from "../../../components/FormInputs/LocationBox/data/airports.json";
import ContentEditor from "../ContentEditor";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { GET_BRAND_DETAIL } from "~/graphql/brd_list/Query";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { INSERT_BRD_GSC_PAGE_INFO } from "~/graphql/brd_gsc_page_info/Mutation";

interface BrandPostDetailProps {
  setLogoFile: (file: any) => void;
  addPost: (data: any) => void;
  content: string;
  setContent: (content: string) => void;
  register: any;
  control: any;
  handleSubmit: any;
  errors: any;
  setType: string;
  pageData: any;
  setValue: any;
}

const BrandPostDetail = ({
  setLogoFile,
  addPost,
  content,
  setContent,
  register,
  control,
  handleSubmit,
  errors,
  setType,
  pageData,
  setValue,
}: BrandPostDetailProps) => {
  const [isGoogleIndexed, setIsGoogleIndexed] = useState(false);
  const { brd_id } = useParams();

  const { data } = useQuery(GET_BRAND_DETAIL, {
    variables: { brd_id },
  });

  const [insertBrdGscPageInfo, { error: insertError }] = useMutation(
    INSERT_BRD_GSC_PAGE_INFO
  );
  const [sendGscData, { error: gscError }] = useMutation(SEND_GSC_DATA);

  const handleGoogleIndexing = async () => {
    const type = "blog";
    const slug = pageData?.slug || "";
    const brandDetail = data?.brd_list?.[0];
    const baseUrl = brandDetail?.domain
      ? `https://${brandDetail.domain}`
      : `https://${brandDetail?.subdomain}`;
    const client_email = brandDetail.brd_secrets?.[0]?.gsc_email;
    const private_key = brandDetail.brd_secrets?.[0]?.gsc_private_key;

    const url = `${baseUrl}/${type}/${slug}`;
    const action = "add";

    const gscResponse = await sendGscData({
      variables: {
        client_email,
        private_key,
        url,
        action,
      },
    });

    if (gscResponse.data) {
      await insertBrdGscPageInfo({
        variables: { brd_id: brd_id, page_type: type, page_url: url },
      });
      toast.success("Google indexing and page info insertion successful!");
    }

    if (gscError) {
      toast.error("Failed to index page with Google.");
    }

    if (insertError) {
      toast.error("Failed to insert page info.");
    }
  };

  useEffect(() => {
    if (pageData) {
      setValue("post_title", pageData?.post_title || "");
      setValue("slug", pageData?.slug || "");
      setValue("canonical_url", pageData?.canonical_url || "");
      setValue("destination", pageData?.destination || null);
      setValue("airline", pageData?.airline || null);
      setValue("excerpt", pageData?.excerpt || "");
      setValue("is_google_indexed", pageData?.is_google_indexed || false);
      setIsGoogleIndexed(pageData?.is_google_indexed || false);
      setContent(pageData?.content || "");
    }
  }, [pageData, setValue, setContent]);

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];
    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setLogoFile({ file });
    }
  };

  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
      <h1 className="text-xl">Destination Base Settings</h1>
      <Box component="form" onSubmit={handleSubmit(addPost)} noValidate>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
          <Controller
            name="post_title"
            control={control}
            defaultValue={pageData?.post_title || ""}
            render={({ field }) => (
              <TextField
                {...field}
                label="Post Title"
                variant="outlined"
                error={!!errors.post_title}
                helperText={errors.post_title ? errors.post_title.message : ""}
              />
            )}
          />
          {setType === "update" && (
            <Controller
              name="slug"
              control={control}
              defaultValue={pageData?.slug || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Slug"
                  variant="outlined"
                  error={!!errors.slug}
                  helperText={errors.slug ? errors.slug.message : ""}
                />
              )}
            />
          )}
          <Controller
            name="canonical_url"
            control={control}
            defaultValue={pageData?.canonical_url || ""}
            render={({ field }) => (
              <TextField
                {...field}
                label="Canonical URL"
                variant="outlined"
                error={!!errors.canonical_url}
                helperText={
                  errors.canonical_url ? errors.canonical_url.message : ""
                }
              />
            )}
          />
          <Controller
            name="destination"
            control={control}
            rules={{ required: true }}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                onChange={(_, data) => field.onChange(data)}
                options={_airportsData || []}
                getOptionLabel={(option: any) =>
                  `${option.city_name || ""}-${option.iata_code}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Destination"
                    error={!!errors["destination"]}
                  />
                )}
              />
            )}
          />
          <div className={`${setType === "update" && "md:col-span-2"}`}>
            <Controller
              name="airline"
              control={control}
              rules={{ required: true }}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  onChange={(_, data) => field.onChange(data)}
                  options={_airlineData || []}
                  getOptionLabel={(option: any) => option.marketing_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Airline"
                      error={!!errors["airline"]}
                    />
                  )}
                />
              )}
            />
          </div>
          <div>
            <FormLabel>Post Excerpt</FormLabel>
            <textarea
              {...register("excerpt", { required: true })}
              className={`${
                errors?.excerpt
                  ? "border-red-600 bg-transparent rounded w-full focus:border-red-600 focus:ring-red-600 h-14 p-2"
                  : "border border-secondary dark:border-dark-secondary bg-transparent rounded w-full focus:border-basic focus:ring-basic h-14 p-2"
              }`}
            />
          </div>
          <div>
            <FormLabel className="mb-2">Featured Image</FormLabel>
            <Controller
              name="featured_image"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: "100%" }}
                  type="file"
                  inputProps={{
                    style: {
                      paddingTop: "7px",
                      paddingBottom: "27px",
                      paddingLeft: "23px",
                    },
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    handleLogoUpload(e);
                    field.onChange(e);
                  }}
                  helperText="SVG, PNG, JPG or GIF (MAX. 800x400px)"
                />
              )}
            />
          </div>
        </div>
        <div className="col-span-2">
          <FormControlLabel
            control={
              <Controller
                name="is_google_indexed"
                control={control}
                defaultValue={pageData?.is_google_indexed || false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={isGoogleIndexed}
                    onChange={(e) => {
                      setIsGoogleIndexed(e.target.checked);
                      setValue("is_google_indexed", e.target.checked);
                      field.onChange(e.target.checked);
                    }}
                    name="is_google_indexed"
                    color="primary"
                  />
                )}
              />
            }
            label="Is Google Indexed"
          />
        </div>
        <div className="col-span-6 sm:col-full">
          <label className="mb-2 block">Post Content</label>
          <ContentEditor content={content} setContent={setContent} />
        </div>
        <Button type="submit" variant="contained" sx={{ marginTop: "20px" }}>
          Save Post
        </Button>
        <br />
        {isGoogleIndexed && pageData?.canonical_url === "" && (
          <Button
            type="button"
            variant="contained"
            sx={{ marginTop: "20px" }}
            onClick={handleGoogleIndexing}
          >
            Send for Google Indexing
          </Button>
        )}
      </Box>
    </div>
  );
};

export default BrandPostDetail;
