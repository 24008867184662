import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import {
  Button,
  TextField,
  FormControl,
  Autocomplete,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UPDATE_BRD_VISAS } from "~/graphql/brd_visas/Mutation";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GET_BRD_VISA_TYPES } from "~/graphql/brd_visa_types/Query";

const EditVisas = ({ selectedVisa, setSidePopup, brd_id }: any) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm<any>();

  const client = useApolloClient();
  const [updateVisa, { loading }] = useMutation(UPDATE_BRD_VISAS);

  // Pre-fill the form with selected visa data on component mount
  useEffect(() => {
    if (selectedVisa) {
      reset({
        type: selectedVisa.type,
        validity: selectedVisa.validity,
        price: selectedVisa.price,
      });
    }
  }, [selectedVisa, reset]);

  const { data } = useQuery(GET_BRD_VISA_TYPES, {
    variables: {
      brd_id,
    },
  });

  const [type, setType] = useState<any>(null);

  useEffect(() => {
    const defValue = data?.brd_visa_types?.filter(
      (item: any) => item.id === selectedVisa.type
    )?.[0];

    setType(defValue);
  }, [data?.brd_visa_types]);

  const onSubmit = async (data: any) => {
    try {
      const response = await updateVisa({
        variables: {
          id: selectedVisa?.id,
          type: data.type,
          validity: data.validity,
          price: data.price,
        },
      });

      if (response?.data?.update_brd_visas?.affected_rows > 0) {
        await client.refetchQueries({
          include: "all",
        });
        toast.success("Visa updated successfully.");
        setSidePopup(false); // Close the side popup after success
      }
    } catch (error) {
      console.error("Error updating visa:", error);
      toast.error("Error updating visa.");
    }
  };

  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <div className="bg-secondary mb-4 sm:p-6 rounded-lg shadow dark:bg-dark-secondary">
        <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
          Edit Visa
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-primary dark:text-dark-primary flex-col"
        >
          <div className="flex flex-col space-y-4">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("type", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  value={type}
                  onChange={(_, data: any) => {
                    field.onChange(data?.id);
                    setType(data);
                  }}
                  options={data?.brd_visa_types ? data?.brd_visa_types : []}
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Type"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["type"] && el.focus();
                      }}
                      error={errors["type"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />

            <TextField
              label="Validity"
              variant="outlined"
              fullWidth
              required
              {...register("validity")}
              className="dark:text-dark-primary"
            />
            <TextField
              label="Price"
              variant="outlined"
              fullWidth
              required
              {...register("price")}
              className="dark:text-dark-primary"
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Changes"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditVisas;
