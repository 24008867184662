import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import TagFacesOutlinedIcon from "@mui/icons-material/TagFacesOutlined";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import { Popover, TextField, makeStyles } from "@mui/material";
import Add from "./Add";
import { useEffect, useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import EmojiPicker from "emoji-picker-react";
import { INSERT_MESSAGES } from "../../graphql/Mutation";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useUserId } from "@nhost/react";
import SendVoice from "./SendVoice";
import SingleFile from "~/components/SideForm/WhatsappSend/components/Files/componets/SingleFile";
import { INSERT_COMMUNITY_MESSAGE } from "~/graphql/brd_whatsapp_community_messages/Mutation";
import { WHATASPP_SETTING_INSERT } from "~/graphql/brd_whatsapp_setting/Mutation";

const Footer = ({
  setShowEmojis,
  showEmojis,
  setShowModal,
  footerType,
  setFiles,
  files,
  attachmentType,
  setAttachmentType,
  community = false,
  sendMessage,
  replyMessageID,
  conversation_id,
}: any) => {
  const [showRecording, setShowRecording] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [insertMessages] = useMutation(INSERT_MESSAGES);
  const [insertComunityMessages] = useMutation(INSERT_COMMUNITY_MESSAGE);
  const [messageData, setMessageData] = useState("");
  const [isSending, setIsSending] = useState<any>(false);
  const userId = useUserId();

  const [filesIds, setFilesId] = useState<any>([]);

  // const { conversation_id } = useParams();

  const { handleSubmit } = useForm();

  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOnInputChange = (e: any) => {
    setIsTyping(e.target.value.trim() === "" ? false : true);
  };

  const handleInputChange = (event: any): any => {
    setInputValue(event.target.value);
  };

  const handleEmojiClick = (event: any) => {
    setInputValue(inputValue + event.emoji);
  };

  async function sendWhatsappMessage() {
    if (!isSending) {
      setIsSending(true);

      if (files?.length > 0) {
        filesIds.map(async (file: any) => {
          const currentTime = moment().utc();
          const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");

          const payload: any = {
            sender_id: userId,
            text: file.id,
            type: attachmentType,
            timestamp: formattedTime,
          };
          if (!community) {
            payload["conversation_id"] = conversation_id;
            payload["sender_role"] = "brand";
            const resMessagesInsert = await insertMessages({
              variables: { data: [payload] },
            });
          } else {
            payload["community_id"] = conversation_id;
            const resMessagesInsert = await insertComunityMessages({
              variables: { data: [payload] },
            });
          }
        });
        setFiles([]);
        setFilesId([]);
      } else {
        const textmsg = inputValue.trim();
        if (textmsg !== "") {
          const currentTime = moment().utc();
          const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");

          const payload: any = {
            sender_id: userId,
            text: textmsg,
            type: "text",
            timestamp: formattedTime,
            reply_message_id: replyMessageID || null,
          };

          if (!community) {
            payload["conversation_id"] = conversation_id;
            payload["sender_role"] = "brand";
            const resMessagesInsert = await insertMessages({
              variables: { data: [payload] },
            });
          } else {
            payload["community_id"] = conversation_id;
            const resMessagesInsert = await insertComunityMessages({
              variables: { data: [payload] },
            });
          }
        }
      }
      setInputValue("");
      setIsTyping(false);
      setIsSending(false);
    }
  }

  const sendAutoReplyMsg = async ({ message, type }: any) => {
    if (message) {
      await sendMessage({ type: type, message: message });
    } else {
      await sendMessage({ message: inputValue, type: "text" });
      setInputValue("");
      setIsTyping(false);
    }
  };

  const handleKeyDown = async (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent default form submission
      if (footerType === "autoReply") {
        await sendAutoReplyMsg({ message: inputValue, type: "text" });
        setInputValue("");
      } else {
        await sendWhatsappMessage();
      }
    }
  };

  const removeFile = (filesToRemove: any) => {
    const updatedFiles = files?.filter(
      (file: any) => filesToRemove?.id !== file?.id
    );

    setFiles(updatedFiles);
  };

  useEffect(() => {}, [files]);

  useEffect(() => {}, [attachmentType]);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      {showRecording ? (
        <SendVoice
          setShowRecording={setShowRecording}
          sendAutoReplyMsg={sendAutoReplyMsg}
          footerType={footerType}
        />
      ) : (
        <form
          onSubmit={handleSubmit(
            footerType === "autoReply" ? sendAutoReplyMsg : sendWhatsappMessage
          )}
          className="bg-whatsappPrimaryBg dark:bg-dark-primary px-[16px] py-[10px] flex items-center text-primary dark:text-dark-primary z-10"
        >
          <div onClick={(e: any) => setAnchorEl(e.currentTarget)}>
            {showMenu ? (
              <CloseOutlinedIcon
                sx={{
                  color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
                onClick={(e: any) => {
                  setShowMenu(true);
                }}
              />
            ) : (
              <AddOutlinedIcon
                sx={{
                  color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
                onClick={() => {
                  setShowMenu(true);
                }}
              />
            )}
          </div>

          <Popover
            sx={{
              backgroundColor: "transparent",
              top: "-20px",
              cursor: "pointer",
            }}
            anchorEl={anchorEl}
            onClose={() => {
              setShowMenu(!showMenu);
            }}
            open={showMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Add
              setAttachmentType={setAttachmentType}
              setShowMenu={setShowMenu}
              setFiles={setFiles}
              setShowModal={setShowModal}
              footerType={footerType}
            />
          </Popover>

          <div
            className="relative flex items-end w-full mx-[16px] "
            onClick={(e: any) => setAnchorEl(e.currentTarget)}
          >
            <TagFacesOutlinedIcon
              sx={{
                position: "absolute",
                left: "10px",
                bottom: "10px",
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                cursor: "pointer",
                zIndex: 1,
              }}
              onClick={() => {
                setShowEmojis(!showEmojis);
              }}
            />

            <Popover
              sx={{
                backgroundColor: "transparent",
                top: "-15px",
                cursor: "pointer",
              }}
              anchorEl={anchorEl}
              onClose={() => {
                setShowEmojis(!showEmojis);
              }}
              open={showEmojis}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </Popover>
            <TextField
              fullWidth
              multiline
              onChange={handleInputChange}
              onInput={handleOnInputChange}
              onKeyDown={handleKeyDown}
              value={inputValue}
              maxRows={4}
              sx={{
                "& fieldset": { border: "none" },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: darkMode
                    ? footerType === "documentPreview"
                      ? "#28183D"
                      : "#312D4B"
                    : "#FFFFFF",
                  padding: "10px 45px",
                  borderRadius: "10px",
                },
              }}
            />
          </div>
          {isTyping || files?.length > 0 ? (
            <button type="submit">
              <SendOutlinedIcon
                sx={{
                  color: "#3A3541DE",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
              />
            </button>
          ) : (
            <MicOutlinedIcon
              sx={{
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                cursor: "pointer",
                fontSize: "30px",
              }}
              onClick={() => setShowRecording(true)}
            />
          )}
        </form>
      )}
    </>
  );
};

export default Footer;
