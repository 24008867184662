import { gql } from "@apollo/client";

export const GET_CUSTOMERS = gql`
  query GetCustomers(
    $condition: users_bool_exp!
    $th_id: uuid!
    $brd_ids: [uuid!]!
  ) {
    users(where: $condition) {
      email
      id
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      lastSeen

      brd_users(
        where: {
          _and: [{ thp_id: { _eq: $th_id } }, { brd_id: { _in: $brd_ids } }]
        }
      ) {
        user_id
        brd_id
        thp_id
        brd_list {
          name
        }
      }
    }
  }
`;

export const GET_TRANSACTIONS = gql`
  query GetTransaction($userID: uuid, $brdIds: [uuid!]) {
    acc_transactions(
      where: { user_id: { _eq: $userID }, brd_id: { _in: $brdIds } }
      order_by: { created_at: desc }
    ) {
      brd_id
      acc_id
      inq_id
      payment_method_id
      def_acc_id
      user_id
      transactions_no
      date
      id
      type
      amount
      note
      paid
      inq_ticket {
        ibe
      }
      brd_list {
        name
      }
      inq_list {
        inquiry_no
        picked_user {
          thp_users_profile {
            pseudo_name
          }
        }
      }
      acc_list {
        type
        name
      }
      def_acc_list {
        type
        name
      }
      users {
        displayName
        defaultRole
      }
      acc_payment_method {
        name
      }
    }
  }
`;

export const ADD_TRANSACTION = gql`
  mutation MyMutation($transactions: [acc_transactions_insert_input!]!) {
    insert_acc_transactions(objects: $transactions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
