import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Typography,
  FormLabel,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface Room {
  adults: number;
  children: number;
}

const RoomForm: React.FC<{
  value: Room[];
  onChange: (newValue: Room[]) => void;
  errors: any;
  setValue?: any;
  fieldName?: string;
  label?: string;
  inquiryPassengers: any;
}> = ({
  value = [{ adults: 1, children: 0 }],
  onChange,
  label,
  inquiryPassengers,
}) => {
  const [rooms, setRooms] = useState<Room[]>(value);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const id = "room-popover";
  const popoverWidth = "300px";

  useEffect(() => {
    if (Array.isArray(inquiryPassengers) && inquiryPassengers.length > 0) {
      const adultsCount = inquiryPassengers.filter(
        (passenger: any) =>
          passenger.type === "adult" || passenger.type === "youth"
      ).length;

      const childrenCount = inquiryPassengers.filter(
        (passenger: any) => passenger.type === "child"
      ).length;

      const updatedRooms = [
        {
          adults: adultsCount,
          children: childrenCount,
        },
      ];

      setRooms(updatedRooms);
      onChange(updatedRooms);
    }
  }, [inquiryPassengers, onChange]);

  const handleRoomClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleRoomClose = () => setOpen(false);

  const handleAddRoom = () => {
    const totalAdults = rooms.reduce((sum, room) => sum + room.adults, 0);
    const totalChildren = rooms.reduce((sum, room) => sum + room.children, 0);

    const maxAdults = inquiryPassengers.filter(
      (passenger: any) =>
        passenger.type === "adult" || passenger.type === "youth"
    ).length;

    const maxChildren = inquiryPassengers.filter(
      (passenger: any) => passenger.type === "child"
    ).length;

    if (totalAdults + 1 <= maxAdults && totalChildren <= maxChildren) {
      const newRooms = [...rooms, { adults: 1, children: 0 }];
      setRooms(newRooms);
      onChange(newRooms);
    }
  };

  const handleRemoveRoom = (index: number) => {
    if (rooms.length <= 1) return; // Ensure at least one room remains

    const removedRoom = rooms[index]; // Get the room being removed
    const remainingRooms = rooms.filter((_, i) => i !== index); // Filter out the removed room

    // Distribute adults and children from the removed room back to the remaining rooms
    const newRooms = remainingRooms.map((room, i) => {
      if (i === 0) {
        // Add removed adults and children to the first room as an example
        return {
          adults: room.adults + removedRoom.adults,
          children: room.children + removedRoom.children,
        };
      }
      return room; // Other rooms remain the same
    });

    setRooms(newRooms);
    onChange(newRooms);
  };

  const handleRoomChange = (
    index: number,
    type: "adults" | "children",
    value: number
  ) => {
    const totalAdults = rooms.reduce((sum, room) => sum + room.adults, 0);
    const totalChildren = rooms.reduce((sum, room) => sum + room.children, 0);

    const maxAdults = inquiryPassengers.filter(
      (passenger: any) =>
        passenger.type === "adult" || passenger.type === "youth"
    ).length;

    const maxChildren = inquiryPassengers.filter(
      (passenger: any) => passenger.type === "child"
    ).length;

    const newTotal =
      type === "adults"
        ? totalAdults - rooms[index].adults + value
        : totalChildren - rooms[index].children + value;

    if (
      (type === "adults" && newTotal <= maxAdults) ||
      (type === "children" && newTotal <= maxChildren)
    ) {
      const newRooms = [...rooms];

      if (type === "children") {
        // Prevent reducing children if there is only one room
        if (rooms.length === 1 && value < rooms[index].children) {
          return; // Don't allow decreasing children if there's only one room
        }

        if (rooms.length > 1 && value === 0) {
          // Redistribute children to other rooms if there are multiple rooms
          const childrenToRedistribute = newRooms[index].children;
          let remainingChildren = childrenToRedistribute;
          newRooms[index].children = 0;

          for (let i = 0; i < newRooms.length && remainingChildren > 0; i++) {
            if (i !== index) {
              const canAdd = maxChildren - newRooms[i].children;
              const toAdd = Math.min(canAdd, remainingChildren);
              newRooms[i].children += toAdd;
              remainingChildren -= toAdd;
            }
          }

          // Prevent losing children if redistribution is incomplete
          if (remainingChildren > 0) {
            newRooms[index].children += remainingChildren;
          }
        } else {
          // Regular update for children count
          newRooms[index].children = value;
        }
      } else if (type === "adults") {
        newRooms[index].adults = Math.max(1, value); // Ensure at least 1 adult per room
      }

      setRooms(newRooms);
      onChange(newRooms);
    }
  };

  const canAddRoom = () => {
    const totalAdults = rooms.reduce((sum, room) => sum + room.adults, 0);
    const maxAdults = inquiryPassengers.filter(
      (passenger: any) =>
        passenger.type === "adult" || passenger.type === "youth"
    ).length;

    return totalAdults < maxAdults;
  };

  return (
    <div className="w-full">
      <TextField
        aria-describedby={id}
        label={label || "Travelers"}
        value={`Rooms: ${rooms.length}`}
        onClick={handleRoomClick}
        aria-readonly
        fullWidth
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleRoomClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{ style: { width: popoverWidth } }}
      >
        <Box className="p-4 max-w-md w-full md:max-w-xl overflow-auto max-h-96">
          {rooms.map((room, index) => (
            <Box key={index} className="mb-4 p-4 rounded-lg mx-w-md w-full">
              <FormLabel sx={{ fontWeight: "bold" }}>{`Room ${
                index + 1
              }`}</FormLabel>

              <Box className="flex gap-4 mt-2 justify-between">
                <Typography>Adults:</Typography>
                <div className="flex items-center justify-center gap-2">
                  <IconButton
                    onClick={() =>
                      handleRoomChange(
                        index,
                        "adults",
                        Math.max(1, rooms[index].adults - 1)
                      )
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>

                  <span className="text-center w-12 text-lg font-medium">
                    {rooms[index].adults}
                  </span>

                  <IconButton
                    onClick={() =>
                      handleRoomChange(index, "adults", rooms[index].adults + 1)
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Box>

              <Box className="flex gap-4 mt-2 justify-between">
                <Typography>Children:</Typography>
                <div className="flex items-center justify-center gap-2">
                  <IconButton
                    onClick={() =>
                      handleRoomChange(
                        index,
                        "children",
                        Math.max(0, rooms[index].children - 1)
                      )
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>

                  <span className="text-center w-12 text-lg font-medium">
                    {rooms[index].children}
                  </span>

                  <IconButton
                    onClick={() =>
                      handleRoomChange(
                        index,
                        "children",
                        rooms[index].children + 1
                      )
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Box>

              {rooms.length > 1 && (
                <div className="flex w-full justify-end mt-2">
                  <Button onClick={() => handleRemoveRoom(index)}>
                    Remove Room
                  </Button>
                </div>
              )}
            </Box>
          ))}
          {canAddRoom() && <Button onClick={handleAddRoom}>Add Room</Button>}
        </Box>
      </Popover>
    </div>
  );
};

export default RoomForm;
