import React, { useState } from "react";
import PassengerBox from "../components/PassengerBox";
import PassengerAdd from "../components/PassengerAdd";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import SideDrawer from "~/components/common/sideDrawer";
import NewPassangerAdd from "../components/NewPassengerAdd";
import NewPassengerAdd from "../components/NewPassengerAdd";
import NewPassengerBox from "../components/PassengerBox/NewPassengerBox";
import RoomCard from "./StayRoomCard";
import AddStayRoom from "../components/NewPassengerAdd/AddStayRoom";
import { Tabs, Tab } from "@mui/material";

const NewPassengerCard = ({ tabs, inquiry }: any) => {
  const userDefaultRole = useUserDefaultRole();
  const user_id = useUserId();
  const [addPassenger, setAddPassenger] = useState(false);
  const [addRoom, setAddRoom] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="self-stretch flex flex-col items-start justify-start mb-5">
      <div className="self-stretch flex items-center dark:text-primary mt-[30px]">
        <hr className="w-[2%] dark:border-[#E7E3FC61]" />
        <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
          PASSENGERS
        </span>
        <hr className="w-full dark:border-[#E7E3FC61]" />
        {inquiry.status !== "completed" && (
          <div className="min-w-fit pl-2">
            {!inquiry?.selected_suggestion &&
              (user_id === inquiry?.picked_by ||
                userDefaultRole === "admin") && (
                <button
                  onClick={() => setAddPassenger(true)}
                  className="block border border-basic rounded-full px-3 py-1 hover:text-basic cursor-pointer font-medium bg-basic dark:hover:bg-dark-primary hover:bg-white text-white"
                >
                  Add Passenger
                </button>
              )}
          </div>
        )}
      </div>

      <div className="mt-[30px] self-stretch grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 items-start justify-start gap-[33px] text-mini">
        {inquiry?.inq_passengers?.map((passenger: any, i: any) => {
          return (
            <NewPassengerBox
              key={i}
              passenger={passenger}
              inquiry={inquiry}
              inq_id={inquiry.id}
              totalPassengers={inquiry?.inq_passengers.length}
            />
          );
        })}
      </div>

      {tabs?.[0]?.label === "Hotel" && (
        <div className="w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
            <div className="self-stretch flex items-center dark:text-primary mt-[30px]">
              <hr className="w-[2%] dark:border-[#E7E3FC61]" />
              <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
                ROOMS
              </span>
              <hr className="w-full dark:border-[#E7E3FC61]" />
              {inquiry.status !== "completed" && (
                <div className="min-w-fit pl-2">
                  {!inquiry?.selected_suggestion &&
                    (user_id === inquiry?.picked_by ||
                      userDefaultRole === "admin") && (
                      <button
                        onClick={() => setAddRoom(true)}
                        className="block border border-basic rounded-full px-3 py-1 hover:text-basic cursor-pointer font-medium bg-basic dark:hover:bg-dark-primary hover:bg-white text-white"
                      >
                        Add Rooms
                      </button>
                    )}
                </div>
              )}
            </div>
          </div>
          {inquiry?.stay_inq_details?.length > 1 ? (
            <>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {inquiry?.stay_inq_details?.map(
                  (detail: any, index: number) => (
                    <Tab key={index} label={`Stay ${index + 1}`} />
                  )
                )}
              </Tabs>
              {inquiry?.stay_inq_details?.map((detail: any, index: number) => (
                <div
                  key={index}
                  role="tabpanel"
                  hidden={selectedTab !== index}
                  className="mt-[30px] self-stretch grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 items-start justify-start gap-[33px] text-mini"
                >
                  {selectedTab === index &&
                    detail?.stay_inq_rooms?.map((room: any, i: number) => (
                      <RoomCard
                        key={i}
                        room={room}
                        inquiry={inquiry}
                        inq_id={inquiry.id}
                      />
                    ))}
                </div>
              ))}
            </>
          ) : (
            <div className="mt-[30px] self-stretch grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 items-start justify-start gap-[33px] text-mini">
              {inquiry?.stay_inq_details?.[0]?.stay_inq_rooms?.map(
                (room: any, i: any) => (
                  <RoomCard
                    key={i}
                    room={room}
                    inquiry={inquiry}
                    inq_id={inquiry.id}
                  />
                )
              )}
            </div>
          )}
        </div>
      )}
      {addPassenger && (
        <SideDrawer
          setSidePopup={setAddPassenger}
          sidePopup={addPassenger}
          component={
            <NewPassengerAdd
              setAddPassenger={setAddPassenger}
              inqId={inquiry?.id}
            />
          }
        />
      )}

      {addRoom && (
        <SideDrawer
          setSidePopup={setAddRoom}
          sidePopup={addRoom}
          component={
            <AddStayRoom
              setAddRoom={setAddRoom}
              stayInqId={
                inquiry?.stay_inq_details?.length > 1
                  ? inquiry?.stay_inq_details?.[selectedTab]?.id
                  : inquiry?.stay_inq_details?.[0]?.id
              }
              user_id={inquiry?.users.id}
              picked_by={inquiry?.picked_by}
            />
          }
        />
      )}
    </div>
  );
};

export default NewPassengerCard;
