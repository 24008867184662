import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { SEND_EMAIL } from "~/config/Mutation";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserData, useUserDisplayName, useUserId } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import { URLs } from "~/config/enums";
import { pdf } from "@react-pdf/renderer";
import { nhost } from "~/lib/nhost";
import { ADD_TRAIN_SUGGESTION } from "../graphql/Mutation";
import useNotifications from "~/hooks/useNotifications";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import FlightPrices from "../components/FlightPrices";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PassengersCost from "../components/PassengersCost";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import SuggestionModelWraper from "../../../../SuggestionNav/components/SuggestionModelWraper";
import { trainFormatData } from "../../../utils/trainFormData";
import { TrainNameSelector } from "./components/TrainType";
import TrainSuggestionPDF from "./components/SuggestionPDF";
import TrainPassengerCost from "./components/TrainPassengerCost";
import moment from "moment";

export default function TrainSuggestionForm({
  defaultValue = {
    whereTo: "",
    train: {
      dates: [null, null],
      time: null,
    },
  },
  inquiry_id,
  inquiry,
  user_id,
  user_phone,
  user_email,
  brand_url,
  brand_name,
  brand_domain,
  passengers,
  setShowAddModal,
  setActive,
  showModal,
  onCloseModal,
  type,
}: any) {
  const [addTrainSuggestion] = useMutation(ADD_TRAIN_SUGGESTION);
  const client = useApolloClient();
  const [isLoading, setIsloading] = useState<any>(false);
  const userName = useUserDisplayName();
  const emailSend = useEmail();
  const today = dayjs();
  const userId = useUserId();
  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  const alert = useNotifications();
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
  });
  const [logo, setLogo] = useState<any>();

  async function convertLogo() {
    try {
      const response = await fetch(
        URLs.FILE_URL + inquiry?.brd_list?.brd_details?.[0]?.fav_icon
      );
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) { }
  }

  useEffect(() => {
    if (inquiry?.brd_list?.brd_details?.[0]?.fav_icon) {
      convertLogo();
    }
  }, [inquiry?.brd_list?.brd_details?.[0]?.fav_icon]);
  const uploadPdfToNhost = async (file: any) => {
    try {
      const response = await nhost.storage.upload({
        file,
      });

      if (response.error) {
        throw new Error(response.error.message);
      }

      return response.fileMetadata.id;
    } catch (error) {
      throw error;
    }
  };

  const getPdfId = async (formData: any) => {
    const blob = await pdf(
      <TrainSuggestionPDF
        inquiry={inquiry}
        logo={logo}
        suggestions={formData}
      />
    ).toBlob();

    const file = new File([blob], "invoice.pdf", {
      type: "application/pdf",
    });
    const pdfURL = URL.createObjectURL(blob);

    // Open the PDF in a new tab
    // window.open(pdfURL, "_blank");
    const pdfId = await uploadPdfToNhost(file);
    return pdfId;
  };

  const trainInqId = inquiry?.train_inq_details?.[0]?.id

  const onSubmit = async (data: any) => {
    setIsloading(true)
    try {
      const trainSuggestionData = {
        train_inq_id: trainInqId,
        origin: data?.train?.Origin,
        destination: data?.train?.Destination,
        cabin_class: data?.train?.cabinClass,
        train_name: data?.train?.trainName,
        start_date: moment(data?.train?.dates[0]?.$d).format("YYYY-MM-DD"),
        end_date: moment(data?.train?.dates[1]?.$d).format("YYYY-MM-DD"),
        time: moment(data?.train?.time?.$d).format("YYYY-MM-DDTHH:mm:ss"),
        pnr: null,
        train_inq_suggestion_costs: {
          data: data?.train?.passengers.map((passengerCost: any) => ({
            inq_passenger_id: passengerCost.id,
            basic_fare: passengerCost.basicFare,
            tax_price: passengerCost.taxPrice,
            sale_price: passengerCost.salePrice,
          })),
        },
      };

      const res = await addTrainSuggestion({
        variables: {
          data: trainSuggestionData,
        },
      });

      if (res?.data?.insert_train_inq_suggestion?.affected_rows > 0) {
        toast.success(`Suggestions Added Successfully!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (err) {
      toast.error(`Failed to Add Suggestion ${err}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setIsloading(false)
    }







    // data.train_inq_details = data.train_inq_details || {};
    // // Set stay_inq_id if it exists in stay_details
    // data.train_inq_details.inq_id = inquiry?.train_inq_details[0]?.id;

    // const pdfId = await getPdfId(trainFormatData(data, null));
    // setIsloading(true);
    // if (!isLoading) {
    //   try {
    //     // Create Suggestion function call

    //     await updateInqListUser({
    //       variables: { id: inquiry.id, updated_by: userId },
    //     });



    //     if (res?.data?.insert_train_inq_suggestion?.returning?.length > 0) {
    //       setActive(res?.data?.insert_train_inq_suggestion?.returning?.[0]?.id);

    //       const variables = {
    //         from: {
    //           th_name: brand_name,
    //         },
    //         to: {
    //           email: user_email,
    //         },
    //         variables: {
    //           phone: user_phone,
    //           url: brand_url,
    //         },
    //       };

    //       const emailVariables: any = {
    //         inquiryNo: inquiry?.inquiry_no,
    //         brandName: inquiry?.brd_list?.name,
    //         userFirstName: inquiry?.users?.displayName,
    //         userLastName: "",
    //         consultantName: userName,
    //       };

    //       // ;
    //       await emailSend(
    //         3,
    //         inquiry.brd_id,
    //         inquiry.users?.id,
    //         inquiry.users?.email,
    //         inquiry.users?.phoneNumber,
    //         emailVariables
    //       );

    // toast.success(`Suggestions added successfully`, {
    //   position: toast.POSITION.BOTTOM_RIGHT,
    // });

    //       await alert.newNotification(
    //         user_id,
    //         `Your consultant added a suggestion Please review in the portal.`,
    //         "",
    //         ``
    //       );

    //       await client.refetchQueries({
    //         include: "all", // Consider using "active" instead!
    //       });
    //     }
    //     setIsloading(false); // change button loading state
    //     setShowAddModal(false); // hide form modal
    //   } catch (e) {
    //     setIsloading(false); // change button loading state
    //   }
    // }
  };

  const handleDateRangeChange = (newValue: [Dayjs | null, Dayjs | null]) => {
    // Custom logic
  };
  return (
    <SuggestionModelWraper isVisible={showModal} onClose={onCloseModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-gray-100 rounded-lg p-2 dark:bg-[#28243D]">
          <div className="grid grid-cols-2 gap-4 mx-3">
            {/* Where To Field */}
            <Controller
              control={control}
              name={`train.Origin`} // This stores the value as train.Origin or train.Destination
              rules={{ required: true }} // Add validation rules
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <TextField
                      id={`outlined-basic-Origin`}
                      label="Origin" // Use the original field name for the label
                      variant="outlined"
                      {...field} // Spread the controlled field props
                      error={!!error} // Show error state if validation fails
                      helperText={error ? "This field is required" : ""} // Display error message
                    />
                  </>
                );
              }}
            />
            {/* Destination  */}
            <Controller
              control={control}
              name={`train.Destination`} // This stores the value as train.Origin or train.Destination
              rules={{ required: true }} // Add validation rules
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <TextField
                      id={`outlined-basic-Destination}`}
                      label="Destination" // Use the original field name for the label
                      variant="outlined"
                      {...field} // Spread the controlled field props
                      error={!!error} // Show error state if validation fails
                      helperText={error ? "This field is required" : ""} // Display error message
                    />
                  </>
                );
              }}
            />
            <FormControl className="mx-3 " fullWidth>
              <Controller
                control={control}
                rules={{ required: "This is required" }}
                {...register("train.cabinClass", { required: true })}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    onChange={(_, data: any) => {
                      field.onChange(data);
                    }}
                    options={["Economy", "Business"]}
                    getOptionLabel={(option: any) => option}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          label="Cabin Class"
                          error={!!errors["cabinClass"]}
                          {...params}
                        />
                        {error && (
                          <span className="text-red-500 text-xs">
                            {error.message}
                          </span> // Extract the error message here
                        )}
                      </>
                    )}
                    noOptionsText=""
                  />
                )}
              />
            </FormControl>
            <FormControl className="mx-3 " fullWidth>
              <Controller
                name="train.trainName" // The name for the input
                control={control}
                defaultValue="" // Set to empty for initial state
                render={({ field, fieldState: { error } }) => (
                  <>
                    <TrainNameSelector
                      {...field} // Spread the field props to connect with react-hook-form
                      register={register}
                      errors={errors}
                      onChange={(value: any) => {
                        // Update the value on change

                        field.onChange(value); // Update the form state
                      }}
                      required
                    />
                    {error && (
                      <span className="text-red-500 text-xs">
                        {error.message}
                      </span> // Extract the error message here
                    )}
                  </>
                )}
              />
              {/* <TrainNameSelector
                name="train.trainName"
                register={register}
                errors={errors}
              /> */}
            </FormControl>
            <FormControl>
              <Controller
                control={control}
                rules={{
                  validate: (value) =>
                    value && value[0] && value[1]
                      ? true
                      : "This field is required", // Ensure both dates are selected
                }}
                name="train.dates"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["SingleInputDateRangeField"]}
                          sx={{ margin: 0, padding: 0 }}
                        >
                          <DateRangePicker
                            value={value || [null, null]}
                            onChange={(newValue) => {
                              onChange(newValue); // Update the form value
                              handleDateRangeChange(
                                newValue as [Dayjs | null, Dayjs | null]
                              ); // Custom handler
                            }}
                            minDate={today}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {error && (
                        <span className="text-red-500 text-xs">
                          {error.message}
                        </span> // Extract the error message here
                      )}
                    </>
                  );
                }}
              />
            </FormControl>
            <FormControl>
              <Controller
                control={control}
                name="train.time"
                rules={{ required: "This is requireds" }} // Custom validation message
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Select Train Time"
                        value={value} // Controlled value from form state
                        onChange={(newValue) => {
                          onChange(newValue); // Update the form value
                        }}
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error ? error.message : null,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </>
                )}
              />
            </FormControl>
          </div>
        </div>
        <hr className="border-gray-300 border-dashed my-4" />
        {/* <PassengersCost
          passengers={passengers}
          errors={errors}
          register={register}
          control={control}
          setValue={setValue}
          watch={watch}
          type={type}
        /> */}
        <TrainPassengerCost
          passengers={passengers}
          errors={errors}
          register={register}
          control={control}
          setValue={setValue}
          watch={watch}
        />
        {/* Submit form button */}
        <button
          className={` ${isLoading ? "bg-basic cursor-wait" : "bg-basic"
            } h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
          type="submit"
        >
          {isLoading ? "Creating.." : "Create Suggestion"}
        </button>
      </form>
    </SuggestionModelWraper>
  );
}
