import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Divider } from "@mui/material";
import InputField from "components/FormInputs/InputField";
import SelectBox from "components/FormInputs/SelectBox";
import { ADD_STAY_ROOMS } from "../../../graphql/Mutation";
import { RoomTypes } from "../../../data/InitalDefaultValue";

const AddStayRoom = ({
  stayInqId,
  setAddRoom,
  user_id,
  picked_by,
  tabLabel,
  inquiry,
}: any) => {
  const [addRoom] = useMutation(ADD_STAY_ROOMS);
  const {
    handleSubmit,
    register,
    formState: { errors }, // Correct destructuring here
    control,
    setValue,
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      const addRoomData = {
        stay_inq_id: stayInqId,
        room_type: data?.roomType,
        room_no: data?.room_no,
      };

      const res = await addRoom({
        variables: {
          data: addRoomData,
        },
      });

      if (res?.data?.insert_stay_inq_rooms?.affected_rows > 0) {
        toast.success("New Room Added Successfully!");
        setAddRoom(false);
      }
    } catch (err) {
      toast.error(`Error Adding Room ${err}`);
    }
  };

  return (
    <div className="self-stretch flex-1 min-w-[500px] h-full bg-white dark:bg-dark-primary flex flex-col p-[25px] items-center gap-[20px] text-left text-mini text-darkslategray shadow">
      <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
        Add Room
      </h2>
      <Divider />
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-start">
          <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
            <div className="self-stretch relative leading-[130%]">
              <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 mt-2 items-center w-full">
                <SelectBox
                  errors={errors}
                  control={control}
                  fieldName={"roomType"}
                  label="Room Type"
                  options={RoomTypes}
                  selectedOption={RoomTypes[0]}
                  required={true}
                  optionContainerClasses="dark:bg-dark-secondary"
                  inputClasses="border rounded border-gray-200 pl-3 focus:outline-none focus:border-basic dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
                  labelClasses="dark:text-dark-primary"
                  checkIconClasses="text-basic"
                  optionClasses="text-primary dark:text-dark-primary"
                  optionHoverClasses="bg-primary dark:bg-dark-primary"
                />
                <InputField
                  label="Room Number"
                  register={register}
                  type="text"
                  errors={errors}
                  placeHolder="Room Number"
                  fieldName={`room_no`}
                  inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-5">
          <button
            type="submit"
            className="w-full btn bg-basic text-white rounded-md p-2"
          >
            Add Room
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddStayRoom;
