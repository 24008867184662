import { gql } from "@apollo/client";

export const GET_BRD_WHATSAPP_CONVERSATION_WITH_USER_ID = gql`
  query GetUsersWithUserId($user_id: uuid!) {
    user(id: $user_id) {
      brd_whatsapp_conversation {
        id
      }
    }
  }
`;
