import React, { useEffect, useState } from "react";
import TicketRequestForm from "./components/TicketRequestForm";
import TicketRequestCard from "./components/TicketRequestCard";
import CloseInquiry from "./components/CloseInquiry";
import CompleteInquiry from "./components/CompleteInquiry";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import SideDrawer from "~/components/common/sideDrawer";
import RefundInquiry from "./components/RefundInquiry";
import TicketsAndVouchers from "./SelectedTicketRequest";
import { GET_TICKET } from "../../graphql/TicketQuery";
import { useSubscription } from "@apollo/client";
import SeatSelection from "./components/SeatSelection";
import ExtraBaggage from "./components/ExtraBaggage";
import SeatsCard from "./components/SeatsCard";
import BaggageCard from "./components/BaggageCard";
import SelectTicketForm from "./components/SelectTicketForm";
import add from "../../assets/img/Add.svg";

export default function TicketRequest({
  inquiry,
  transactions,
  tabs,
  inquiry_id,
}: any) {
  const [hideForm, setHideForm] = useState(false);
  const [showAdd, setShowAdd] = useState<any>(false);
  const role = useUserDefaultRole();
  const user_id = useUserId();

  const [sidePopup, setSidePopup] = useState<any>(false);
  const [side_popup, setSide_popup] = useState<any>(false);
  const { loading, data, error } = useSubscription(GET_TICKET, {
    variables: { inquiry_no: inquiry_id },
  });

  const ticekts = data?.inq_list || [];

  useEffect(() => {
    setShowAdd(false);

    if (inquiry && ticekts?.[0]) {
      if (
        ticekts?.[0]?.train_inq_details?.[0] &&
        !ticekts?.[0]?.train_inq_details?.[0]?.train_inq_tickets?.[0]
      ) {
        setShowAdd(true);
      }

      if (
        ticekts?.[0]?.car_inq_details?.[0] &&
        !ticekts?.[0]?.car_inq_details?.[0]?.car_inq_tickets?.[0]
      ) {
        setShowAdd(true);
      }

      if (inquiry?.from && inquiry?.to && !ticekts?.[0]?.inq_tickets?.[0]) {
        setShowAdd(true);
      }

      if (
        ticekts?.[0]?.stay_inq_details?.[0] &&
        !ticekts?.[0]?.stay_inq_details?.[0]?.stay_inq_vouchers?.[0]
      ) {
        setShowAdd(true);
      }

      if (
        ticekts?.[0]?.visa_inq_details?.[0] &&
        !ticekts?.[0]?.visa_inq_details?.[0]?.visa_inq_tickets?.[0]
      ) {
        setShowAdd(true);
      }
    }
  }, [ticekts, inquiry]);

  return (
    <>
      <div className="self-stretch flex flex-col items-start w-full justify-start gap-[30px] mb-5">
        <div className="self-stretch flex items-center dark:text-primary mt-[30px] ">
          <hr className="w-[2%] dark:border-[#E7E3FC61]" />
          <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
            TICKETS & VOUCHERS
          </span>
          <hr className="w-full dark:border-[#E7E3FC61]" />
          <div className="flex w-[350px] gap-2 pl-2 min-w-fit ">
            <div className="flex items-center justify-end gap-3 w-full ">
              {/* {!(ticekts?.[0]?.car_inq_details?.[0]?.car_inq_tickets?.[0] && ticekts?.[0]?.inq_tickets?.[0] && ticekts?.[0]?.stay_inq_details?.[0]?.stay_inq_tickets?.[0] && ticekts?.[0]?.train_inq_details?.[0]?.train_inq_tickets?.[0] && ticekts?.[0]?.visa_inq_details?.[0]?.visa_inq_tickets?.[0]) && ( */}

              {/* )} */}

              {showAdd && (
                <img
                  src={add}
                  className="text-basic h-8 w-8 cursor-pointer"
                  onClick={() => setSide_popup(true)}
                />
              )}
              {inquiry.status !== "refunded" && (
                <button
                  onClick={() => setSidePopup(true)}
                  className="block border border-basic rounded-full px-3 py-1 font-medium bg-basic text-white cursor-pointer hover:bg-white hover:dark:bg-dark-primary hover:text-basic"
                >
                  Refund and Close
                </button>
              )}
              {(user_id === inquiry?.picked_by || role === "admin") &&
                inquiry.status !== "completed" &&
                inquiry.status !== "refunded" && (
                  <CompleteInquiry
                    inquiry={inquiry}
                    transactions={transactions}
                    tickets={ticekts}
                  />
                )}
            </div>
          </div>
        </div>
        <div className="w-full">
          <TicketsAndVouchers inquiry={inquiry} ticekts={ticekts} />
        </div>
        {tabs?.[0]?.label === "Flights" && (
          <div className="w-full">
            <div className="self-stretch flex items-center dark:text-primary mb-[30px] ">
              <hr className="w-[2%] dark:border-[#E7E3FC61]" />
              <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
                ADD ONS
              </span>
              <hr className="w-full dark:border-[#E7E3FC61]" />
            </div>
            <div className="grid grid-cols-2 gap-5">
              {inquiry?.flightInq_seatSelections?.length > 0 ? (
                <SeatsCard inquiry={inquiry} />
              ) : (
                <SeatSelection inquiry={inquiry} />
              )}
              {inquiry?.flightInq_extraBaggages?.length > 0 ? (
                <BaggageCard inquiry={inquiry} />
              ) : (
                <ExtraBaggage inquiry={inquiry} />
              )}
            </div>
          </div>
        )}
      </div>
      <SideDrawer
        setSidePopup={setSidePopup}
        sidePopup={sidePopup}
        component={
          <RefundInquiry inquiry={inquiry} setSidePopup={setSidePopup} />
        }
      />
      <SideDrawer
        setSidePopup={setSide_popup}
        sidePopup={side_popup}
        component={
          <SelectTicketForm
            inquiry={inquiry}
            ticekts={ticekts}
            setSidePopup={setSide_popup}
          />
        }
      />
    </>
  );
}
