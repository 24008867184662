import { useQuery } from "@apollo/client";
import React from "react";
import { GET_BRD_WHATSAPP_CONVERSATION_WITH_USER_ID } from "~/graphql/brd_whatsapp_conversations/Query";
import ChatWindow from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow";

const WhatsAppInqComp = (customerId: any) => {
  const {
    data: whatsappData,
    loading: whatsappLoading,
    error: whatsappError,
  } = useQuery(GET_BRD_WHATSAPP_CONVERSATION_WITH_USER_ID, {
    variables: { user_id: customerId?.customerId },
  });

  const conversation_id = whatsappData?.user?.brd_whatsapp_conversation?.id;

  return (
    <div
      className=" bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary shadow rounded-lg
      w-full mt-[30px]"
    >
      <h2 className="text-gray-700 dark:text-dark-primary uppercase text-[16px] md:text-[18px] flex  font-bold border-b border-[#E7E3FC61] mt-3 justify-between pb-[20px] pl-[25px] py-[20px]">
        WhatsApp Chat
      </h2>
      {conversation_id ? (
        <ChatWindow conversation_id={conversation_id} setShowUserInfo={true} />
      ) : (
        <p>No conversation found.</p>
      )}
    </div>
  );
};

export default WhatsAppInqComp;
