import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  TRAIN_ADD_SUGGESTION_COST,
  EDIT_TRAIN_SUGGESTION_COST,
  EDIT_TRAIN_SUGGESTION,
} from "../../EditSuggestion/components/EditSuggestionForm/graphql/Mutation";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import NewPassengerEditCost from "../components/EditSuggestionForm/components/NewPassengerEditCost";
import { useUserDisplayName, useUserId } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import useNotifications from "~/hooks/useNotifications";
import { toast } from "react-toastify";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TrainNameSelector } from "../../../../AddSuggestion/components/SuggestionForm/Train/components/TrainType";
import TrainPassengerCost from "../../../../AddSuggestion/components/SuggestionForm/Train/components/TrainPassengerCost";
import utc from "dayjs/plugin/utc";
import moment from "moment";

dayjs.extend(utc);

const EditTrainSuggestionBox = ({
  defaultValue,
  inquiry_id,
  inquiry,
  suggestion,
  user_id,
  passengers,
  suggestion_cost,
  setShowAddModal,
  tab,
}: any) => {
  const [editTrainSuggestion] = useMutation(EDIT_TRAIN_SUGGESTION);
  const [editTrainSuggestionCost] = useMutation(EDIT_TRAIN_SUGGESTION_COST);
  const [addSuggestionCost] = useMutation(TRAIN_ADD_SUGGESTION_COST);
  const userName = useUserDisplayName();
  const emailSend = useEmail();

  const userId = useUserId();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const client = useApolloClient();
  //button loading state
  const [isLoading, setIsloading] = useState<any>(false);
  // Define react hooks form
  const alert = useNotifications();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
    unregister,
  } = useForm({
    defaultValues: defaultValue, // Existing default value handling
  });

  // Initialize default values for train details
  React.useEffect(() => {
    // Handle room_type (preserved logic)
    if (defaultValue[0]?.train_name) {
      setValue("train_name", defaultValue[0]?.train_name);
    }

    // Initialize train.dates and train.time from default values if they exist
    if (defaultValue[0]?.train?.dates) {
      setValue("train.dates", [
        dayjs(defaultValue?.train?.dates[0]),
        dayjs(defaultValue?.train?.dates[1]),
      ]);
    }

    if (defaultValue[0]?.train?.time) {
      setValue("time", dayjs.utc(defaultValue[0]?.train?.time));
    }
  }, [defaultValue, setValue]);

  // Submit form function
  const onSubmit = async (data: any) => {
    setIsloading(true);
    let isSuccess = false;

    try {
      const startDate = data?.train?.dates
        ? moment(data?.train?.dates[0]?.$d).format("YYYY-MM-DD")
        : data?.start_date;
      const endDate = data?.train?.dates
        ? moment(data?.train?.dates[1]?.$d).format("YYYY-MM-DD")
        : data?.end_date;

      const updatedTrainSuggestionData = {
        origin: data?.origin,
        destination: data?.destination,
        start_date: startDate,
        end_date: endDate,
        time: data?.time,
        cabin_class: data?.cabin_class,
        train_name: data?.train_name,
      };

      const trainPassengerArray = data?.suggestion_cost.map((cost: any) => {
        const passenger = data?.train?.passengers?.find(
          (p: any) => p.id === cost.inq_passenger_id
        );

        return {
          cost_id: cost.id,
          inq_passenger_id: passenger?.id,
          basicFare: passenger?.basicFare,
          taxPrice: passenger?.taxPrice,
          salePrice: passenger?.salePrice,
        };
      });

      const resSuggestion = await editTrainSuggestion({
        variables: { id: data?.id, data: updatedTrainSuggestionData },
      });

      if (
        resSuggestion?.data?.update_train_inq_suggestion?.returning?.[0]?.id
      ) {
        isSuccess = true;
      }

      for (const passenger of trainPassengerArray) {
        const res = await editTrainSuggestionCost({
          variables: {
            id: passenger.cost_id,
            basicFare: passenger.basicFare,
            taxPrice: passenger.taxPrice,
            salePrice: passenger.salePrice,
            inq_passenger_id: passenger.inq_passenger_id,
          },
        });

        if (!res?.data?.update_train_inq_suggestion_cost?.returning?.[0]?.id) {
          isSuccess = false;
          break;
        }
      }

      if (isSuccess) {
        toast.success(`Suggestions Updated Successfully!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setShowAddModal(false);
      }
    } catch (err) {
      toast.error(`Failed to edit suggestion ${err}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setIsloading(false);
    }

    // setIsloading(true);
    // if (!isLoading) {
    //   formData["id"] = suggestion.id;
    //   try {
    //     const startDate =
    //       formData?.train?.dates && formData.train.dates[0]
    //         ? formData.train.dates[0]
    //         : defaultValue.start_date;

    //     const endDate =
    //       formData?.train?.dates && formData.train.dates[1]
    //         ? formData.train.dates[1]
    //         : defaultValue.end_date;
    //     const inq_suggestion: any = {
    //       origin: formData.origin,
    //       destination: formData.destination,
    //       cabin_class: formData.cabin_class,
    //       train_name: formData.train_name,
    //       start_date: startDate,
    //       end_date: endDate,
    //       time: formData?.time,
    //     };
    //     await updateInqListUser({
    //       variables: { id: inquiry.id, updated_by: userId },
    //     });

    //     // Add Suggestion Cost
    //     if (
    //       !formData.suggestion_cost ||
    //       formData.suggestion_cost.length === 0
    //     ) {
    //       // No existing cost: add new suggestion cost

    //       let suggestion_cost_add: any = [];

    //       // Prepare new cost data
    //       formData.suggestion_cost_add.map((sca: any) => {
    //         sca["train_suggestion_id"] = formData.id; // Add the suggestion ID
    //         suggestion_cost_add.push(sca);
    //       });

    //       // Add new suggestion cost
    //       const resSuggestionCostAdd = await addSuggestionCost({
    //         variables: { suggestion_cost: suggestion_cost_add },
    //       });
    //     } else {
    //       // Existing cost: update the suggestion cost
    //       console.log(
    //         "Existing suggestion cost found. Updating suggestion cost."
    //       );
    //       formData.suggestion_cost.map(async (cost: any) => {
    //         const suggestionCost = { ...cost };
    //         const id = suggestionCost["id"];

    //         // Update existing cost
    //         const resSuggestionCost = await editSuggestionCost({
    //           variables: { id: id, suggestion_cost: suggestionCost },
    //         });
    //       });
    //     }

    //     await client.resetStore();

    //     const variables: any = {
    //       inquiryNo: inquiry?.inquiry_no,
    //       brandName: inquiry?.brd_list?.name,
    //       userFirstName: inquiry?.users?.displayName,
    //       consultantName: userName,
    //     };

    //     await emailSend(
    //       4,
    //       inquiry.brd_id,
    //       inquiry.users?.id,
    //       inquiry.users?.email,
    //       inquiry.users?.phoneNumber,
    //       variables
    //     );

    // toast.success(`Suggestions edit successfully`, {
    //   position: toast.POSITION.BOTTOM_RIGHT,
    // });

    //     await alert.newNotification(
    //       user_id,
    //       `Your consultant updated a suggestion Please review in the portal `,
    //       "",
    //       ``
    //     );

    //     setIsloading(false);
    //     setShowAddModal(false);
    //   } catch (error) {
    // toast.error(`Failed to edit suggestions`, {
    //   position: toast.POSITION.BOTTOM_RIGHT,
    // });
    //   } finally {
    //     setIsloading(false);
    //   }
    // }
  };

  const handleDateRangeChange = (newValue: [Dayjs | null, Dayjs | null]) => {
    const [start, end] = newValue;
    // Update the form state only if both dates are provided
    if (start && end) {
      setValue("train.dates", [start, end]); // Update using ISO string format
    } else {
      setValue("train.dates", []); // Set to an empty array if either date is null
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!inquiry?.invoice_id && (
        <div className="bg-gray-100 rounded-lg p-4 dark:bg-[#28243D]">
          <div className="grid grid-cols-2 gap-4">
            {/* Origin Field */}
            <Controller
              control={control}
              name="origin"
              rules={{ required: "Origin is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Origin"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            {/* Destination Field */}
            <Controller
              control={control}
              name="destination"
              rules={{ required: "Destination is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Destination"
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            {/* Cabin Class Selector */}
            <FormControl fullWidth>
              <Controller
                control={control}
                name="cabin_class"
                rules={{ required: "Cabin class is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    onChange={(_, data: any) => field.onChange(data)}
                    options={["Economy", "Business"]}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        label="Cabin Class"
                        {...params}
                        error={!!errors.cabin_class}
                      />
                    )}
                  />
                )}
              />
            </FormControl>

            {/* Train Name Selector */}
            <FormControl className="mx-3" fullWidth>
              <Controller
                control={control}
                name="train_name"
                rules={{ required: true }}
                render={({ field }) => (
                  <TrainNameSelector
                    {...field}
                    label="Train Type"
                    errors={errors}
                    register={register}
                    control={control}
                    setValue={setValue} // Use the new function
                    unregister={unregister}
                    getValues={getValues}
                  />
                )}
              />
            </FormControl>

            {/* Date Range Picker */}
            <Controller
              control={control}
              name="train.dates"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                const startDate = dayjs(defaultValue?.start_date);
                const endDate = dayjs(defaultValue?.end_date);

                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["SingleInputDateRangeField"]}
                      sx={{ margin: 0, padding: 0 }}
                    >
                      <DateRangePicker
                        value={[startDate, endDate]} // Controlled value
                        onChange={(newValue) => {
                          onChange(newValue); // Update the form value
                          handleDateRangeChange(newValue); // Call the custom handler
                        }}
                      />
                      {error && <span className="error">{error.message}</span>}{" "}
                    </DemoContainer>
                  </LocalizationProvider>
                );
              }}
            />
            {/* Time Picker */}
            <FormControl>
              <Controller
                control={control}
                name="time"
                rules={{ required: "Time is required" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  // Ensure the value is a Dayjs object
                  const parsedValue = value ? dayjs.utc(value) : null;

                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Select Train Time"
                        value={parsedValue} // Pass the Dayjs object
                        onChange={(newValue) =>
                          onChange(newValue?.toISOString() || null)
                        } // Convert back to ISO string
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error ? error.message : null,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
            </FormControl>
          </div>
        </div>
      )}
      <hr className="border-gray-300 border-dashed my-4" />
      <TrainPassengerCost
        errors={errors}
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        costs={defaultValue?.suggestion_cost}
        passengers={passengers}
      />
      <button
        className={` ${
          isLoading ? "bg-basic cursor-wait" : "bg-basic"
        } h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
        type="submit"
      >
        {isLoading ? "Updating.." : "Update Suggestion"}
      </button>
    </form>
  );
};

export default EditTrainSuggestionBox;
