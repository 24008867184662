import EditStay from "./components/EditStay";
import { splitLocation } from "~/utils/splitLocation";
import HotelIcon from "@mui/icons-material/Hotel";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { useState } from "react";
import SideDrawer from "~/components/common/sideDrawer";
import moment from "moment";
import PassengerRecord from "../InquiryCard/components/PassengerRecord";

const StayCard = ({
  stayId,
  location,
  startDate,
  endDate,
  rooms,
  adults,
  children,
  editAble,
  height,
  detail,
}: any) => {
  const { cityName: locationName, remainingAddress: locationAddress } =
    splitLocation(location);

  const locationWords = locationName.split(" ");
  const [editStay, setEditStay] = useState(false);

  const createPassengerArray = (adults: any, children: any) => {
    const adultsArray = Array(adults).fill({ type: "adult" });
    const childrenArray = Array(children).fill({ type: "child" });

    const passengers = [...adultsArray, ...childrenArray];
    return passengers;
  };

  function calculateNightsStay(startDate: any, endDate: any) {
    if (!startDate || !endDate) return 0;
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, 'days');
  }

  const passengers = createPassengerArray(adults, children);
  const nightsStay = calculateNightsStay(startDate, endDate);

  return (
    <div className="bg-secondary dark:bg-dark-secondary text-primary rounded-2xl dark:text-dark-primary self-stretch flex flex-col h-full">
      <div className="self-stretch flex flex-col my-5 px-[30px] mt-5 flex-grow">
        <div className="h-full flex flex-col items-start justify-center gap-5">
          <div className="bg-basic h-20 w-20 rounded-full flex items-center justify-center">
            <HotelIcon
              className="text-center text-white"
              fontSize="large"
            />
          </div>
          <div className="flex flex-row justify-between items-center w-full gap-3">
            <div className=" flex gap-3 flex-col">
              <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base">
                LOCATION
              </div>
              <div
                className="relative text-3xl font-medium text-basic dark:text-dark-primary"
                title={location}
              >
                {location.length > 20 ? `${location.slice(0, 20)}...` : location}
              </div>
            </div>

          </div>
          <div className="flex items-center justify-start gap-[9px] absolute top-[5%] right-[2%]">
            {editAble && (
              <BiSolidMessageSquareEdit
                onClick={() => {
                  setEditStay(true);
                }}
                className="cursor-pointer text-basic dark:text-dark-primary relative w-5 h-5"
              />
            )}
          </div>
        </div>
      </div>
      <div className="self-stretch flex md:flex-row px-[30px] py-7 items-center justify-between bg-basic rounded-b-2xl h-[150px]">
        <div className="w-full flex items-stretch justify-between gap-4 text-white font-medium">
          <div className="flex flex-col gap-1">
            <div className="relative text-base">
              Check In - {moment(startDate).format('DD-MM-YYYY')}
            </div>
            <div className="relative text-base">
              Rooms - {rooms}
            </div>
            <div className="relative text-base">
              Nights Stay - {nightsStay}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="relative text-base">
              Check Out - {moment(endDate).format('DD-MM-YYYY')}
            </div>
            <div className="w-full flex justify-end">
              <PassengerRecord inq_passengers={passengers} />
            </div>
          </div>
        </div>
      </div>
      {editStay && (
        <SideDrawer
          component={
            <EditStay
              setSidePopup={setEditStay}
              stayId={stayId}
              location={location}
              startDate={startDate}
              endDate={endDate}
              rooms={rooms}
              adults={adults}
              children={children}
            />
          }
          sidePopup={editStay}
          setSidePopup={setEditStay}
        />
      )}
    </div>
  );
};

export default StayCard;
