import { gql } from "@apollo/client";

export const GET_USER_GMAIL_CONVERSATION = gql`
  query GetUsers($user_email: String, $brd_id: uuid!) {
    brd_gmail_conversation(
      where: { user_email: { _eq: $user_email }, brd_id: { _eq: $brd_id } }
    ) {
      user_email
      user_name
      last_email_subject
      last_email_time
      last_view_time
      brd_list {
        name
        gmail_refresh_token
      }
    }
  }
`;

export const GET_BRD_GMAIL_CONVERSATION_WITH_USER_ID = gql`
  query GetUsersWithUserId($user_id: uuid!) {
    user(id: $user_id) {
      brd_gmail_conversation {
        id
      }
    }
  }
`;
