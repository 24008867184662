// src/SuggestionBox/components/SuggestionRenderer.tsx
import React from "react";
import EditStaySuggestionBox from "../../../SuggestionBox/components/EditSuggestion/StayEditSuggestion";
import EditSuggestionForm from "../../../SuggestionBox/components/EditSuggestion/components/EditSuggestionForm";
import { formatFormData } from "../../../AddSuggestion/utils/formatFormData";
import { StayFormatFormData } from "../../../AddSuggestion/utils/StayFormatFormData";
import EditCarSuggestionBox from "../../../SuggestionBox/components/EditSuggestion/CarEditSuggestion";
import { CarFormatFormData } from "../../../AddSuggestion/utils/CarFormatFormData";
import EditTrainSuggestionBox from "../../../SuggestionBox/components/EditSuggestion/TrainEditSuggestion";
import { TrainFormatFromData } from "../../../AddSuggestion/utils/TrainFormatFormData";
import EditVisaSuggestionForm from "../../../SuggestionBox/components/EditSuggestion/VisaEditSuggestionForm";
import { VisaFormatFormData } from "../../../AddSuggestion/utils/VisaFormatFormData";

// Props interface for SuggestionRenderer
interface SuggestionRendererProps {
  setShowAddModal: (value: boolean) => void; // Function to show/hide the modal
  inquiry_id: string; // Inquiry ID
  inquiry: any; // Inquiry object
  suggestions: any[]; // Array of suggestions
  user_id: string; // User ID
  arrival: string; // Arrival info
  passengers: any[]; // Passengers info
  tabLabel: string; // Current tab label
  stayPassengers: any[];
  trainPassengers: any[];
  carPassengers: any[];
}

const SuggestionRenderer: React.FC<SuggestionRendererProps> = ({
  setShowAddModal,
  inquiry_id,
  inquiry,
  suggestions,
  user_id,
  arrival,
  passengers,
  tabLabel,
  stayPassengers,
  trainPassengers,
  carPassengers,
}) => {
  const renderSuggestions = () => {
    if (!Array.isArray(suggestions)) {
      // If suggestions is a single object, wrap it in an array
      suggestions = [suggestions];
    }

    switch (tabLabel) {
      case "Hotel":
        return suggestions.map((suggestion: any, i: number) => {
          const defaultValue = StayFormatFormData(suggestion || []);
          return (
            <>
              <EditStaySuggestionBox
                key={i}
                inquiry={inquiry}
                suggestion={suggestion}
                passengers={stayPassengers}
                tab={tabLabel}
                suggestion_cost={suggestion?.stay_inq_suggestion_costs}
                defaultValue={defaultValue}
                setShowAddModal={setShowAddModal}
                inquiry_id={inquiry_id}
                user_id={user_id}
              />
            </>
          );
        });

      case "Cars":
        return suggestions.map((suggestion: any, i: number) => {
          const defaultValue = CarFormatFormData(suggestion || []);

          return (
            <>
              <EditCarSuggestionBox
                key={i}
                suggestion={suggestion}
                inquiry={inquiry}
                inquiry_id={inquiry_id}
                user_id={user_id}
                tab={tabLabel}
                setShowAddModal={setShowAddModal}
                passengers={carPassengers}
                suggestion_cost={suggestion?.car_inq_suggestion_costs}
                defaultValue={defaultValue}
              />
            </>
          );
        });

      case "Train":
        return suggestions.map((suggestion: any, i: number) => {
          const defaultValue = TrainFormatFromData(suggestion || []);
          return (
            <>
              <EditTrainSuggestionBox
                inquiry_id={inquiry_id}
                user_id={user_id}
                tab={tabLabel}
                setShowAddModal={setShowAddModal}
                key={i}
                inquiry={inquiry}
                suggestion={suggestion}
                passengers={trainPassengers}
                suggestion_cost={suggestion?.train_inq_suggestion_costs}
                defaultValue={defaultValue}
              />
            </>
          );
        });

      case "Visa":
        return suggestions.map((suggestion: any, i: number) => {
          const defaultValue = VisaFormatFormData(suggestion || []);
          return (
            <EditVisaSuggestionForm
              key={i}
              suggestion={suggestion}
              suggestion_cost={suggestion?.visa_inq_suggestion_costs}
              defaultValue={defaultValue}
              inquiry={inquiry}
              inquiry_id={inquiry_id}
              passengers={passengers}
              user_id={user_id}
              tab={tabLabel}
              setShowAddModal={setShowAddModal}
            />
          );
        });

      case "Flights":
      default:
        return suggestions.map((suggestion: any, i: number) => {
          const defaultValue = formatFormData(suggestion || []);
          return (
            <EditSuggestionForm
              key={i}
              suggestion={suggestion}
              suggestion_cost={suggestion?.inq_suggestion_costs}
              defaultValue={defaultValue}
              inquiry={inquiry}
              inquiry_id={inquiry_id}
              passengers={passengers}
              user_id={user_id}
              tab={tabLabel}
              setShowAddModal={setShowAddModal}
            />
          );
        });
    }
  };

  return <>{renderSuggestions()}</>;
};

export default SuggestionRenderer;
