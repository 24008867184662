import { useUserDefaultRole, useUserId } from "@nhost/react";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { DELETE_VISA_TICKET } from "~/graphql/visa_inq_ticket/Mutation";
import { useMutation } from "@apollo/client";

const VisaTicketCard = ({ inquiry, ticekts }: any) => {
  const userRole = useUserDefaultRole();
  const user_id = useUserId();

  const [deleteTicket] = useMutation(DELETE_VISA_TICKET);

  return (
    <div className="self-stretch rounded-lg shadow w-full flex flex-col">
      <div
        className={`self-stretch flex-1 bg-secondary dark:bg-dark-secondary w-full flex flex-col p-[25px] gap-[12px]  rounded-t-lg items-start justify-between text-left text-mini text-darkslategray`}
      >
        <div className="self-stretch container w-full">
          <p className="text-basic mb-2 text-center font-semibold">Visa Voucher</p>
          <form className="self-stretch flex flex-col w-full">
            {userRole !== "user" && (
              <div className=" self-stretch container w-full gap-[12px] justify-between  flex flex-col flex-1">
                <div className="self-stretch relative flex items-center justify-between w-full">
                  <div className="flex items-center gap-1 text-basic dark:text-dark-primary">
                    <b>
                      {
                        ticekts?.[0]?.visa_inq_details?.[0]
                          ?.visa_inq_tickets?.[0]?.vendor?.displayName
                      }
                    </b>
                  </div>
                  {(user_id === inquiry?.picked_by || userRole === "admin") &&
                    inquiry?.status !== "completed" && (
                      <div className="flex items-center justify-end gap-[8px]">
                        <MdDelete
                          onClick={async () => {
                            const res = await deleteTicket({
                              variables: {
                                id: ticekts?.[0]?.visa_inq_details?.[0]
                                  ?.visa_inq_tickets?.[0]?.id,
                              },
                            });

                            if (
                              res?.data?.delete_visa_inq_ticket?.affected_rows
                            ) {
                              toast.success("Ticket Deleted Successfully");
                            }
                          }}
                          className="text-basic dark:text-dark-primary cursor-pointer h-6 w-6"
                        />
                      </div>
                    )}
                </div>
                <div className="flex flex-col gap-5">
                  <div className="relative flex items-center  text-black text-[40px]">
                    <b>
                      {ticekts?.[0]?.visa_inq_details?.[0]?.visa_inq_tickets?.[0]?.status.toUpperCase()}
                    </b>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      <div className="flex justify-end gap-2 w-full bg-basic px-[25px] py-[20px] rounded-b-lg">
        <div className="relative flex gap-1 text-basic rounded-2xl font-medium bg-white px-2">
          <p className="capitalize">
            Cost Price: £
            {
              ticekts?.[0]?.visa_inq_details?.[0]?.visa_inq_tickets?.[0]
                ?.cost_price
            }
          </p>
        </div>
      </div>
    </div>
  );
};

export default VisaTicketCard;
