import * as React from "react";
import Header from "./components/Header";
import Content from "./components/Content";

export default function Customer() {
  return (
    <div>
      <Header />

      <Content />
    </div>
  );
}
