import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { FaTimes } from "react-icons/fa";
import { useApolloClient, useMutation } from "@apollo/client";
import { DELETE_PASSENGER } from "../graphql/Mutation";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import SideDrawer from "~/components/common/sideDrawer";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import NewPassengerEdit from "../components/NewPassengerEdit";


const NewPassengerBox = ({
  passenger,
  inquiry,
  hideActions,
}: any) => {

  const role = useUserDefaultRole();
  const [hideForm, setHideForm] = useState(false);
  const [deletePassanger] = useMutation(DELETE_PASSENGER);
  const [wait, setWait] = useState(false);
  const client = useApolloClient();
  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  const userDefaultRole = useUserDefaultRole();
  const userId = useUserId();

  const removePassenger = async (id: string) => {
    setWait(true);
    try {
      const res = await deletePassanger({ variables: { id } });
      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: userId },
      });

      if (res?.data?.delete_inq_passengers_by_pk?.id) {
        toast.success("Passenger Deleted Successfully!");
      }
    } catch (error) {
      toast.error(`Error Deleting Passenger ${error}`);
    } finally {
      setWait(false);
    }
  };
  return (
    <>
      <div className="pt-[25px] rounded-xl shadow-md relative bg-white dark:bg-dark-secondary">
        {/* {!selected_suggestion_id && totalPassengers > 1 && ( */}
        <button
          disabled={wait}
          onClick={() => removePassenger(passenger?.id)}
          className="bg-basic dark:bg-[#CFCCE6] h-[20px] w-[20px] rounded-full text-white flex justify-center items-center absolute top-[-5px] right-[-5px]"
        >
          {wait ? "..." : <FaTimes className="dark:text-[#312D4B]" />}
        </button>
        {/* )} */}
        <div className="self-stretch min-h-[300px] px-[25px] bg-white dark:bg-dark-secondary flex flex-col text-left text-mini text-darkslategray">
          <div className="self-stretch flex flex-row items-center justify-between text-sm mb-7">
            <div className="flex flex-row items-start justify-start text-">
              <div className="flex flex-row items-center justify-start">
                <b className="relative capitalize text-basic dark:text-dark-primary">
                  Type -{" "}
                  {passenger?.type ? passenger.type : "N/A"}
                </b>
              </div>
            </div>
            {!inquiry?.selected_suggestion &&
              !hideActions &&
              (userId === inquiry?.picked_by ||
                userDefaultRole === "admin") &&
              inquiry?.status !== "completed" && (
                <BiSolidMessageSquareEdit
                  className="cursor-pointer text-basic dark:text-dark-primary h-5 w-5"
                  onClick={() => setHideForm(true)}
                />
              )}
          </div>
          <div className="flex flex-col gap-7">
            <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-start border-b-[1px] border-basic dark:border-dark-primary">
              <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
                <div className="self-stretch relative text-basic dark:text-dark-primary flex flex-col gap-1 leading-[130%]">
                  <p className="m-0 font-medium">Name</p>
                  <p className="m-0 text-basic dark:text-dark-primary text-lg">
                    <p className="font-medium">
                      {passenger?.first_name || (
                        <span className="text-[11px] px-2 inline-block italic">
                          N/A
                        </span>
                      )}{" "}
                      {passenger?.last_name}
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row pt-0 justify-between px-0 border-b-[1px] border-basic dark:border-dark-primary">
              <div className="flex-1 flex flex-row items-center justify-start">
                <div className="relative text-basic dark:text-dark-primary flex flex-col gap-1">
                  <p className="m-0 font-medium">Date Of Birth</p>
                  <p className="m-0 text-basic dark:text-dark-primary text-lg">
                    <p className="font-medium">
                      {passenger?.dob ? (
                        moment(passenger?.dob).format("DD-MM-YYYY")
                      ) : (
                        <span className="text-[11px] px-2 inline-block italic">
                          N/A
                        </span>
                      )}
                    </p>
                  </p>
                </div>
              </div>
              <div className="flex-1 rounded-8xs flex flex-row items-end justify-end text-right">
                <div className="relative text-basic dark:text-dark-primary leading-[130%]">
                  <p className="m-0 font-medium">Nationality</p>
                  <p className="m-0 text-basic dark:text-dark-primary text-lg pt-1">
                    <p className="font-medium">
                      {passenger?.nationality || (
                        <span className="text-[11px] px-2 inline-block italic">
                          N/A
                        </span>
                      )}
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-between  border-b-[1px] border-basic dark:border-dark-primary">
              <div className="rounded-8xs flex flex-col items-start justify-start">
                <div className="self-stretch relative text-basic dark:text-dark-primary flex flex-col gap-1">
                  <p className="m-0 font-medium">Passport No</p>
                  <p className="m-0 text-basic dark:text-dark-primary text-lg">
                    <p className="font-medium">
                      {passenger?.passport_no || (
                        <span className="text-[11px] px-2 inline-block italic">
                          N/A
                        </span>
                      )}
                    </p>
                  </p>
                </div>
              </div>
              <div className="rounded-8xs flex flex-col items-end justify-start text-right">
                <div className="self-stretch relative text-basic dark:text-dark-primary flex flex-col gap-1">
                  <p className="m-0 font-medium">Passport Expiry</p>
                  <p className="m-0 text-basic dark:text-dark-primary text-lg">
                    <p className="font-medium">
                      {passenger?.passport_expiry ? (
                        moment(passenger?.passport_expiry).format(
                          "DD-MM-YYYY"
                        )
                      ) : (
                        <span className="text-[11px] px-2 inline-block italic">
                          N/A
                        </span>
                      )}
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between bg-basic rounded-b-lg text-white px-[25px] py-[15px]">
          {
            role !== "user" && (
              <>
                <div className="flex flex-row items-start">
                  <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                    <div className="self-stretch relative flex">
                      <p className="m-0">Cost:</p>
                      <p className="m-0 font-medium">
                        {passenger?.inq_suggestion_costs?.[0]?.basic_fare || (
                          <span className="text-[11px] px-2 inline-block italic">
                            N/A
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                    <div className="self-stretch relative flex">
                      <p className="m-0">Tax:</p>
                      <p className="m-0 font-medium">
                        {passenger?.inq_suggestion_costs?.[0]?.tax_price || (
                          <span className="text-[11px] px-2 inline-block italic">
                            N/A
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                    <div className="self-stretch relative flex">
                      <p className="m-0">Atol:</p>
                      <p className="m-0 font-medium">
                        {passenger?.inq_suggestion_costs?.[0]?.atol_price || (
                          <span className="text-[11px] px-2 inline-block italic">
                            N/A
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                  <div className="self-stretch relative flex">
                    <p className="m-0">Sale:</p>
                    <p className="m-0 font-medium">
                      {passenger?.inq_suggestion_costs?.[0]?.sale_price || (
                        <span className="text-[11px] px-2 inline-block italic">
                          N/A
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </>
            )
          }
        </div>
      </div>
      {hideForm && (
        <SideDrawer
          setSidePopup={setHideForm}
          sidePopup={hideForm}
          component={
            <NewPassengerEdit
              inqId={inquiry?.id}
              setHideForm={setHideForm}
              passenger={passenger}
            />
          }
        />
      )}
    </>
  );
};

export default NewPassengerBox;
