import { gql } from "@apollo/client";

export const GET_INQ_FLAGS = gql`
  query MyQuery {
    thp_inquiry_flags {
      id
      name
      thp_id
      color
    }
  }
`;
