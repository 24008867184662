import React, { useEffect, useState } from "react";
import { useClickOutside } from "@mantine/hooks";
import { TextField } from "@mui/material";
import { getAccessToken } from "~/hooks/getAccessToken";

const HotelNames = ({
  value,
  onChange,
  setValue,
  fieldName,
  defaultValue,
  required,
  cityName, // This is the full city name
  label,
  errors,
}: any) => {
  const cityNameFull = cityName?.split(/[,\s]/)[0];

  const [hotelList, setHotelList] = useState([]);
  const [query, setQuery] = useState("");
  const ref = useClickOutside(() => setQuery(""));
  const [cityCode, setCityCode] = useState("");

  const getCityCode = async (cityNameFull: string) => {
    try {
      const token = await getAccessToken();
      if (!token) throw new Error("Missing access token");

      const encodedCityName = encodeURIComponent(String(cityNameFull).trim());

      const response = await fetch(
        `https://test.api.amadeus.com/v1/reference-data/locations/cities?keyword=${encodedCityName}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        const errorData = await response.json(); // Log any specific error
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.data && data.data.length > 0) {
        return data.data[0].iataCode; // Use 'iataCode' or 'cityCode' as needed
      } else {
        return null;
      }
    } catch (error) {
      return null; // Ensure function returns something even on failure
    }
  };

  useEffect(() => {
    const fetchCityCode = async () => {
      if (cityNameFull) {
        const code = await getCityCode(cityNameFull);
        setCityCode(code);
      }
    };

    fetchCityCode();
  }, [cityNameFull]);

  const searchHotel = async () => {
    if (!cityCode) return; // Ensure city code is available

    try {
      const token = await getAccessToken();
      const response = await fetch(
        `https://test.api.amadeus.com/v1/reference-data/locations/hotels/by-city?cityCode=${cityCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await response.json();

      if (!res.error) {
        const hotelOffers = res.data.map((hotel: any) => ({
          name: hotel.name,
        }));
        setHotelList(hotelOffers);
      }
    } catch (error) {
      setHotelList([]);
    }
  };

  useEffect(() => {
    searchHotel();
  }, [query, cityCode]);

  const handleCity = (hotel: any) => {
    const fullName = hotel.name; // Use the hotel name for the full name
    setValue(`${fieldName}.fullName`, fullName); // Store the hotel name in the form state
    onChange(fullName); // Update the input field value
    setQuery("");
    setHotelList([]);
  };

  const renderSearchOptions = () => {
    return (
      <div
        ref={ref}
        className="w-[92%] absolute mt-[55px] bg-primary text-primary dark:text-dark-primary dark:bg-dark-primary rounded z-10 shadow-lg transition-all"
      >
        {hotelList.length === 0 ? (
          <p className="text-center text-[14px] py-2">No hotels found</p>
        ) : (
          hotelList.map((item: any, i: number) => (
            <div
              onClick={() => handleCity(item)}
              key={i}
              className="p-[15px] border-b border-gray-200 dark:border-gray-700 hover:bg-secondary dark:hover:bg-dark-secondary cursor-pointer transition-all"
            >
              <p>{item.name}</p>
            </div>
          ))
        )}
      </div>
    );
  };

  return (
    <div className="w-full grid grid-cols-1 gap-5">
      <TextField
        sx={{ width: "100%" }}
        value={value || ""}
        onChange={(e) => {
          setQuery(e.target.value);
          onChange(e.target.value);
        }}
        label={label}
        type="text"
        required={required}
      />
      {query !== "" && renderSearchOptions()}
      {errors[fieldName]?.fullName && (
        <p className="text-red-500 text-[12px] mt-1 ml-1">
          <sup>*</sup> required
        </p>
      )}
    </div>
  );
};

export default HotelNames;
