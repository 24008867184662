import React, { useState } from "react";
import _ from "lodash";
import Cookies from "universal-cookie";
import PassengerBox from "./components/PassengerBox";
import PassengerAdd from "./components/PassengerAdd";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import SideDrawer from "~/components/common/sideDrawer";

const PassengersCard = ({ inquiry }: any) => {

  const userDefaultRole = useUserDefaultRole();
  const user_id = useUserId();
  const [sidePopup, setSidePopup] = useState(false);

  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
      <div className="self-stretch flex items-center dark:text-primary mt-[30px]">
        <hr className="w-[2%] dark:border-[#E7E3FC61]" />
        <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
          PASSENGERS
        </span>
        <hr className="w-full dark:border-[#E7E3FC61]" />
        {inquiry.status !== "completed" && (
          <div className="min-w-fit pl-2">
            {!inquiry?.selected_suggestion &&
              (user_id === inquiry?.picked_by ||
                userDefaultRole === "admin") && (
                <button
                  onClick={() => setSidePopup(true)}
                  className="block border border-basic rounded-full px-3 py-1 hover:text-basic cursor-pointer font-medium bg-basic dark:hover:bg-dark-primary hover:bg-white text-white"
                >
                  Add Passenger
                </button>
              )}
          </div>
        )}
      </div>
      <div className="self-stretch grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 items-start justify-start gap-[33px] text-mini">
        {_.sortBy(inquiry?.inq_passengers, "created_at")?.map(
          (passenger: any, i: any) => (
            <PassengerBox
              selected_suggestion_id={inquiry?.suggestion_id}
              passenger={passenger}
              inquiry={inquiry}
              inq_id={inquiry.id}
              totalPassengers={inquiry?.inq_passengers?.length}
            />
          )
        )}
        {sidePopup && (
          <SideDrawer
            setSidePopup={setSidePopup}
            sidePopup={sidePopup}
            component={
              <PassengerAdd
                setAdd={setSidePopup}
                inquiry_id={inquiry?.id}
                user_id={inquiry?.users.id}
                picked_by={inquiry?.picked_by}
                hideForm={setSidePopup}
                selected_suggestion_id={inquiry.suggestion_id}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default PassengersCard;
