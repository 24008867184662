import { Autocomplete, Box, Divider, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import _airportsData from "../../../../../../FormInputs/LocationBox/data/airports.json";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import _airlineData from "../../../../../../FormInputs/AirlineBox/data/airlines.json";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

const FlightDetail = ({
  register,
  errors,
  control,
  name,
  ind,
  entries,
  setEntries,
  mainHeading,
  defData,
  setValue,
  mainDefData,
  seasons,
  unregister,
}: any) => {
  setValue && setValue(`${name}.id`, defData && defData?.id);

  setValue &&
    setValue(
      `${name}.stops.${ind}.id`,
      defData?.stops && defData?.stops[ind]?.id
    );

  const [departDateTime, setDepartDateTime] = useState(
    defData?.stops?.length > 0 && defData?.stops[0]?.departDateTime
      ? dayjs(defData?.stops[ind]?.departDateTime)
      : null
  );

  const [arrivalDateTime, setArrivalDateTime] = useState(
    defData?.stops?.length > 0 && defData?.stops[0]?.arrivalDateTime
      ? dayjs(defData?.stops[ind]?.arrivalDateTime)
      : null
  );

  useEffect(() => {
    setValue &&
      setValue(
        `${name}.stops.${ind}.departDateTime`,
        defData?.stops?.length > 0 &&
          defData?.stops[0]?.departDateTime &&
          defData?.stops[ind]?.departDateTime
      );

    setValue &&
      setValue(
        `${name}.stops.${ind}.arrivalDateTime`,
        defData?.stops?.length > 0 &&
          defData?.stops[0]?.arrivalDateTime &&
          defData?.stops[ind]?.arrivalDateTime
      );
  }, []);
  // console.log("datahere:", mainDefData);
  return (
    <>
      {ind < 1 && (
        <>
          <Divider sx={{ marginTop: "25px" }} />
          <h2 className="text-center text-xl text-basic mt-3">
            {name === "departLeg"
              ? "DEPARTURE"
              : name === "returnLeg" && "RETURN"}
          </h2>
        </>
      )}

      <div className="grid md:grid-cols-2 grid-cols-1 gap-5 bg-secondary dark:bg-dark-secondary p-6 shadow rounded-lg relative mt-5">
        {ind > 0 && !(mainHeading === "Update Suggestion") && (
          <CancelIcon
            onClick={() => {
              unregister(`${name}.stops.${ind}`);
              const newArray = entries.slice(0, entries.length - 1);
              setEntries(newArray);
            }}
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              cursor: "pointer",
            }}
          />
        )}

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          {...register(`${name}.stops.${ind}.departCity`, {
            required: true,
          })}
          render={({ field }: any) => (
            <Autocomplete
              sx={{ marginTop: "10px" }}
              {...field}
              disablePortal
              ref={field.ref}
              onChange={(_, data: any) => {
                field.onChange(data);
              }}
              filterOptions={(options, { inputValue }: any) => {
                // If input is empty, don't show any suggestions
                if (!inputValue.trim()) {
                  return [];
                }

                // Filter options to only include those that start with the user's input
                const inputValueLowerCase = inputValue.toLowerCase();
                return options.filter(
                  (option) =>
                    option.city_name
                      .toLowerCase()
                      .startsWith(inputValueLowerCase) ||
                    option.iata_code
                      .toLowerCase()
                      .startsWith(inputValueLowerCase)
                );
              }}
              options={_airportsData ? _airportsData : []}
              getOptionLabel={(option: any) =>
                `${option.city_name || ""}-${option.iata_code}`
              }
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  {option.city_name}-{option.iata_code}
                </Box>
              )}
              defaultValue={() => {
                const data = _airportsData?.find(
                  (option: any) => option?.iata_code === mainDefData?.depart
                );
                field.onChange(data);
                return data;
              }}
              renderInput={(params) => (
                <TextField
                  label="Origin"
                  ref={field.ref}
                  inputRef={(el) => {
                    field.ref(el);
                  }}
                  error={
                    errors[`${name}.stops.${ind}.departCity`] ? true : false
                  }
                  {...params}
                />
              )}
              noOptionsText=""
            />
          )}
        />

        {seasons !== "available" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <Controller
                rules={{
                  required: true,
                }}
                {...register(`${name}.stops.${ind}.departDateTime`, {
                  required: true,
                })}
                control={control}
                render={({ field: { onChange, ref } }) => (
                  <DateTimePicker
                    value={departDateTime}
                    inputRef={ref}
                    onChange={(data: any) => {
                      onChange(data?.$d.toString());
                      setDepartDateTime(data);
                    }}
                    sx={{ width: "100%" }}
                    label="Origin Date & Time"
                    slotProps={{
                      textField: {
                        error: errors[`${name}.stops.${ind}.departDateTime`]
                          ? true
                          : false,
                      },
                    }}
                  />
                )}
              />
            </DemoContainer>
          </LocalizationProvider>
        )}

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          {...register(`${name}.stops.${ind}.arrivalCity`, {
            required: true,
          })}
          render={({ field }: any) => (
            <Autocomplete
              sx={{ marginTop: "10px" }}
              {...field}
              disablePortal
              ref={field.ref}
              onChange={(_, data: any) => {
                field.onChange(data);
              }}
              filterOptions={(options, { inputValue }: any) => {
                // If input is empty, don't show any suggestions
                if (!inputValue.trim()) {
                  return [];
                }

                // Filter options to only include those that start with the user's input
                const inputValueLowerCase = inputValue.toLowerCase();
                return options.filter(
                  (option) =>
                    option.city_name
                      .toLowerCase()
                      .startsWith(inputValueLowerCase) ||
                    option.iata_code
                      .toLowerCase()
                      .startsWith(inputValueLowerCase)
                );
              }}
              options={_airportsData ? _airportsData : []}
              getOptionLabel={(option: any) =>
                `${option.city_name || ""}-${option.iata_code}`
              }
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  {option.city_name}-{option.iata_code}
                </Box>
              )}
              defaultValue={() => {
                const data = _airportsData?.find(
                  (option) => option?.iata_code === mainDefData?.arrival
                );
                field.onChange(data);
                return data;
              }}
              renderInput={(params) => (
                <TextField
                  label="Destination"
                  ref={field.ref}
                  inputRef={(el) => {
                    field.ref(el);
                  }}
                  error={
                    errors[`${name}.stops.${ind}.arrivalCity`] ? true : false
                  }
                  {...params}
                  defaultValue={mainDefData?.arrival}
                />
              )}
              noOptionsText=""
            />
          )}
        />
        {seasons !== "available" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <Controller
                rules={{
                  required: true,
                }}
                {...register(`${name}.stops.${ind}.arrivalDateTime`, {
                  required: true,
                })}
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DateTimePicker
                    value={arrivalDateTime}
                    inputRef={ref}
                    onChange={(data: any) => {
                      onChange(data?.$d.toString());
                      setArrivalDateTime(data);
                    }}
                    sx={{ width: "100%" }}
                    label="Destination Date & Time"
                    slotProps={{
                      textField: {
                        error: errors[`${name}.stops.${ind}.arrivalDateTime`]
                          ? true
                          : false,
                      },
                    }}
                  />
                )}
              />
            </DemoContainer>
          </LocalizationProvider>
        )}

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          {...register(`${name}.stops.${ind}.airline`, {
            required: true,
          })}
          render={({ field }: any) => (
            <Autocomplete
              sx={{ marginTop: "10px" }}
              {...field}
              disablePortal
              ref={field.ref}
              onChange={(_, data: any) => {
                field.onChange(data);
              }}
              filterOptions={(options, { inputValue }: any) => {
                // If input is empty, don't show any suggestions
                if (!inputValue.trim()) {
                  return [];
                }

                // Filter options to only include those that start with the user's input
                const inputValueLowerCase = inputValue.toLowerCase();
                return options.filter((option) =>
                  option.marketing_name
                    .toLowerCase()
                    .startsWith(inputValueLowerCase)
                );
              }}
              options={_airlineData ? _airlineData : []}
              getOptionLabel={(option: any) => option.marketing_name}
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  {option.marketing_name}
                </Box>
              )}
              defaultValue={() => {
                const data = _airlineData?.find(
                  (option) => option?.iata === mainDefData?.airline_iata
                );
                field.onChange(data);
                return data;
              }}
              renderInput={(params) => (
                <TextField
                  label="Airline"
                  ref={field.ref}
                  inputRef={(el) => {
                    field.ref(el);
                  }}
                  error={errors[`${name}.stops.${ind}.airline`] ? true : false}
                  {...params}
                />
              )}
              noOptionsText=""
            />
          )}
        />
        <TextField
          sx={{ marginTop: "10px" }}
          error={errors[`${name}.stops.${ind}.airlineNumber`] ? true : false}
          {...register(`${name}.stops.${ind}.airlineNumber`, {
            required: true,
          })}
          label="airlineNumber"
          type="text"
          defaultValue={mainDefData?.flight_no}
        />
      </div>
    </>
  );
};

export default FlightDetail;
