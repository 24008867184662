import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GroupIcon from "@mui/icons-material/Group";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PersonIcon from "@mui/icons-material/Person";
import Inquires from "./components/Inquires";
import FlightsBookings from "./components/FlightsBookings";
import FriendsFamily from "./components/FriendsFamily";
import Invoices from "./components/Invoices";
import Payments from "./components/Payments";
import Ledger from "./components/Ledger";
import Documents from "./components/Documents";
import Profile from "./components/Profile";
import Activity from "./components/Activity";
import WhatsApp from "./components/WhatsApp";
import Email from "./components/Email";

export default function Content() {
  const [value, setValue] = React.useState("Activity");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ minWidth: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              sx={{ minWidth: "100%" }}
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab
                label="Activity"
                value="Activity"
                icon={<AccessTimeIcon />}
                iconPosition="start"
                style={{ height: "20px" }}
              />
              <Tab
                label="Inquires"
                value="Inquires"
                icon={<AssignmentTurnedInIcon />}
                iconPosition="start"
              />
              <Tab
                label="Flights Bookings"
                value="FlightsBookings"
                icon={<AirplaneTicketIcon />}
                iconPosition="start"
              />
              <Tab
                label="Friends & Family"
                value="FriendsFamily"
                icon={<GroupIcon />}
                iconPosition="start"
              />
              <Tab
                label="Invoices"
                value="Invoices"
                icon={<ReceiptIcon />}
                iconPosition="start"
              />
              <Tab
                label="Payments"
                value="Payments"
                icon={<MonetizationOnIcon />}
                iconPosition="start"
              />
              <Tab
                label="Ledger"
                value="Ledger"
                icon={<AccountBalanceWalletIcon />}
                iconPosition="start"
              />
              <Tab
                label="Documents"
                value="Documents"
                icon={<FileCopyIcon />}
                iconPosition="start"
              />
              <Tab
                label="Profile"
                value="Profile"
                icon={<PersonIcon />}
                iconPosition="start"
              />
              <Tab
                label="Email"
                value="Email"
                icon={<MailOutlineIcon />}
                iconPosition="start"
              />
              <Tab
                label="WhatsApp"
                value="WhatsApp"
                icon={<WhatsAppIcon />}
                iconPosition="start"
              />
            </TabList>
          </Box>
          <TabPanel value="Activity">
            <Activity />
          </TabPanel>
          <TabPanel value="Inquires">
            <Inquires />
          </TabPanel>
          <TabPanel value="FlightsBookings">
            <FlightsBookings />
          </TabPanel>
          <TabPanel value="FriendsFamily">
            <FriendsFamily />
          </TabPanel>
          <TabPanel value="Invoices">
            <Invoices />
          </TabPanel>
          <TabPanel value="Payments">
            <Payments />
          </TabPanel>
          <TabPanel value="Ledger">
            <Ledger />
          </TabPanel>
          <TabPanel value="Documents">
            <Documents />
          </TabPanel>
          <TabPanel value="Profile">
            <Profile />
          </TabPanel>
          <TabPanel value="Email">
            <Email />
          </TabPanel>
          <TabPanel value="WhatsApp">
            <WhatsApp />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
