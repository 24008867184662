import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import { GET_BRD_WHATSAPP_CONVERSATION_WITH_USER_ID } from "~/graphql/brd_whatsapp_conversations/Query";
import ChatWindow from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow";

const WhatsApp = () => {
  const { customerId } = useParams();
  const { data, loading, error } = useQuery(
    GET_BRD_WHATSAPP_CONVERSATION_WITH_USER_ID,
    {
      variables: { user_id: customerId },
    }
  );

  const conversation_id = data?.user?.brd_whatsapp_conversation?.id;

  return (
    <div>
      {conversation_id ? (
        <ChatWindow conversation_id={conversation_id} setShowUserInfo={true} />
      ) : (
        <p>No conversation found.</p>
      )}
    </div>
  );
};

export default WhatsApp;
