import { useState } from "react";
import { useForm } from "react-hook-form";

const SendEmailForm = ({
  to,
  resetSendLoading,
  handleSendEmail,
  setModalOpen,
  from,
  setReply,
  setCompose,
  subject,
  messageId,
  compose,
}: any) => {
  const [sendLoading, setSendLoading] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      to_email: to,
      email_subject:
        subject && !subject.startsWith("Re:") ? `Re: ${subject}` : subject,
      email_body: "",
      from_email: "",
    },
  });

  const onSubmitEmail = async (data: any) => {
    setSendLoading(true);
    await handleSendEmail({
      from: compose ? data?.from_email : from,
      to: data?.to_email,
      subject: data?.email_subject,
      body: data?.email_body,
      messageId: messageId,
    });
    const loadingFinish = resetSendLoading();
    if (loadingFinish) {
      setSendLoading(false);
      setModalOpen(false);
      setReply(false);
      setCompose(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitEmail)}>
      <div className="space-y-4">
        {compose && (
          <>
            <label>From</label>
            <select
              {...register("from_email", { required: true })}
              className="bg-gray-50 mb-4 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {from?.map((email: any) => (
                <option key={email.sendAsEmail} value={email.sendAsEmail}>
                  {email.sendAsEmail}
                </option>
              ))}
            </select>
          </>
        )}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900 ">
            To
          </label>
          <input
            {...register("to_email", { required: true })}
            type="text"
            id="to_email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Type email address"
          />
        </div>
        <div>
          <label
            htmlFor="email_subject"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Subject
          </label>
          {!compose ? (
            <input
              {...register("email_subject", { required: true })}
              type="text"
              id="email_subject"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Subject"
              readOnly
            />
          ) : (
            <input
              {...register("email_subject", { required: true })}
              type="text"
              id="email_subject"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Subject"
            />
          )}
        </div>
        <div>
          <label
            htmlFor="email_body"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Email Body
          </label>
          <textarea
            {...register("email_body", { required: true })}
            id="email_body"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Write your email"
          ></textarea>
        </div>
      </div>
      <div className="bottom-0 left-0 flex justify-center w-full pb-4 mt-4 space-x-4 sm:px-4 sm:mt-0">
        <button
          type="submit"
          className="w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {sendLoading ? "Sending..." : "Send"}
        </button>
      </div>
    </form>
  );
};

export default SendEmailForm;
