export const TrainFormatFromData = (trainSuggestions: any) => {
  const generateCost = (train_inq_suggestion_costs: any) => {
    const train_inq_suggestion_costs_return: any = [];
    train_inq_suggestion_costs.map((suggestion_cost: any) => {
      train_inq_suggestion_costs_return.push({
        id: suggestion_cost?.id,
        tax_price: suggestion_cost?.tax_price,
        basic_fare: suggestion_cost?.basic_fare,
        sale_price: suggestion_cost?.sale_price,
        inq_passenger_id: suggestion_cost?.inq_passenger?.[0]?.id,
      });
    });

    return train_inq_suggestion_costs_return;
  };
  const defaultValue: any = {
    id: trainSuggestions.id,
    origin: trainSuggestions.origin,
    destination: trainSuggestions.destination,
    cabin_class: trainSuggestions.cabin_class,
    train_name: trainSuggestions.train_name,
    start_date: trainSuggestions.start_date,
    end_date: trainSuggestions.end_date,
    time: trainSuggestions.time,
    suggestion_cost: generateCost(
      trainSuggestions.train_inq_suggestion_costs || []
    ), // Keep this as an array
  };

  return defaultValue;
};
