import FlightTicketRequestCard from "./components/flightRequestCard";
import StayTicketRequestCard from "./components/stayRequestCard";
import CarTicketRequestCard from "./components/carRequestCard";
import TrainTicketRequestCard from "./components/trainRequestCard";
import VisaTicketCard from "./components/VisaTicketCard";

const SelectedRequestForm = ({
  inquiry,
  ticekts,
}: any) => {

  return (
    <>
      {ticekts.map((ticket: any, index: number) => {
        return (
          <div key={index} className="grid grid-cols-2 gap-5">
            {ticket.car_inq_details?.[0]?.car_inq_tickets?.[0] && (
              <CarTicketRequestCard inquiry={inquiry} ticekts={ticekts} />
            )}
            {ticket.train_inq_details?.[0]?.train_inq_tickets?.[0] && (
              <TrainTicketRequestCard inquiry={inquiry} ticekts={ticekts} />
            )}
            {ticket.stay_inq_details?.[0]?.stay_inq_tickets?.[0] && (
              <StayTicketRequestCard inquiry={inquiry} ticekts={ticekts} />
            )}
            {ticket.visa_inq_details?.[0]?.visa_inq_tickets?.[0] && (
              <VisaTicketCard inquiry={inquiry} ticekts={ticekts} />
            )}
            {ticket.inq_tickets?.[0] && (
              <FlightTicketRequestCard inquiry={inquiry} ticekts={ticekts} />
            )}
          </div>
        )
      })}
    </>
  )
}

export default SelectedRequestForm;