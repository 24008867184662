import { useApolloClient, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { ADD_TRANSACTION, GET_TRANSACTIONS } from "../../graphql/Query";
import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import useAllBrandPaymentMethodName from "~/hooks/useAllBrandPaymentMethodName";
import Brandfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Brandfilter";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import { toast } from "react-toastify";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import brandsStore from "~/store/brandsStore/brandsStore";

const AdjustPayments = ({ user, heading, setSidePopup }: any) => {
  const client = useApolloClient();
  const [brdIds, setBrdIds] = useState<any>([]);
  const { brands }: any = brandsStore();
  const [selectedPayMeth, setSelectedPayMeth] = useState<any>();

  const [loading, setLoading] = useState<any>(false);

  const [date, setDate] = useState<any>(new Date());

  const [selectedBrand, setSelectedBrand] = useState<any>();

  const { paymentMethodName }: any = useAllBrandPaymentMethodName(brdIds);

  const [transactions, setTransactions] = useState<any>([]);

  const [refundedInquiry, setRefundedInquiry] = useState<any>([]);

  const [creditV, setCreditV] = useState<any>();
  const [debitV, setDebitV] = useState<any>();

  const [ibeTrans, setIbeTrans] = useState<any>([]);

  const [addTransaction] = useMutation(ADD_TRANSACTION);

  useEffect(() => {
    const brandIds = brands.map((item: any) => {
      return item.id;
    });

    setBrdIds(brandIds);
  }, [brands]);


  useEffect(() => {
    setIbeTrans(
      Array.from(
        new Set(
          transactions
            ?.map((transaction: any) => transaction?.inq_ticket?.ibe)
            ?.filter((val: any): val is string => val !== undefined)
        )
      ).map((ibe: any) => ({
        ibe: ibe,
        inq_id: transactions?.filter(
          (item: any) => item?.inq_ticket?.ibe === ibe
        )?.[0]?.inq_id,
        user_id: transactions?.filter(
          (item: any) => item?.inq_ticket?.ibe === ibe
        )?.[0]?.user_id,
      }))
    );

    setRefundedInquiry(
      Array.from(
        new Set(
          transactions
            ?.filter((item: any) => item?.def_acc_id === 4)
            ?.map((transaction: any) => transaction?.inq_list?.inquiry_no)
            ?.filter((val: any) => val !== undefined)
        )
      ).map((inq_no: any) => ({
        inq_no: inq_no,
        inq_id: transactions?.filter(
          (item: any) => item?.inq_list?.inquiry_no === inq_no
        )?.[0]?.inq_id,
        user_id: transactions?.filter(
          (item: any) => item?.inq_list?.inquiry_no === inq_no
        )?.[0]?.user_id,
      }))
    );

    const initialCreditValue =
      heading === "Adjust Vendor Balance"
        ? -transactions?.reduce((sum: any, transaction: any) => {
          return transaction.type === "credit" && transaction?.inq_ticket?.ibe
            ? sum + transaction.amount
            : sum;
        }, 0)
        : -transactions?.reduce((sum: any, transaction: any) => {
          return transaction.type === "credit" &&
            transaction?.def_acc_id === 4
            ? sum + transaction.amount
            : sum;
        }, 0);

    setCreditV(initialCreditValue);

    const initialDebitValue =
      heading === "Adjust Vendor Balance"
        ? transactions?.reduce((sum: any, transaction: any) => {
          return transaction.type === "debit" && transaction?.inq_ticket?.ibe
            ? sum + transaction.amount
            : sum;
        }, 0)
        : transactions?.reduce((sum: any, transaction: any) => {
          return transaction.type === "debit" && transaction?.def_acc_id === 4
            ? sum + transaction.amount
            : sum;
        }, 0);

    setDebitV(initialDebitValue);
  }, [transactions]);


  // useEffect(() => {
  //   client
  //     .query({
  //       query: GET_TRANSACTIONS,
  //       variables: {
  //         userID: user.id,
  //         brdIds: brdIds,
  //       },
  //     })
  //     .then((trans: any) => {
  //       setTransactions(trans?.data?.acc_transactions);
  //     });
  // }, []);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await client.query({
          query: GET_TRANSACTIONS,
          variables: {
            userID: user.id,
            brdIds: brdIds,
          },
        });

        const fetchedTransactions = response?.data?.acc_transactions;
        await new Promise((resolve) => setTimeout(resolve, 500)); // Optional delay

        setTransactions(fetchedTransactions);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, [client, user.id, brdIds]);




  const addTrans = async () => {
    setLoading(true);

    const transactionNo1 = generateRandom10DigitCode();

    if (!selectedBrand?.brd_id) {
      toast.error("Please Select Brand");
      setLoading(false);
      return;
    }

    if (!selectedPayMeth) {
      toast.error("Please Select Payment Mehtod");
      setLoading(false);
      return;
    }

    const transactions: any = [
      {
        brd_id: selectedBrand?.brd_id,
        def_acc_id: 3,
        payment_method_id: selectedPayMeth,
        transactions_no: transactionNo1,
        type: "credit",
        amount:
          heading === "Adjust Vendor Balance"
            ? -parseFloat(
              ibeTrans.reduce((sum: any, transaction: any) => {
                return transaction?.paying ? sum + transaction?.paying : sum;
              }, 0)
            )
            : -parseFloat(
              refundedInquiry.reduce((sum: any, transaction: any) => {
                return transaction?.paying ? sum + transaction?.paying : sum;
              }, 0)
            ),
        date: moment(date).format("YYYY-MM-DD"),
      },
      ...(heading === "Adjust Vendor Balance"
        ? ibeTrans
          ?.filter((item: any) => item?.paying > 0)
          ?.map((trans: any) => ({
            brd_id: selectedBrand?.brd_id,
            def_acc_id: 2,
            inq_id: trans?.inq_id,
            payment_method_id: selectedPayMeth,
            user_id: trans?.user_id,
            transactions_no: transactionNo1,
            type: "debit",
            amount: trans.paying,
            date: moment(date).format("YYYY-MM-DD"),
          }))
        : refundedInquiry
          ?.filter((item: any) => item?.paying > 0)
          ?.map((trans: any) => ({
            brd_id: selectedBrand?.brd_id,
            def_acc_id: 4,
            inq_id: trans?.inq_id,
            payment_method_id: selectedPayMeth,
            user_id: trans?.user_id,
            transactions_no: transactionNo1,
            type: "debit",
            amount: trans.paying,
            date: moment(date).format("YYYY-MM-DD"),
          }))),
    ];



    await Promise.all(
      transactions?.map(async (transaction: any) => {
        const res = await addTransaction({
          variables: {
            transactions: [transaction],
          },
        });
      })
    ).then(async () => {
      toast.success("Transactions Successfully Added");
      setLoading(false);
      setSidePopup(false);
    });
  };

  return (
    <>
      <div className="md:w-[1000px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">{heading}</h1>
        <Divider />
        <Box>
          <div className="grid grid-cols-1 mt-10 gap-5">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  defaultValue={dayjs(new Date())}
                  onChange={(date: any) => {
                    setDate(date?.$d);
                  }}
                  sx={{ width: "100%" }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Autocomplete
              sx={{ marginBottom: "10px" }}
              disablePortal
              onChange={(_, data: any) => {
                setSelectedBrand(data);
              }}
              options={user?.brd_users || []}
              getOptionLabel={(option: any) => option.brd_list.name}
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  {option.brd_list.name}
                </Box>
              )}
              renderInput={(params) => <TextField label="Brands" {...params} />}
              noOptionsText=""
            />
            <Autocomplete
              sx={{ marginBottom: "10px" }}
              disablePortal
              onChange={(_, data: any) => {
                setSelectedPayMeth(data?.id);
              }}
              options={paymentMethodName || []}
              getOptionLabel={(option: any) => option.name}
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField label="Payment Method" {...params} />
              )}
              noOptionsText=""
            />
            <TextField
              label="Credit"
              disabled
              value={creditV?.toFixed(2) || 0}
            />
            <TextField label="Debit" disabled value={debitV?.toFixed(2) || 0} />
            <TextField
              label="Difference"
              value={creditV?.toFixed(2) - debitV?.toFixed(2) || 0}
              disabled
            />
            <h1 className="text-center text-3xl text-basic my-2">
              All Transactions
            </h1>
            <div className="overflow-scroll flex flex-col gap-3 w-full">
              {heading === "Adjust Vendor Balance"
                ? ibeTrans
                  ?.filter(
                    (item: any) =>
                      -transactions?.reduce((sum: any, transaction: any) => {
                        return transaction.type === "credit" &&
                          transaction?.inq_ticket?.ibe === item?.ibe
                          ? sum + transaction.amount
                          : sum;
                      }, 0) -
                      transactions?.reduce((sum: any, transaction: any) => {
                        return transaction.type === "debit" &&
                          transaction?.inq_ticket?.ibe === item?.ibe
                          ? sum + transaction.amount
                          : sum;
                      }, 0) >
                      0
                  )
                  ?.map((trans: any) => {
                    return (
                      <div className="grid grid-cols-3 gap-5 mt-2 items-center">
                        <TextField
                          label="Vendor Ref"
                          disabled
                          value={trans?.ibe}
                        />
                        <TextField
                          label="Pending"
                          disabled
                          value={(
                            -transactions?.reduce(
                              (sum: any, transaction: any) => {
                                return transaction.type === "credit" &&
                                  transaction?.inq_ticket?.ibe === trans?.ibe
                                  ? sum + transaction.amount
                                  : sum;
                              },
                              0
                            ) -
                            transactions?.reduce(
                              (sum: any, transaction: any) => {
                                return transaction.type === "debit" &&
                                  transaction?.inq_ticket?.ibe === trans?.ibe
                                  ? sum + transaction.amount
                                  : sum;
                              },
                              0
                            )
                          )?.toFixed(2)}
                        />
                        <TextField
                          label="Paying"
                          onChange={(e: any) => {
                            setIbeTrans((prevTrans: any) =>
                              prevTrans.map((item: any) =>
                                item?.ibe === trans?.ibe
                                  ? {
                                    ...item,
                                    paying: parseFloat(e.target.value),
                                  }
                                  : item
                              )
                            );
                          }}
                          type="number"
                          placeholder={(
                            -transactions?.reduce(
                              (sum: any, transaction: any) => {
                                return transaction.type === "credit" &&
                                  transaction?.inq_ticket?.ibe === trans?.ibe
                                  ? sum + transaction.amount
                                  : sum;
                              },
                              0
                            ) -
                            transactions?.reduce(
                              (sum: any, transaction: any) => {
                                return transaction.type === "debit" &&
                                  transaction?.inq_ticket?.ibe === trans?.ibe
                                  ? sum + transaction.amount
                                  : sum;
                              },
                              0
                            )
                          )?.toFixed(2)}
                          value={trans?.paying || null}
                        />
                      </div>
                    );
                  })
                : refundedInquiry
                  ?.filter(
                    (item: any) =>
                      -transactions?.reduce((sum: any, transaction: any) => {
                        return transaction.type === "credit" &&
                          transaction?.inq_list?.inquiry_no ===
                          item?.inq_no &&
                          transaction?.def_acc_id === 4
                          ? sum + transaction.amount
                          : sum;
                      }, 0) -
                      transactions?.reduce((sum: any, transaction: any) => {
                        return transaction.type === "debit" &&
                          transaction?.inq_list?.inquiry_no ===
                          item?.inq_no &&
                          transaction?.def_acc_id === 4
                          ? sum + transaction.amount
                          : sum;
                      }, 0) !==
                      0
                  )
                  ?.map((inq: any) => {
                    return (
                      <div className="grid grid-cols-3 gap-5 mt-2">
                        <TextField
                          label="Inquiry No"
                          disabled
                          value={inq?.inq_no}
                        />
                        <TextField
                          label="Pending"
                          disabled
                          value={(
                            -transactions?.reduce(
                              (sum: any, transaction: any) => {
                                return transaction.type === "credit" &&
                                  transaction?.inq_list?.inquiry_no ===
                                  inq?.inq_no &&
                                  transaction?.def_acc_id === 4
                                  ? sum + transaction.amount
                                  : sum;
                              },
                              0
                            ) -
                            transactions?.reduce(
                              (sum: any, transaction: any) => {
                                return transaction.type === "debit" &&
                                  transaction?.inq_list?.inquiry_no ===
                                  inq?.inq_no &&
                                  transaction?.def_acc_id === 4
                                  ? sum + transaction.amount
                                  : sum;
                              },
                              0
                            )
                          )?.toFixed(2)}
                        />
                        <TextField
                          label="Paying"
                          onChange={(e: any) => {
                            setRefundedInquiry((prevTrans: any) =>
                              prevTrans.map((item: any) =>
                                item?.inq_no === inq?.inq_no
                                  ? {
                                    ...item,
                                    paying: parseFloat(e.target.value),
                                  }
                                  : item
                              )
                            );
                          }}
                          type="number"
                          placeholder={(
                            -transactions?.reduce(
                              (sum: any, transaction: any) => {
                                return transaction.type === "credit" &&
                                  transaction?.inq_list?.inquiry_no ===
                                  inq?.inq_no &&
                                  transaction?.def_acc_id === 4
                                  ? sum + transaction.amount
                                  : sum;
                              },
                              0
                            ) -
                            transactions?.reduce(
                              (sum: any, transaction: any) => {
                                return transaction.type === "debit" &&
                                  transaction?.inq_list?.inquiry_no ===
                                  inq?.inq_no &&
                                  transaction?.def_acc_id === 4
                                  ? sum + transaction.amount
                                  : sum;
                              },
                              0
                            )
                          )?.toFixed(2)}
                          value={inq?.paying || null}
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </Box>
        <Button
          onClick={addTrans}
          fullWidth
          sx={{ marginTop: "20px" }}
          variant="contained"
        >
          {loading ? "Proceeding..." : "Proceed Balance Adjustment"}
        </Button>
      </div>
    </>
  );
};

export default AdjustPayments;
