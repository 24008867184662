import { gql } from "@apollo/client";

export const GET_INQ_INVOICE_WITH_USER_ID = gql`
  query MyQuery($userId: uuid) {
    inq_list(where: { user_id: { _eq: $userId } }) {
      inq_invoices {
        amount
        due_date
        invoice_no
        invoice_pdf
        invoice_sign
        created_at
      }
    }
  }
`;
