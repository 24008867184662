import React from "react";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import StayCard from "~/modules/InquiriesModule/components/StayCard";
import CarCard from "~/modules/InquiriesModule/components/CarCard";
import TrainCard from "~/modules/InquiriesModule/components/TrainCard";
import InquiryCard from "~/modules/InquiriesModule/components/InquiryCard";
import VisaCard from "~/modules/InquiriesModule/components/VisaCard";

const TabPanel = ({ children, value, index, ...other }: any) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-full" // Ensure it takes full height
    >
      {value === index && (
        <Box sx={{ p: 0, height: "100%" }}>
          {" "}
          {/* Remove padding and set height */}
          {children}
        </Box>
      )}
    </div>
  );
};

interface InquiryTabPanelProps {
  value: number;
  tabs: { label: string; details: any[] }[];
  inquiry: any;
  className: any;
}

const InquiryTabPanel: React.FC<InquiryTabPanelProps> = ({
  value,
  tabs,
  inquiry,
  className,
}) => {
  const user_id = useUserId();
  const defaultRole = useUserDefaultRole();

  return (
    <>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {!tab.details || tab.details.length === 0 ? (
            <Typography>No details available</Typography>
          ) : (
            <>
              {/* {tab.details.map((detail: any, detailIndex: any) => ( */}
              <>
                <div className="relative shadow bg-secondary dark:bg-dark-secondary rounded-2xl flex flex-col items-center justify-end text-left text-xs text-darkslategray h-[380px]">
                  {/* {tab.label === "Hotel" && (
                      
                      <StayCard
                        stayId={detail.id}
                        location={detail.location}
                        startDate={detail.start_date}
                        endDate={detail.end_date}
                        rooms={detail.rooms}
                        detail={detail}
                        height="300px"
                        adults={detail.adults}
                        children={detail.children}
                        editAble={
                          inquiry.status !== "completed" &&
                          (user_id === inquiry.picked_by ||
                            defaultRole === "admin")
                        }
                      />
                    )} */}
                  {tab.label === "Hotel" && (
                    <Swiper
                      className="my-swiper"
                      style={{ width: "100%", height: "100%" }}
                      modules={[Navigation, Pagination]}
                      navigation
                      pagination={{ clickable: true }}
                      spaceBetween={20}
                      slidesPerView={1}
                      loop={true}
                    >
                      {tab.details.map((hotelDetail: any, hotelDetailIndex) => (
                        <SwiperSlide key={hotelDetailIndex}>
                          <StayCard
                            stayId={hotelDetail.id}
                            location={hotelDetail.location}
                            startDate={hotelDetail.start_date}
                            endDate={hotelDetail.end_date}
                            rooms={hotelDetail.rooms}
                            detail={hotelDetail}
                            height="300px"
                            adults={hotelDetail.adults}
                            children={hotelDetail.children}
                            editAble={
                              inquiry.status !== "completed" &&
                              (user_id === inquiry.picked_by ||
                                defaultRole === "admin")
                            }
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                  {tab.label === "Cars" && (
                    <CarCard
                      carId={tab.details?.[0].id}
                      origin={tab.details?.[0].origin}
                      destination={tab.details?.[0].destination}
                      Pick_Date_Time={tab.details?.[0].pick_date_time}
                      Drop_Date_Time={tab.details?.[0].drop_date_time}
                      withDriver={tab.details?.[0].with_driver ? "Yes" : "No"}
                      height="300px"
                      editAble={
                        inquiry.status !== "completed" &&
                        (user_id === inquiry.picked_by ||
                          defaultRole === "admin")
                      }
                    />
                  )}
                  {tab.label === "Train" && (
                    <TrainCard
                      trainId={tab.details?.[0].id}
                      inqId={tab.details?.[0].inq_id}
                      origin={tab.details?.[0].origin}
                      destination={tab.details?.[0].destination}
                      date={tab.details?.[0].date}
                      passengers={tab.details?.[0].train_inq_passengers}
                      height="300px"
                      editAble={
                        inquiry.status !== "completed" &&
                        (user_id === inquiry.picked_by ||
                          defaultRole === "admin")
                      }
                    />
                  )}
                  {tab.label === "Flights" && (
                    <InquiryCard
                      inquiry={inquiry}
                      header={false}
                      height="300px"
                      editAble={
                        inquiry.status !== "completed" &&
                        (user_id === inquiry.picked_by ||
                          defaultRole === "admin")
                      }
                    />
                  )}
                  {tab.label === "Visa" && (
                    <VisaCard
                      name={tab.details?.[0].name}
                      country={tab.details?.[0].country}
                      details={tab.details?.[0]}
                      height="300px"
                      editAble={
                        inquiry.status !== "completed" &&
                        (user_id === inquiry.picked_by ||
                          defaultRole === "admin")
                      }
                    />
                  )}
                </div>
              </>
              {/* ))} */}
            </>
          )}
        </TabPanel>
      ))}
    </>
  );
};

export default InquiryTabPanel;
