// import { useUserDefaultRole } from "@nhost/react";
// import React from "react";

// export default function CostBreakDown({
//   inq_suggestion_cost,
//   setShowCostBreakdownModal,
//   staySuggestionCost,
//   car_inq_suggestion_cost,
//   visa_inq_suggestion_cost,
//   train_inq_suggestion_cost,
//   tabLabel,
// }: any) {
//   const userRole = useUserDefaultRole();

//   console.log("jsdbs", staySuggestionCost)

//   // Determine which cost details to use based on the tabLabel

//   let costDetails;
//   switch (tabLabel) {
//     case "Stay":
//       costDetails = staySuggestionCost;
//       break;
//     case "Cars":
//       costDetails = car_inq_suggestion_cost;
//       break;
//     case "Train":
//       costDetails = train_inq_suggestion_cost;
//       break;
//     case "Visa":
//       costDetails = visa_inq_suggestion_cost
//       break;
//     default:
//       costDetails = inq_suggestion_cost; // Default case for the initial tab
//   }

//   return (
//     <div className="fixed top-0 z-[5] left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
//       <div className="bg-white dark:bg-dark-primary p-5 rounded-lg">
//         <div className="flex flex-col md:flex-row w-full justify-between items-center mb-5">
//           <h1 className="text-primary dark:text-dark-primary ">
//             Cost Breakdown
//           </h1>
//           <button
//             className="button btn bg-basic text-white rounded-[5px] p-2 py-[3px]"
//             onClick={() => setShowCostBreakdownModal(false)}
//           >
//             Close
//           </button>
//         </div>
//         <table className="min-w-full text-center divide-y divide-gray-200 ">
//           <thead>
//             <tr>
//               <th
//                 scope="col"
//                 className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-secondary dark:text-dark-secondary "
//               >
//                 Sr
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-secondary dark:text-dark-secondary "
//               >
//                 Name
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-secondary dark:text-dark-secondary "
//               >
//                 Type
//               </th>
//               <th
//                 scope="col"
//                 className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-secondary dark:text-dark-secondary "
//               >
//                 Sale
//               </th>
//               {userRole !== "user" && (
//                 <th
//                   scope="col"
//                   className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-secondary dark:text-dark-secondary "
//                 >
//                   Cost + Tax
//                 </th>
//               )}
//             </tr>
//           </thead>
//           <tbody>
//             {costDetails.map((costDetail: any, index: any) => (
//               <tr key={index}>
//                 <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary  whitespace-nowrap">
//                   {index + 1}
//                 </td>
//                 <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary  whitespace-nowrap">
//                   {costDetail?.inq_passenger?.first_name}{" "}
//                   {costDetail?.inq_passenger?.last_name}
//                 </td>

//                 <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary  whitespace-nowrap">
//                   {costDetail?.inq_passenger?.type}
//                 </td>

//                 <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary  whitespace-nowrap">
//                   {costDetail?.sale_price}
//                 </td>

//                 {userRole !== "user" && (
//                   <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary  whitespace-nowrap">
//                     {costDetail.basic_fare || 0} + {costDetail.tax_price || 0} +{" "}
//                     {costDetail?.atol_price} ={" "}
//                     {costDetail.basic_fare +
//                       costDetail.tax_price +
//                       costDetail?.atol_price}
//                   </td>
//                 )}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

import { useUserDefaultRole } from "@nhost/react";
import { capitalize } from "lodash";
import React from "react";

export default function CostBreakDown({
  inq_suggestion_cost,
  setShowCostBreakdownModal,
  staySuggestionCost,
  car_inq_suggestion_cost,
  visa_inq_suggestion_cost,
  train_inq_suggestion_cost,
  tabLabel,
}: any) {
  const userRole = useUserDefaultRole();

  // Determine which cost details to use based on the tabLabel
  let costDetails: any;
  if (tabLabel === "Hotel") {
    costDetails = staySuggestionCost;
  } else {
    switch (tabLabel) {
      case "Cars":
        costDetails = car_inq_suggestion_cost;
        break;
      case "Train":
        costDetails = train_inq_suggestion_cost;
        break;
      case "Visa":
        costDetails = visa_inq_suggestion_cost;
        break;
      default:
        costDetails = inq_suggestion_cost; // Default case for the initial tab
    }
  }

  return (
    <div className="fixed top-0 z-[5] left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white dark:bg-dark-primary p-5 rounded-lg">
        <div className="flex flex-col md:flex-row w-full justify-between items-center mb-5">
          <h1 className="text-primary dark:text-dark-primary">
            Cost Breakdown
          </h1>
          <button
            className="button btn bg-basic text-white rounded-[5px] p-2 py-[3px]"
            onClick={() => setShowCostBreakdownModal(false)}
          >
            Close
          </button>
        </div>
        {tabLabel === "Hotel" ? (
          <table className="min-w-full text-center divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-3.5 text-sm font-normal text-left text-secondary dark:text-dark-secondary">
                  Sr
                </th>
                <th className="px-4 py-3.5 text-sm font-normal text-left text-secondary dark:text-dark-secondary">
                  Room Number
                </th>
                <th className="px-4 py-3.5 text-sm font-normal text-left text-secondary dark:text-dark-secondary">
                  Room Type
                </th>
                <th className="px-4 py-3.5 text-sm font-normal text-left text-secondary dark:text-dark-secondary">
                  Sale Price
                </th>
                {userRole !== "user" && (
                  <th className="px-4 py-3.5 text-sm font-normal text-left text-secondary dark:text-dark-secondary">
                    Basic Fare
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {costDetails?.map((room: any, index: number) => (
                <tr key={index}>
                  <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap">
                    {capitalize(room.room_number)}
                  </td>
                  <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap">
                    {capitalize(room.room_type)}
                  </td>
                  <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap">
                    {room.stay_inq_suggestion_costs[0]?.sale_price || 0}
                  </td>
                  {userRole !== "user" && (
                    <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap">
                      {room.stay_inq_suggestion_costs[0]?.basic_fare || 0}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <table className="min-w-full text-center divide-y divide-gray-200">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-secondary dark:text-dark-secondary "
                >
                  Sr
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-secondary dark:text-dark-secondary "
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-secondary dark:text-dark-secondary "
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-secondary dark:text-dark-secondary "
                >
                  Sale
                </th>
                {userRole !== "user" && (
                  <th
                    scope="col"
                    className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-secondary dark:text-dark-secondary "
                  >
                    Cost + Tax
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {costDetails?.map((costDetail: any, index: number) => (
                <tr key={index}>
                  <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap">
                    {costDetail?.inq_passenger?.first_name}{" "}
                    {costDetail?.inq_passenger?.last_name}
                  </td>
                  <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap">
                    {costDetail?.inq_passenger?.type}
                  </td>
                  <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap">
                    {costDetail?.sale_price}
                  </td>
                  {userRole !== "user" && (
                    <td className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap">
                      {costDetail.basic_fare || 0} + {costDetail.tax_price || 0} +{" "}
                      {costDetail?.atol_price || 0} ={" "}
                      {costDetail.basic_fare +
                        costDetail.tax_price +
                        costDetail?.atol_price}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

