// import { useApolloClient, useMutation, useQuery } from "@apollo/client";
// import React from "react";
// import travelHouseStore from "~/store/travelHouse/travelHouseStore";
// import { GET_ACTIVE_MEMBERS_WITH_THP } from "../../../TeamList/components/TravelHouseWise/graphql/Query";
// import SingleRecord from "./components/SingleRecord";
// import { useForm } from "react-hook-form";
// import { GET_USERS } from "./graphql/Query";
// import moment from "moment";
// import { ADD_ATTENDANCE, UPDATE_ATTENDANCE } from "./graphql/Mutation";
// import { toast } from "react-toastify";
// // import SingleRecord from "~/modules/TravelHousesModule/segments/TravelHouseList/SingleRecord";

// export default function ResultsForm({ date }: any) {
//   const { travelHouse }: any = travelHouseStore();

//   const [add] = useMutation(ADD_ATTENDANCE);
//   const [update] = useMutation(UPDATE_ATTENDANCE);
//   const client = useApolloClient();

//   const startDate = moment(date).format("YYYY-MM-DD");
//   const endDate = moment(date).add(1, "day").format("YYYY-MM-DD");

//   const timestart = `${startDate}T00:00:00.000+00:00`;
//   const timeend = `${endDate}T00:00:00.000+00:00`;

//   const { data, error, loading } = useQuery(GET_USERS, {
//     variables: { thp_id: travelHouse?.id, timestart, timeend },
//   });

//   const {
//     control,
//     setValue,
//     watch,
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm();

//   const onSubmit = async (formData: any) => {
//     const addAttendanceData: any = [];
//     const updateAttendanceData: any = [];

//     formData.user.map((item: any) => {
//       if (item.id) {
//         updateAttendanceData.push(item);
//       } else {
//         item["thp_id"] = travelHouse.id;
//         item["date"] = moment(date).format("YYYY-MM-DD");
//         addAttendanceData.push(item);
//       }
//     });

//     if (addAttendanceData.length > 0) {
//       try {
//         const res = await add({
//           variables: {
//             data: addAttendanceData,
//           },
//         });
//         await client.resetStore();
//         toast.success("Attendance added successfully", {
//           position: toast.POSITION.BOTTOM_RIGHT,
//         });
//       } catch (e) {
//
//       }
//     }
//     if (updateAttendanceData.length > 0) {
//       updateAttendanceData.map(async (item: any) => {
//         const id = item.id;
//         delete item.user_id;
//         delete item.id;
//         try {
//           const res = await update({
//             variables: {
//               id,
//               data: item,
//             },
//           });

//           await client.resetStore();
//         } catch (e) {
//
//         }
//       });

//       toast.success("Attendance added successfully", {
//         position: toast.POSITION.BOTTOM_RIGHT,
//       });
//     }
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit(onSubmit)} className="w-full">
//         {data?.users.map((user: any, index: any) => {
//           return (
//             <SingleRecord
//               user={user}
//               date={date}
//               errors={errors}
//               index={index}
//               register={register}
//             />
//           );
//         })}
//         <div className="flex gap-2 w-full mt-4">
//           <button
//             className="w-full btn bg-basic text-white rounded-md p-2"
//             type="submit"
//           >
//             Update
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_THP_USER_ATTENDANCE_USING_DATE } from "~/graphql/thp_user_attendance/Query";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  Typography,
  Modal,
} from "@mui/material";
import BreakRecords from "./components/BreakRecords";
import brandsStore from "~/store/brandsStore/brandsStore";

export default function ResultsForm({ date }: { date: any }) {
  const { travelHouse } = travelHouseStore();
  const formattedDate = moment(date?.$d).format("YYYY-MM-DD");

  const { brands } = brandsStore();

  const [brdIds, setBrdIds] = useState<any>(null);

  useEffect(() => {
    const ids = brands.map((brand: any) => brand.id);
    setBrdIds(ids);
  }, [brands]);

  const { data, error, loading } = useQuery(
    GET_THP_USER_ATTENDANCE_USING_DATE,
    {
      variables: { attendanceDate: formattedDate, brdIds },
    }
  );
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);

  const handleOpen = (record: any) => {
    setSelectedRecord(record);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRecord(null);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <TableContainer className="mt-5" component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>Out Time</TableCell>
            <TableCell>Total Break Time</TableCell>
            <TableCell>Breaks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.thp_user_attendance.map((record: any, index: number) => (
            <TableRow key={index}>
              <TableCell>{record.user.displayName}</TableCell>
              <TableCell>
                {moment(record.start_time).format("h:mm A")}
              </TableCell>
              <TableCell>
                {moment(record.out_time).format("h:mm A") || ""}
              </TableCell>
              <TableCell>
                {(() => {
                  const totalMinutes =
                    record?.breaks?.reduce(
                      (accumulator: any, item: any) =>
                        accumulator + item.break_time_minutes,
                      0
                    ) || 0;

                  const hours = Math.floor(totalMinutes / 60);
                  const minutes = totalMinutes % 60;

                  let result = "";
                  if (hours > 0) {
                    result += `${hours} hour${hours > 1 ? "s" : ""}`;
                  }
                  if (minutes > 0) {
                    if (result) result += " and ";
                    result += `${minutes} minute${minutes > 1 ? "s" : ""}`;
                  }
                  if (!result) {
                    result = "0 minutes"; // Default case if both hours and minutes are zero
                  }
                  return result;
                })()}
              </TableCell>
              <TableCell>
                <Button
                  className="bg-basic px-3 py-2 text-white rounded-md"
                  onClick={() => handleOpen(record)}
                >
                  Get Breaks
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            height: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            // overflow: "auto",
          }}
        >
          <BreakRecords selectedRecord={selectedRecord} />
        </Box>
      </Modal>
    </TableContainer>
  );
}
