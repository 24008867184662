import AddCsvInq from "~/modules/InquiriesModule/components/AddCsvInq";
import Layout from "~/components/common/Layout";

export default function AddCsvInqPage() {
  return (
    <div>
      <Layout>
        <AddCsvInq />
      </Layout>
    </div>
  );
}
