import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import {
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import { EDIT_INQUIRY } from "./graphql/Mutation";
import LocationBox from "components/FormInputs/LocationBox";
import { CalendarBox } from "components/FormInputs/CalendarBox";
import SelectBox from "components/FormInputs/SelectBox";
import { CabinClasses } from "~/data/cabinClasses";
import AirlineBox from "~/components/FormInputs/AirlineBox";
import InputField from "~/components/FormInputs/InputField";
import moment from "moment";
import useNotifications from "~/hooks/useNotifications";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_PAYMENT_METHODS } from "./graphql/Query";
import useEmail from "~/hooks/emails/useEmail";
import { Divider } from "@mui/material";

export default function InquiryCardEdit({ inquiry, setEdit }: any) {
  const { travelHouse }: any = travelHouseStore();
  const userDefaultRole = useUserDefaultRole();
  // get data based on query
  const { loading, data, error } = useQuery(GET_PAYMENT_METHODS, {
    variables: { th_id: travelHouse.id, brd_id: inquiry.brd_id },
  });

  const userId = useUserId();
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const client = useApolloClient();
  const alert = useNotifications();
  const role = useUserDefaultRole();
  const userDisplayName = useUserDisplayName();
  const emailSend = useEmail();
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    setValue,
  } = useForm();

  const onSubmit = async (data: any) => {
    let pay_id;
    if (userDefaultRole !== "user") {
      pay_id = data["payment_method_id"] || null;
    } else {
      pay_id = inquiry?.payment_method_id;
    }

    const payload = {
      ...data,
      id: inquiry.id,
      from: data["from"].iata_code,
      to: data["to"].iata_code,
      payment_method_id: pay_id,
      departure_datetime: moment(data["departure"]).format("YYYY-MM-DD"),
      arrival_datetime: data["arrival"]
        ? moment(data["arrival"]).format("YYYY-MM-DD")
        : null,
      preferred_airline: data["preferred_airline"]?.iata || null,
      flexible_departure: data["flexible_departure"] === "yes" ? true : false,
      flexible_arrival: data["flexible_arrival"] === "yes" ? true : false,
      direct_flight: data["direct_flight"] === "yes" ? true : false,
      approx_budget: data["approx_budget"] || null,
    };

    try {
      const res = await editInquiry({ variables: payload });
      if (res.data?.update_inq_list?.returning?.length > 0) {
        const variables: any = {
          inquiryNo: inquiry?.inquiry_no,
          brandName: inquiry?.brd_list?.name,
          userFirstName: inquiry?.users?.displayName,
          userLastName: "",
          consultantName: userDisplayName,
        };

        // ;
        await emailSend(
          2,
          inquiry.brd_id,
          inquiry.users?.id,
          inquiry.users?.email,
          inquiry.users?.phoneNumber,
          variables
        );

        if (role === "user") {
          await emailSend(
            16,
            inquiry.brd_id,
            inquiry?.picked_by,
            inquiry.users?.email,
            inquiry.users?.phoneNumber,
            variables
          );
        } else {
          await alert.newNotification(
            inquiry?.users?.id,
            `Your Consultant Updated your Inquiry `,
            "",
            ``
          );
        }
        toast.success(`Inquiry Detail Edit Successfully`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setEdit(false);
        await client.resetStore();
      }
    } catch (e) {
      toast.error(`${e}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  const paymentMethods: any = [
    {
      id: "",
      value: "",
      title: "--Select Payment Method--",
    },
  ];
  data.acc_payment_methods
    .filter((pay: any) => pay.type === "bank")
    .forEach((pay: any) => {
      const singlePay: any = {};
      singlePay["id"] = pay.id;
      singlePay["title"] = pay.name;
      singlePay["value"] = pay.id;
      paymentMethods.push(singlePay);
    });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="min-w-[500px] h-full p-4 pt-10 dark:bg-dark-primary"
    >
      <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
        Edit Inquiry
      </h2>
      <Divider />
      <div className="mt-10">
        <div className="flex w-full">
          <div className="flex-1 m-2">
            <LocationBox
              control={control}
              errors={errors}
              setValue={setValue}
              fieldName={`from`}
              label={"from"}
              labelClasses="capitalize text-primary dark:text-dark-primary"
              placeholder="Choose your location"
              defaultValue={inquiry.from}
              inputClasses="text-primary dark:text-dark-primary bg-white dark:bg-dark-primary focus:outline-none focus:border-basic"
            />
          </div>
          <div className="flex-1 m-2">
            <LocationBox
              control={control}
              errors={errors}
              setValue={setValue}
              fieldName={`to`}
              label={"To"}
              labelClasses="capitalize text-primary dark:text-dark-primary font-normal"
              placeholder="Choose your location"
              defaultValue={inquiry.to}
              inputClasses="text-primary dark:text-dark-primary bg-white dark:bg-dark-primary focus:outline-none focus:border-basic"
            />
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex-1 m-2">
            <CalendarBox
              control={control}
              setValue={setValue}
              errors={errors}
              fieldName="departure"
              label="Departure Date"
              defaultValue={inquiry.departure_datetime}
              labelClasses="mb-2 text-primary dark:text-dark-primary text-base font-normal"
              inputClasses="text-primary dark:text-dark-primary bg-white dark:bg-dark-primary border-gray-200 focus:outline-none focus:border-basic"
            />
          </div>
          {inquiry?.trip_type === "roundTrip" && (
            <div className="flex-1 m-2">
              <CalendarBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName="arrival"
                label="Arrival Date"
                defaultValue={inquiry.arrival_datetime}
                labelClasses="mb-2 text-primary dark:text-dark-primary text-base font-normal"
                inputClasses="text-primary dark:text-dark-primary bg-white dark:bg-dark-primary border-gray-200 focus:outline-none focus:border-basic"
              />
            </div>
          )}
        </div>
        <div className="flex w-full">
          <div className="flex-1 m-2 z-30">
            {/* <SelectBox
              control={control}
              errors={errors}
              fieldName={"cabin"}
              label="Cabin Class"
              options={CabinClasses}
              selectedOption={CabinClasses[0]}
              defaultValue={inquiry?.cabin_class}
              optionContainerClasses="dark:bg-dark-secondary"
              inputClasses="border rounded border-gray-200 py-1.5 pl-3 focus:outline-none focus:border-basic text-[14px] dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
              labelClasses="dark:text-dark-primary text-base"
              checkIconClasses="text-basic"
              optionClasses="text-primary dark:text-dark-primary"
              optionHoverClasses="bg-primary dark:bg-dark-primary"
            /> */}
            <SelectBox
              control={control}
              errors={errors}
              fieldName={"cabin"}
              label="Cabin Class"
              options={CabinClasses}
              selectedOption={CabinClasses.find(
                (cabin) => cabin.value === inquiry?.cabin_class
              )} // Select based on inquiry.cabin_class value
              defaultValue={
                inquiry?.cabin_class
                  ? CabinClasses.find(
                      (cabin) => cabin.value === inquiry.cabin_class
                    )
                  : CabinClasses[0]
              }
              optionContainerClasses="dark:bg-dark-secondary"
              inputClasses="border rounded border-gray-200 py-1.5 pl-3 focus:outline-none focus:border-basic text-[14px] dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
              labelClasses="dark:text-dark-primary text-base"
              checkIconClasses="text-basic"
              optionClasses="text-primary dark:text-dark-primary"
              optionHoverClasses="bg-primary dark:bg-dark-primary"
            />
          </div>
          <div className="flex-1 m-2">
            <AirlineBox
              control={control}
              errors={errors}
              setValue={setValue}
              fieldName={`preferred_airline`}
              defaultValue={inquiry?.preferred_airline}
              label="Airline"
              placeholder="Choose your location"
              inputClasses="border rounded border-gray-200 pl-3 text-[14px] bg-white dark:bg-dark-primary focus:outline-none focus:border-basic"
              optionBoxClasses="dark:bg-dark-secondary"
              optionClasses="dark:text-dark-primary"
            />
          </div>
        </div>

        {userDefaultRole !== "user" && (
          <div className="flex-1 m-2">
            <SelectBox
              errors={errors}
              control={control}
              fieldName={"payment_method_id"}
              label="Payment Method"
              options={paymentMethods}
              selectedOption={
                paymentMethods.filter(
                  (pay: any) => pay.id === inquiry?.payment_method_id
                )[0]
              }
              optionContainerClasses="dark:bg-dark-secondary"
              inputClasses="border rounded border-gray-200 py-1.5 pl-3 focus:outline-none focus:border-basic text-[14px] dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
              labelClasses="dark:text-dark-primary text-base"
              checkIconClasses="text-basic"
              optionClasses="text-primary dark:text-dark-primary"
              optionHoverClasses="bg-primary dark:bg-dark-primary"
            />
          </div>
        )}
        <div className="flex w-full">
          <div className="flex-1 m-2">
            <label className="">Flexible Departure</label>
            <div className="flex-1 self-stretch flex flex-row items-center mt-2 justify-start gap-[30px]">
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="Yes"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"yes"}
                  fieldName="flexible_departure"
                  checked={inquiry?.flexible_departure ? true : false}
                  containerClasses="flex"
                  labelClasses="mb-0"
                  inputClasses="text-[14px]"
                />
              </div>
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="No"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"no"}
                  fieldName="flexible_departure"
                  checked={!inquiry?.flexible_departure ? true : false}
                  labelClasses="mb-0"
                  containerClasses="flex"
                />
              </div>
            </div>
          </div>
          <div className="flex-1 m-2">
            <label className="">Flexible Arrival</label>
            <div className="flex-1 self-stretch flex flex-row items-center mt-2 justify-start gap-[30px]">
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="Yes"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"yes"}
                  fieldName="flexible_arrival"
                  checked={inquiry?.flexible_arrival ? true : false}
                  labelClasses="mb-0"
                  containerClasses="flex"
                />
              </div>
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="No"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"no"}
                  fieldName="flexible_arrival"
                  checked={!inquiry?.flexible_arrival ? true : false}
                  labelClasses="mb-0"
                  containerClasses="flex"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex-1 m-2">
            <InputField
              type="number"
              register={register}
              errors={errors}
              label="Approx Budget"
              fieldName="approx_budget"
              placeHolder="Approx Budget"
              defaultValue={inquiry?.approx_budget}
              inputClasses="text-primary dark:text-dark-primary bg-white dark:bg-dark-primary focus:outline-none border focus:border-basic"
            />
          </div>
          <div className="flex-1 m-2">
            <label className="">Direct Flight</label>
            <div className="flex-1 self-stretch flex flex-row items-center mt-2 justify-start gap-[30px]">
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="Yes"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"yes"}
                  fieldName="direct_flight"
                  checked={inquiry?.direct_flight ? true : false}
                  labelClasses="mb-0"
                  containerClasses="flex"
                />
              </div>
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="No"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"no"}
                  fieldName="direct_flight"
                  checked={!inquiry?.direct_flight ? true : false}
                  labelClasses="mb-0"
                  containerClasses="flex"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 w-full mt-2">
        {/* <button
          className="w-full button btn bg-basic text-white rounded-md p-2"
          onClick={() => {
            setEdit(false);
          }}
        >
          Close
        </button> */}
        <button
          className="w-full button btn bg-basic text-white rounded-md p-2"
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  );
}
