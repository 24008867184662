import React, { useEffect, useRef, useState } from "react";
import { useUserData, useUserId } from "@nhost/react";
import UserCard from "~/components/UserCard";
import CloseIcon from "@mui/icons-material/Close";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import {
  FETCH_UPDATE,
  GET_CONVERSATION_DETAIL,
  GET_CONVERSATION_MESSAGES,
  GET_MESSAGES,
  UPDATE_CONVERSATION,
} from "./graphql/Query";
// import { useOptimistic } from "@nhost/react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import SingleMessage from "./components/SingleMessage";
import moment from "moment";
import DotLoader from "~/components/common/DotLoader";
import { MdSend } from "react-icons/md";
import { useForm } from "react-hook-form";
import { SEND_WHATSAPP_MESSAGE } from "~/modules/BrandModule/modules/WhatsappModule/components/Whatsapp/Query";
import { INSERT_MESSAGES, SEND_READ_STATUS } from "./graphql/Mutation";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import WhatsappSend from "~/components/SideForm/WhatsappSend";
import SidePopup from "~/components/common/SidePopup";
import Header from "./components/Header";
import Messages from "./components/Messages";
import Footer from "./components/Footer";
import PopUpModal from "./components/PopUpModal";
import FilesUpload from "./components/FilesUpload";
import SelectTemplate from "./components/SelectTemplate";
import ChatBoxSekeleton from "./components/Sekeleton/ChatBoxSekeleton";
import AttachemnetPrevSend from "./components/AttachemnetPrevSend";
import ReplyMessage from "./components/ReplyMessage";

export default function ChatWindow({
  conversation_id,
  setShowMessageInfoBar,
  showWhatsappSidebar,
  setShowWhatsappChatBox,
  setShowWhatsappSidebar,
  setShowUserInfo,
  setShowConsultantBar,
}: any) {
  const [messages, setMessages] = useState<any>([]);
  const [activeId, setActiveId] = useState(generateRandom10DigitCode());
  const [messageData, setMessageData] = useState("");
  const [user_phone, setUserPhone] = useState("");
  const [brand_phone, setBrandPhone] = useState("");
  const [brand_token, setBrandToken] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { handleSubmit } = useForm();
  const [showReplyMessage, setShowReplyMessage] = useState<any>();

  const [showEmojis, setShowEmojis] = useState(false);

  const [files, setFiles] = useState<any>([]);
  const [attachmentType, setAttachmentType] = useState(null);

  const [isSending, setIsSending] = useState<any>(false);

  const {
    loading: cl,
    data: cd,
    error: ce,
  } = useQuery(GET_CONVERSATION_DETAIL, {
    variables: { id: conversation_id },
    skip: !conversation_id,
  });

  const client = useApolloClient();
  const userId = useUserId();

  // Get the current time in UTC
  const currentTime = moment().utc();

  // Format the current time in the desired format
  const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
  const [timestamp, setTimestamp] = useState<any>(formattedTime);

  const [update] = useMutation(UPDATE_CONVERSATION);
  // const [updateMessage] = useMutation(UPDATE_CONVERSATION_MESSAGE);
  const [insertMessages] = useMutation(INSERT_MESSAGES);

  const chatContainerRef: any = useRef(null);

  // formRef

  const { loading, error, data } = useSubscription(GET_MESSAGES, {
    variables: {
      conversation_id,
    },
  });

  // const {
  //   loading: lf,
  //   error: ef,
  //   data: df,
  // } = useSubscription(FETCH_UPDATE, {
  //   variables: {
  //     conversation_id: conversation_id,
  //     timestamp: timestamp,
  //   },
  // });

  const updateLastViewTime = async (time: any) => {
    const resSuggestion = await update({
      variables: { conversation_id: conversation_id, time: time },
    });
  };

  useEffect(() => {
    // Get the reference to the scrollable div
    const scrollableDiv = document.getElementById("scrollableDiv");

    if (scrollableDiv) {
      // Add a scroll event listener
      const handleScroll = () => {
        // This function will be called whenever the scroll height changes

        if (scrollableDiv?.scrollHeight <= scrollableDiv?.scrollTop + 820) {
          const currentTime = moment().utc();
          const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
          updateLastViewTime(formattedTime);
        }
      };

      scrollableDiv.addEventListener("scroll", handleScroll);

      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        scrollableDiv.removeEventListener("scroll", handleScroll);
      };
    } else {
    }
  }, [chatContainerRef.current]); // Empty dependency array ensures that the effect runs only once (on mount)

  // useEffect(() => {
  //   ;
  //   if (df?.brd_whatsapp_messages_stream?.length > 0) {
  //     const time =
  //       df?.brd_whatsapp_messages_stream[
  //         df?.brd_whatsapp_messages_stream.length - 1
  //       ].timestamp;

  //     ;
  //     updateLastViewTime(time);
  //   }
  // }, [df?.brd_whatsapp_messages_stream]);

  // useEffect(() => {
  //   const currentTime = moment().utc();
  //   const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
  //   updateLastViewTime(formattedTime);
  // }, [cd]);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  useEffect(() => {
    if (cd?.brd_whatsapp_conversations.length > 0) {
      setBrandToken(cd.brd_whatsapp_conversations[0].brd_list.whatsapp_token);
      setBrandPhone(cd.brd_whatsapp_conversations[0].brand_phone);
      setUserPhone(cd.brd_whatsapp_conversations[0].user_phone);
    }
  }, [cd]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
    }
  }, [data?.brd_whatsapp_messages]);

  const sendMessage = async (
    filesIds: any,
    setFilesId: any,
    inputValue: any,
    setInputValue: any
  ) => {
    if (!isSending) {
      setIsSending(true);
      if (files?.length > 0) {
        filesIds?.map(async (file: any) => {
          const currentTime = moment().utc();
          const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");

          const payload: any = [
            {
              conversation_id: conversation_id,
              sender_role: "brand",
              sender_id: userId,
              text: file?.id,
              type: attachmentType,
              timestamp: formattedTime,
            },
          ];
          const resMessagesInsert = await insertMessages({
            variables: { data: payload },
          });
        });
        setFiles([]);
        setFilesId([]);
      } else {
        const textmsg = inputValue?.trim();
        if (textmsg !== "") {
          const currentTime = moment().utc();
          const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
          const payload: any = [
            {
              conversation_id: conversation_id,
              sender_role: "brand",
              sender_id: userId,
              text: textmsg,
              type: "text",
              timestamp: formattedTime,
            },
          ];
          const resMessagesInsert = await insertMessages({
            variables: { data: payload },
          });
        }
      }
      setInputValue("");
      setIsSending(false);
    }
  };

  if (loading) return <ChatBoxSekeleton />;
  if (error) return <p>ERR: {error.message}</p>;

  if (cl) return <p>Loading</p>;
  if (ce) return <p>ERR: {ce.message}</p>;
  // if (lf) return <p>Loading</p>;
  // if (ef) return <p>ERR: {ef.message}</p>;

  return (
    <>
      {/* Right */}
      <div className="w-full flex flex-col h-full">
        <Header
          showWhatsappSidebar={showWhatsappSidebar}
          setShowUserInfo={setShowUserInfo}
          setShowWhatsappChatBox={setShowWhatsappChatBox}
          setShowWhatsappSidebar={setShowWhatsappSidebar}
          conversation={cd.brd_whatsapp_conversations?.[0]}
          setShowConsultantBar={setShowConsultantBar}
        />
        {files.length > 0 ? (
          <>
            <div className="flex-grow bg-whatsappPrimaryBg dark:bg-dark-secondary">
              <AttachemnetPrevSend
                attachmentType={attachmentType}
                setFiles={setFiles}
                files={files}
                setShowEmojis={setShowEmojis}
                showEmojis={showEmojis}
              />
            </div>
          </>
        ) : (
          <>
            {showModal ? (
              <div className="flex-grow bg-whatsappPrimaryBg dark:bg-dark-secondary">
                <PopUpModal
                  setShowModal={setShowModal}
                  showModal={setShowModal}
                >
                  <SelectTemplate
                    setAttachmentType={setAttachmentType}
                    setShowModal={setShowModal}
                    brdId={[cd.brd_whatsapp_conversations?.[0]?.brd_list?.id]}
                  />
                </PopUpModal>
              </div>
            ) : (
              <>
                <Messages
                  setShowReplyMessage={setShowReplyMessage}
                  messages={data?.brd_whatsapp_messages}
                  setShowMessageInfoBar={setShowMessageInfoBar}
                  setShowWhatsappChatBox={setShowWhatsappChatBox}
                />
                {showReplyMessage && (
                  <div className="bg-whatsappPrimaryBg dark:bg-dark-primary pt-[7px] flex items-center px-[22px] pl-[60px]">
                    <ReplyMessage
                      setShowReplyMessage={setShowReplyMessage}
                      message={showReplyMessage}
                      type={
                        showReplyMessage?.sender_role === "user"
                          ? "recieve"
                          : "send"
                      }
                    />
                    <CloseIcon
                      sx={{
                        color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                        cursor: "pointer",
                        marginLeft: "20px",
                      }}
                      fontSize="small"
                      onClick={() => setShowReplyMessage(false)}
                    />
                  </div>
                )}
                <Footer
                  replyMessageID={showReplyMessage?.message_id}
                  sendMessage={sendMessage}
                  setShowEmojis={setShowEmojis}
                  showEmojis={showEmojis}
                  setShowModal={setShowModal}
                  setFiles={setFiles}
                  files={files}
                  attachmentType={attachmentType}
                  setAttachmentType={setAttachmentType}
                  conversation_id={conversation_id}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
