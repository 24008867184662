import moment from "moment";
import { Document, Page, View, Image, Text } from "@react-pdf/renderer";
import { splitLocation } from "~/utils/splitLocation";
import vector81 from "../../../images/dottedImg.svg";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { getStyles } from "../../../pdfDocument/styles";
import room from "../../../images/room.png";
const VisaInvoice = ({ invoice, inquiry }: any) => {
  const visaSuggestion = invoice?.invoice_suggestions?.filter(
    (suggestion: any) => suggestion.type === "visa"
  )?.[0]?.visa_inq_suggestion;

  //   const { cityName: originCity, remainingAddress: originAddress } =
  //     splitLocation(visaSuggestion?.where_to);
  //   const { cityName: hotelcity, remainingAddress: hotelAddres } = splitLocation(
  //     visaSuggestion?.hotel_name
  //   );

  if (!visaSuggestion) return <div>No stay suggestions found</div>;
  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );
  const passengerNames = visaSuggestion?.visa_inq_suggestion_costs
    ?.map((cost: any) => cost?.inq_passenger?.first_name) // Extract first_name
    ?.filter((name: any) => name)
    ?.join(", "); // Join names with commas
  const totalSalePrice = visaSuggestion.visa_inq_suggestion_costs?.reduce(
    (total: any, cost: any) => total + (cost?.sale_price || 0),
    0
  );
  return (
    <View style={styles.inquiryDetailContainer}>
      <View>
        <View style={styles.flightDetailHeader}>
          <Text style={styles.flightTypeText}>Visa Invoice</Text>
          {/* <View style={styles.luggageSubContainer}>
            <Image style={styles.iconSize} src={room} />
            <Text style={styles.flightCabinText}>
              {visaSuggestion?.room_type}
            </Text>
          </View> */}
        </View>
        <View style={styles.flightDetailBody}>
          <View style={styles.flightLegsParentContainer}>
            <View style={styles.flightLegs}>
              <View style={styles.flightLegContainers}>
                <View style={styles.flightLegSubContainers}>
                  {/* <Text style={styles.flightLegArrDep}>originCity</Text> */}
                  <Text>Type: {visaSuggestion?.name}</Text>
                  <Text>Person: {passengerNames}</Text>
                </View>
              </View>

              <Image style={styles.dottedImg} src={vector81} />
              <View style={styles.flightLegContainerRight}>
                <View style={styles.flightLegSubContainerRight}>
                  <Text>Country: {visaSuggestion?.country}</Text>
                  <Text>Expiry: {visaSuggestion?.visa_expiry_date}</Text>
                  {/* <Text>hotelAddres</Text> */}
                </View>
                <Text style={styles.flightLegDateTime}>
                  {/* {moment(visaSuggestion?.end_date).format("DD-MM-YYYY HH:mm")} */}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.flightLegSubContainerRight}>
            <Text style={styles.flightCabinText}>
              Total Sale Price: £ {totalSalePrice}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default VisaInvoice;
