import React, { useEffect, useState } from "react";
import { FormControl, Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";

import {
  Control,
  UseFormRegister,
  FieldValues,
  SubmitHandler,
  Controller,
} from "react-hook-form";

import { options } from "../data/options";
import StayComponent from "../components/Stay";
import CarsComponent from "../components/cars";
import TrainComponent from "../components/train";
import FlightComponent from "../components/flight";
import VisaComponent from "../components/visa";

const SectionContainer = styled(Box)({
  border: "1px solid #ccc",
  borderRadius: "8px",
  padding: "20px",
  marginTop: "20px",
});

const ListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  listStyle: "none",
});

const NewInquiryTypes = ({
  inquiryPassengers,
  register,
  control,
  handleSubmit,
  errors,
  watch,
  setValue,
  selectedOptions,
  onSubmit,
}: any) => {
  const [hotelForms, setHotelForms] = useState<number[]>([1]);

  const handleAddHotel = () => {
    setHotelForms((prev) => [...prev, prev.length + 1]);
  };

  const handleRemoveHotel = () => {
    setHotelForms((prev) => prev.slice(0, -1));
  };

  const onSubmitForm = (data: any) => {
    const submissionData = {
      ...data,
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <FormControl component="fieldset" fullWidth sx={{ mt: 1 }}>
        {selectedOptions.map((optionName: any) => {
          // Get the fields for the current option
          const option = options.find((opt) => opt.name === optionName);
          const fieldsForOption = option ? option.fields : [];

          return (
            <>
              <SectionContainer key={optionName}>
                <Typography variant="h6" gutterBottom>
                  {optionName} Details
                </Typography>
                <ListContainer component="ul">
                  {optionName === "Hotel" ? (
                    <>
                      {hotelForms.map((id, index) => (
                        <StayComponent
                          key={`hotel-form-${id}`} // Unique key for each StayComponent
                          control={control}
                          register={register}
                          setValue={setValue}
                          errors={errors}
                          index={index}
                          inquiryPassengers={inquiryPassengers}
                        />
                      ))}
                      <Box display="flex" gap={2} mt={2}>
                        <Button variant="outlined" onClick={handleAddHotel}>
                          Add Hotel
                        </Button>
                        {hotelForms.length > 1 && (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={handleRemoveHotel}
                          >
                            Remove Hotel
                          </Button>
                        )}
                      </Box>
                    </>
                  ) : optionName === "Cars" ? (
                    <>
                      <CarsComponent
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        inquiryPassengers={inquiryPassengers}
                      />
                    </>
                  ) : optionName === "Train" ? (
                    <>
                      <TrainComponent
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        inquiryPassengers={inquiryPassengers}
                      />
                    </>
                  ) : optionName === "Flights" ? (
                    <>
                      <FlightComponent
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        inquiryPassengers={inquiryPassengers}
                      />
                    </>
                  ) : optionName === "Visa" ? (
                    <>
                      <VisaComponent
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        inquiryPassengers={inquiryPassengers}
                      />
                    </>
                  ) : null}
                </ListContainer>
              </SectionContainer>
            </>
          );
        })}
      </FormControl>
    </form>
  );
};

export default NewInquiryTypes;
