import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { Button, TextField, Grid, Typography } from "@mui/material";
import { GET_SEO_SETTINGS } from "~/graphql/brd_seo_settings/Query";
import {
  INSERT_SEO_SETTINGS_WITH_ID,
  UPDATE_SEO_SETTINGS,
} from "~/graphql/brd_seo_settings/Mutation";
import {
  INSERT_SEO,
  UPDATE_SEO,
} from "~/modules/BrandModule/segments/ManageFAQs/components/SEOSettings/graphql/Mutation";
import { GET_BRD_SEO_SETTINGS_WITH_CASESTUDY_ID } from "~/graphql/brd_seo_settings/Query";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const ShowSEOCasestudy = () => {
  const { register, handleSubmit, setValue } = useForm();
  const { brd_id, case_study_id } = useParams();
  const client = useApolloClient();
  const [insertSeo] = useMutation(INSERT_SEO);
  const [updateSeo] = useMutation(UPDATE_SEO);

  const { data } = useQuery(GET_BRD_SEO_SETTINGS_WITH_CASESTUDY_ID, {
    variables: { caseStudyId: case_study_id },
  });

  // Mutation for updating SEO settings
  // const [updateSEOSettings] = useMutation(UPDATE_SEO_SETTINGS);
  // const [insertSEOSettings] = useMutation(INSERT_SEO_SETTINGS_WITH_ID);

  useEffect(() => {
    if (data) {
      setValue("title", data.brd_seo_setting?.[0]?.default_title || "");
      setValue("keywords", data.brd_seo_setting?.[0]?.default_keywords || "");
      setValue("canonical_url", data.brd_seo_setting?.[0]?.canonical_url || "");
      setValue("og_image_url", data.brd_seo_setting?.[0]?.og_image_url || "");
      setValue(
        "description",
        data.brd_seo_setting?.[0]?.default_description || ""
      );
    }
  }, [data, setValue]);

  const onSubmit = async (inpData: any) => {
    if (data?.brd_seo_setting?.[0]?.id) {
      const res = await updateSeo({
        variables: {
          id: { id: data?.brd_seo_setting?.[0]?.id },
          data: {
            default_title: inpData?.title,
            default_description: inpData?.description,
            default_keywords: inpData?.keywords,
            canonical_url: inpData?.canonical_url,
            og_image_url: inpData?.og_image_url,
          },
        },
      });

      if (res?.data?.update_brd_seo_setting_by_pk?.id) {
        toast.success("SEO Settings Updated Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertSeo({
        variables: {
          data: {
            brd_id: brd_id,
            default_title: inpData?.title,
            default_description: inpData?.description,
            default_keywords: inpData?.keywords,
            canonical_url: inpData?.canonical_url,
            og_image_url: inpData?.og_image_url,
            caseStudy_page_id: case_study_id,
          },
        },
      });

      if (res?.data?.insert_brd_seo_setting_one?.id) {
        toast.success("SEO Settings Inserted Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary flex-col rounded-lg"
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Title</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("title", { required: true })}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Keywords</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("keywords", { required: true })}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Canonical Url</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("canonical_url")}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Og Image Url</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("og_image_url")}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Description</Typography>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              {...register("description", { required: true })}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save SEO Settings
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ShowSEOCasestudy;
