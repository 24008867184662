import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { getStyles } from "../../../pdfDocument/styles";
import { MdViewComfy } from "react-icons/md";

const PassengerComponent = ({ invoice, inquiry }: any) => {
  const stayPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "stay"
    ) || [];
  const carPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "car"
    ) || [];
  const trainPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "train"
    ) || [];
  const flightSuggestion =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "flights"
    ) || [];
  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );
  console.log(
    "flightSuggestion",
    flightSuggestion,
    flightSuggestion[0]?.inq_suggestion?.inq_suggestion_costs[0]?.inq_passenger
      ?.first_name
  );
  return (
    <>
      {stayPassengers.length > 0 && (
        <>
          {stayPassengers.map((item: any, index: number) => (
            <View key={index}>
              {/* Ensure that car_inq_suggestion_costs is correctly accessed */}
              {item?.stay_inq_suggestion?.stay_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <View style={styles.passengerBody}>
                    <Text>{cost?.stay_inq_room?.booked_by || "N/A"} </Text>
                    <Text>£{cost?.sale_price}</Text>
                  </View>
                )
              )}
            </View>
          ))}
        </>
      )}
      {carPassengers.length > 0 && (
        <>
          {carPassengers.map((item: any, index: number) => (
            <View key={index}>
              {item?.car_inq_suggestion?.car_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <View key={costIndex} style={styles.passengerBody}>
                    <Text>
                      {cost?.car_inq_passenger?.first_name}{" "}
                      {cost?.car_inq_passenger?.last_name}
                    </Text>
                    <Text>£{cost?.sale_price}</Text>
                  </View>
                )
              )}
            </View>
          ))}
        </>
      )}

      {trainPassengers.length > 0 && (
        <>
          {trainPassengers.map((item: any, index: number) => (
            <View key={index}>
              {/* Ensure that car_inq_suggestion_costs is correctly accessed */}
              {item?.train_inq_suggestion?.train_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <View key={costIndex} style={styles.passengerBody}>
                    <Text>
                      {cost?.train_inq_passenger?.first_name}{" "}
                      {cost?.train_inq_passenger?.last_name}
                    </Text>
                    <Text>£{cost?.sale_price}</Text>
                  </View>
                )
              )}
            </View>
          ))}
        </>
      )}
      {/* {flightSuggestion.length > 0 && (
        <>
          <View style={styles.passengerBody}>
            <Text>
              Flight:
              {
                flightSuggestion[0]?.inq_suggestion?.inq_suggestion_costs[0]
                  ?.inq_passenger?.first_name
              }
            </Text>
            <Text>
              Type:{" "}
              {
                flightSuggestion[0]?.inq_suggestion?.inq_suggestion_costs[0]
                  ?.inq_passenger?.type
              }
            </Text>
            <Text>Service: Flight</Text>
            <Text>
              Type:{" "}
              {
                flightSuggestion[0]?.inq_suggestion?.inq_suggestion_costs[0]
                  ?.sale_price
              }
            </Text>
          </View>
        </>
      )} */}
      {flightSuggestion.length > 0 && (
        <>
          {flightSuggestion[0]?.inq_suggestion?.inq_suggestion_costs?.map(
            (cost: any, index: number) => (
              <View key={index} style={styles.passengerBody}>
                <Text style={styles.minbody}>
                  {cost?.inq_passenger?.first_name || "N/A"}
                </Text>
                <Text>{cost?.inq_passenger?.type || "N/A"}</Text>
                <Text>Flight</Text>
                <Text>£{cost?.sale_price || "N/A"}</Text>
              </View>
            )
          )}
        </>
      )}
    </>
  );
};

export default PassengerComponent;
