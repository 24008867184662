import { gql } from "@apollo/client";

export const GET_GMAIL_MESSAGES = gql`
  query getGmailMessages($id: String!, $refresh_token: String!) {
    getGmailMessages(arg1: { id: $id, refresh_token: $refresh_token }) {
      payload {
        mimeType
        body {
          data
        }
      }
    }
  }
`;
