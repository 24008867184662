import { gql } from "@apollo/client";

export const EDIT_SUGGESTION = gql`
  mutation MyMutation($id: uuid, $suggestion: inq_suggestion_set_input!) {
    update_inq_suggestion(where: { id: { _eq: $id } }, _set: $suggestion) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_SUGGESTION_STOP = gql`
  mutation MyMutation(
    $id: uuid
    $inq_suggestion_leg_stops: inq_suggestion_leg_stops_set_input!
  ) {
    update_inq_suggestion_leg_stops(
      where: { id: { _eq: $id } }
      _set: $inq_suggestion_leg_stops
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_SUGGESTION_COST = gql`
  mutation MyMutation(
    $id: uuid
    $suggestion_cost: inq_suggestion_cost_set_input!
  ) {
    update_inq_suggestion_cost(
      where: { id: { _eq: $id } }
      _set: $suggestion_cost
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_SUGGESTION_LEG = gql`
  mutation MyMutation(
    $id: uuid
    $inq_suggestion_leg: inq_suggestion_leg_set_input!
  ) {
    update_inq_suggestion_leg(
      where: { id: { _eq: $id } }
      _set: $inq_suggestion_leg
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_SUGGESTION_COST = gql`
  mutation MyMutation($suggestion_cost: [inq_suggestion_cost_insert_input!]!) {
    insert_inq_suggestion_cost(objects: $suggestion_cost) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const STAY_UPDATE_SUGGESTION = gql`
  mutation MyMutation($id: uuid, $suggestion: stay_inq_suggestion_set_input!) {
    update_stay_inq_suggestion(where: { id: { _eq: $id } }, _set: $suggestion) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_STAY_SUGGESTION_ROOM = gql`
  mutation editStaySuggestionRoom(
    $roomId: uuid!
    $roomNumber: String!
    $roomType: String!
    $noOfDays: Int!
  ) {
    update_stay_inq_rooms(
      where: { id: { _eq: $roomId } }
      _set: {
        room_number: $roomNumber
        room_type: $roomType
        no_of_days: $noOfDays
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const STAY_ADD_SUGGESTION_COST = gql`
  mutation MyMutation(
    $suggestion_cost: [stay_inq_suggestion_cost_insert_input!]!
  ) {
    insert_stay_inq_suggestion_cost(objects: $suggestion_cost) {
      affected_rows
      returning {
        id
        # stay_suggestion_id
      }
    }
  }
`;

export const EDIT_ROOM_SUGGESTION_COST = gql`
  mutation editStayRoomSuggestionCost(
    $costId: uuid!
    $roomRate: float8
    $roomTax: float8
    $salePrice: float8
  ) {
    update_stay_inq_suggestion_cost(
      where: { id: { _eq: $costId } }
      _set: { room_rate: $roomRate, room_tax: $roomTax, sale_price: $salePrice }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const CAR_UPDATE_SUGGESTION = gql`
  mutation MyMutation($id: uuid, $suggestion: car_inq_suggestion_set_input!) {
    update_car_inq_suggestion(where: { id: { _eq: $id } }, _set: $suggestion) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const CAR_ADD_SUGGESTION_COST = gql`
  mutation MyMutation(
    $suggestion_cost: [car_inq_suggestion_cost_insert_input!]!
  ) {
    insert_car_inq_suggestion_cost(objects: $suggestion_cost) {
      affected_rows
      returning {
        id
        # car_suggestion_id
      }
    }
  }
`;

export const CAR_EDIT_SUGGESTION_COST = gql`
  mutation MyMutation(
    $id: uuid
    $suggestion_cost: car_inq_suggestion_cost_set_input!
  ) {
    update_car_inq_suggestion_cost(
      where: { id: { _eq: $id } }
      _set: $suggestion_cost
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_TRAIN_SUGGESTION = gql`
  mutation MyMutation($id: uuid, $data: train_inq_suggestion_set_input!) {
    update_train_inq_suggestion(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      returning {
        id
      }
    }
  }
`;

export const TRAIN_ADD_SUGGESTION_COST = gql`
  mutation MyMutation(
    $suggestion_cost: [train_inq_suggestion_cost_insert_input!]!
  ) {
    insert_train_inq_suggestion_cost(objects: $suggestion_cost) {
      affected_rows
      returning {
        id
        # train_suggestion_id
      }
    }
  }
`;

export const EDIT_TRAIN_SUGGESTION_COST = gql`
  mutation MyMutation(
  $id: uuid!,
  $basicFare: float8,
  $taxPrice: float8,
  $salePrice: float8,
  $inq_passenger_id: uuid!
) {
  update_train_inq_suggestion_cost(
    where: { id: { _eq: $id } }
    _set: {
      basic_fare: $basicFare,
      tax_price: $taxPrice,
      sale_price: $salePrice,
      inq_passenger_id: $inq_passenger_id
    }
  ) {
    returning {
      id
    }
  }
}
`;

export const REMOVE_VISA_PERSON = gql`
  mutation RemoveVisaPerson($id: uuid!) {
    delete_visa_inq_suggestion_cost(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_VISA_SUGGESTION = gql`
  mutation updateVisaSuggestion(
    $id: uuid!
    $visaName: String
    $country: String
    $visaExpiryDate: date
  ) {
    update_visa_inq_suggestion(
      where: { id: { _eq: $id } }
      _set: {
        name: $visaName
        country: $country
        visa_expiry_date: $visaExpiryDate
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_VISA_SUGGESTION_COST = gql`
  mutation UpdateVisaSuggestionCost(
    $id: uuid!
    $costPrice: float8!
    $salePrice: float8!
    $inq_passenger_id: uuid!
  ) {
    update_visa_inq_suggestion_cost(
      where: { id: { _eq: $id } }
      _set: {
        cost_price: $costPrice
        inq_passenger_id: $inq_passenger_id
        sale_price: $salePrice
      }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_VISA_SUGGESTION_COST = gql`
  mutation InsertVisaSuggestionCost(
    $data: [visa_inq_suggestion_cost_insert_input!]!
  ) {
    insert_visa_inq_suggestion_cost(objects: $data) {
      affected_rows
    }
  }
`;
