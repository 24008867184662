import { gql } from "@apollo/client";

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($brd_ids: [uuid!]) {
    acc_payment_methods(
      where: { brd_id: { _in: $brd_ids } }
      order_by: { brd_id: asc }
    ) {
      account_no
      name
      account_title
      stripe_pub
      stripe_secret
      default
      swift_code
      webhook_secret
      type
      created_at
      updated_at
      id
      thp_id
      brd_id
      card_terminal_type
      status
      brd_list {
        name
      }
    }
  }
`;
