import { gql, useMutation } from "@apollo/client";

export const GET_GMAIL_FILTERED_MESSAGES = gql`
  mutation MyMutation(
    $refreshToken: String!
    $searchWord: String!
    $senderEmail: String!
  ) {
    getGmailFilteredMessages(
      arg1: {
        refresh_token: $refreshToken
        searchWord: $searchWord
        senderEmail: $senderEmail
      }
    ) {
      response
    }
  }
`;
