import { gql } from "@apollo/client";

export const GET_INQ_TRANSACTIONS_WITH_USER_ID = gql`
  query MyQuery($userId: uuid) {
    inq_list(where: { user_id: { _eq: $userId } }) {
      inq_transections(
        where: { status: { _in: ["paid", "partiallyRefunded"] } }
      ) {
        amount
        ref_no
        status
        refund_amount
      }
    }
  }
`;
