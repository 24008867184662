import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ViewIcon from "../../../../assets/svg/ViewIcon";
import { BiSolidFilePdf } from "react-icons/bi";
import InvoiceDetail from "./components/InvoiceDetail";
import { getPaidAmount } from "~/modules/InquiriesModule/utils/getInquiryStats";
import { pdf } from "@react-pdf/renderer";
import PDFDocument from "./components/InvoiceDetail/pdfDocument";
import { nhost } from "~/lib/nhost";
import { URLs } from "~/config/enums";
import InvoiceP from "./components/InvoiceDetail/InvoiceP";
import DownloadIcon from "@mui/icons-material/Download";
import NewPDFComponent from "./components/InvoiceDetail/NewpdfDocument";
import NewInvoiceP from "./components/InvoiceDetail/NewInvoiceP";

export default function InvoiceCard({
  invoice,
  isActive,
  inquiry,
  hideActions,
  tab,
}: any) {
  const [showDetail, setShowDetail] = useState(false);

  const [logo, setLogo] = useState<any>();
  const [signedImage, setSignedImage] = useState<any>();

  async function convertLogo() {
    try {
      const response = await fetch(
        URLs.FILE_URL + inquiry?.brd_list?.brd_details?.[0]?.fav_icon
      );
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {}
  }

  async function convertSignedImage() {
    try {
      const response = await fetch(URLs.FILE_URL + invoice?.invoice_sign);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignedImage(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {}
  }

  useEffect(() => {
    if (invoice?.invoice_sign) {
      convertSignedImage();
    }
  }, [invoice?.invoice_sign]);

  useEffect(() => {
    if (inquiry?.brd_list?.brd_details?.[0]?.fav_icon) {
      convertLogo();
    }
  }, [inquiry?.brd_list?.brd_details?.[0]?.fav_icon]);

  const downloadPdf = async () => {
    const blob = await pdf(
      <NewPDFComponent
        inquiry={inquiry}
        invoice={invoice}
        logo={logo}
        signedImage={signedImage}
      />
    ).toBlob();

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "invoice.pdf"; // Specify the filename here
    a.click();

    URL.revokeObjectURL(url); // Clean up
  };

  const invoiceAmount =
    parseFloat(invoice?.amount) +
    parseFloat(
      inquiry?.selected_suggestion?.bookingFee ||
        invoice?.invoice_suggestion?.bookingFee ||
        0
    );

  const PaidAmount = inquiry?.inq_transections
    ?.filter((transaction: any) =>
      ["paid", "partiallyRefunded"].includes(transaction.status)
    )
    ?.map((transaction: any) =>
      transaction.status === "partiallyRefunded"
        ? transaction.amount - (transaction.refund_amount || 0)
        : transaction.amount
    )
    ?.reduce((sum: number, amount: number) => sum + amount, 0);

  return (
    <div className="rounded-lg shadow">
      <div className="self-stretch bg-white dark:bg-dark-secondary flex-1 flex flex-col p-[25px] items-start justify-start gap-10 text-left rounded-t-lg text-mini text-darkslategray dark:text-dark-primary">
        <div className="self-stretch flex flex-row items-center justify-between ">
          <div className="relative flex gap-1">
            <span>{`Invoice`}</span>
            <b>{`#${invoice?.invoice_no}`}</b>
            {/* <span>{`(${isActive ? "Active" : "Void"})`}</span> */}
          </div>
          {/* {/* {offer_price && ( */}
          {!hideActions && (
            <div className="flex flex-row items-center justify-between">
              <button
                onClick={() => {
                  setShowDetail(true);
                  // openPdfInNewWindow();
                }}
              >
                <BiSolidFilePdf className="text-basic dark:text-dark-primary h-6 w-6" />
              </button>
              {/* <Link to={`/invoice/pdf/download/${inquiry_no}`}>
              <DownloadIcon />
            </Link> */}
            </div>
          )}

          {/* )}  */}
        </div>

        <div className="self-stretch flex flex-col items-start justify-center text-right">
          <b className="relative leading-[130%] text-29xl">
            {/* {invoice.inq_invoice_items[0].amount} */}£{invoice?.amount}
          </b>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between text-sm bg-basic rounded-b-lg px-[25px] py-[20px] text-white">
        <div className="relative flex gap-1">
          <p className="m-0">Due date:</p>
          <p className="m-0">
            <b>{moment(invoice?.due_date).format("DD-MM-YYYY")}</b>
          </p>
        </div>
        {showDetail && (
          <div className="fixed z-[999] top-0 left-0 w-full min-h-screen bg-[#00000070] flex justify-center items-center py-[100px]">
            <div className="container max-w-[900px] m-[20px] w-full relative">
              <button
                onClick={() => setShowDetail(false)}
                className="text-[16px] text-white flex m-4 justify-center items-center h-[25px] w-[25px] bg-red-500 rounded-full absolute right-0 top-0"
              >
                x
              </button>
              <button className="text-[16px] text-white flex m-4 justify-center items-center h-[25px] w-[25px] bg-red-500 rounded-full absolute left-0 top-0">
                <DownloadIcon onClick={downloadPdf} />
              </button>
              <div className="bg-white h-[600px] overflow-y-scroll scroll-y rounded-lg p-4">
                <NewInvoiceP
                  inquiry={inquiry}
                  invoice={invoice}
                  signedImage={signedImage}
                  tab={tab}
                />
              </div>
            </div>
          </div>
        )}
        {/* {editInv && (
          <UpdateInvoiceModal
            hideForm={setEditInv}
            user={user}
            inquiryDetail={inq}
          />
        )} */}

        {!hideActions &&
          (inquiry?.status === "completed" ? (
            <>
              {PaidAmount === invoiceAmount &&
              inquiry?.invoice_id === invoice.id ? (
                <p className="text-green-500 px-2 rounded-2xl font-medium bg-white">
                  Paid
                </p>
              ) : PaidAmount > invoiceAmount &&
                inquiry?.invoice_id === invoice.id ? (
                <p className="text-green-500 px-2 rounded-2xl font-medium bg-white">
                  Paid
                </p>
              ) : PaidAmount < invoiceAmount && PaidAmount !== 0 ? (
                <p className="text-[#d68e4a] px-2 rounded-2xl font-medium bg-white">
                  Partialy Paid
                </p>
              ) : (
                <p className="text-tomato px-2 rounded-2xl font-medium bg-white">
                  UnPaid
                </p>
              )}
            </>
          ) : inquiry?.status === "invoice_signed" &&
            inquiry?.invoice_id === invoice.id ? (
            <p className="text-green-500 px-2 rounded-2xl font-medium bg-white">
              {" "}
              Invoice Signed
            </p>
          ) : inquiry?.status === "awaiting_for_sign" &&
            inquiry?.invoice_id === invoice.id ? (
            <p className="text-tomato px-2 rounded-2xl font-medium bg-white">
              Awaiting For Sign
            </p>
          ) : PaidAmount === invoiceAmount &&
            inquiry?.invoice_id === invoice.id ? (
            <p className="text-green-500 px-2 rounded-2xl font-medium bg-white">
              Paid
            </p>
          ) : PaidAmount < invoiceAmount &&
            PaidAmount !== 0 &&
            inquiry?.invoice_id === invoice.id ? (
            <p className="text-[#d68e4a] px-2 rounded-2xl font-medium bg-white">
              Partialy Paid
            </p>
          ) : (
            inquiry?.invoice_id !== invoice.id && (
              <p className="bg-white text-basic px-2 font-medium rounded-2xl">
                Invoice Void
              </p>
            )
          ))}
      </div>
    </div>
  );
}
