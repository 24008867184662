import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useEffect, useState } from "react";
import brandsStore from "~/store/brandsStore/brandsStore";
import { useQuery } from "@apollo/client";
import { GET_BRAND_DETAIL } from "~/graphql/brd_list/Query";

const BrandAllPages = () => {
  const { brd_id } = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(GET_BRAND_DETAIL, {
    variables: {
      brd_id,
    },
  });

  const handleUpdateClick = (pageName: any) => {
    if (pageName === "Home Page") {
      navigate(`/admin/brands/${brd_id}/pages/home`);
    }

    if (pageName === "Privacy Policy") {
      navigate(`/admin/brands/${brd_id}/pages/privacy-policy`);
    }

    if (pageName === "Terms and Condition") {
      navigate(`/admin/brands/${brd_id}/pages/terms-and-condition`);
    }

    if (pageName === "Case Study") {
      navigate(`/admin/brands/${brd_id}/pages/case-study`);
    }

    if (pageName === "Umrah Packages") {
      navigate(`/admin/brands/${brd_id}/pages/umrah-packages`);
    }

    if (pageName === "FAQs") {
      navigate(`/admin/brands/${brd_id}/pages/faqs`);
    }
  };

  const [pages, setPages] = useState<any>([
    { name: "Home Page" },
    { name: "Privacy Policy" },
    { name: "Terms and Condition" },
    { name: "Umrah Packages" },
  ]);

  useEffect(() => {
    if (
      data?.brd_list?.[0]?.brand_type === "54671601-ca1f-4214-8315-9c4396c62999"
    ) {
      setPages((prevPages: any) => [
        ...prevPages,
        { name: "FAQs" },
        { name: "Case Study" },
      ]);
    }
  }, [data?.brd_list]);

  return (
    <div className="tex-primary dark:text-dark-primary">
      <h1 className="mb-3">Brand Pages</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Pages</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pages.map((page: any, index: any) => (
              <TableRow key={index}>
                <TableCell>{page.name}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpdateClick(page.name)}
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BrandAllPages;
