import React from "react";
import { useQuery } from "@apollo/client";

import { GET_BRD_GMAIL_CONVERSATION_WITH_USER_ID } from "~/graphql/brd_gmail_conversation/Query";
import ChatWindow from "~/modules/EmailModule/segments/EmailWindow/components/ChatWindow";

const EmailInqComp = (customerId: any) => {
  const { data, loading, error } = useQuery(
    GET_BRD_GMAIL_CONVERSATION_WITH_USER_ID,
    {
      variables: { user_id: customerId?.customerId },
    }
  );

  const conversationId = data?.user?.brd_gmail_conversation?.id;

  return (
    <div
      className=" bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary shadow rounded-lg
      w-full mt-[30px]"
    >
      <h2 className="text-gray-700 dark:text-dark-primary uppercase text-[16px] md:text-[18px] flex  font-bold border-b border-[#E7E3FC61] mt-3 justify-between pb-[20px] pl-[25px] py-[20px]">
        Email Chat
      </h2>
      {conversationId ? (
        <ChatWindow conversation_id={conversationId} setShowUserInfo={true} />
      ) : (
        <p>No conversation found.</p>
      )}
    </div>
  );
};

export default EmailInqComp;
