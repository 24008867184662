import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useFileUpload, useUserId } from "@nhost/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BrandPostDetail from "~/components/common/BrandPostDetail";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import PostUpdate from "~/modules/BrandModule/segments/PostUpdate";
import { UPDATE_POST } from "~/modules/BrandModule/segments/PostUpdate/graphql/Mutation";
import { GET_POST_DETAILS } from "~/modules/BrandModule/segments/PostUpdate/graphql/Query";

export default function BrandPostsUpdatePage() {
  const { post_id } = useParams();
  const { loading, data } = useQuery(GET_POST_DETAILS, {
    variables: { id: post_id },
  });
  const pageData = data?.brd_posts_by_pk;

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const userID = useUserId();
  const client = useApolloClient();

  const [updatePost] = useMutation(UPDATE_POST);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();

  const [logoFile, setLogoFile] = useState<any | null>(null);
  const [content, setContent] = useState("");

  const updatePostSetting = async (inpData: any) => {
    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["image"] = logoUploadRes?.id || null;
    }

    const res = await updatePost({
      variables: {
        id: { id: pageData?.id },
        data: {
          slug: inpData?.slug
            ? inpData?.slug
            : inpData?.post_title?.replaceAll(" ", "-").toLowerCase(),
          post_title: inpData?.post_title,
          canonical_url: inpData?.canonical_url,
          author_id: userID,
          featured_image: inpData?.image || pageData?.featured_image,
          content: content,
          show_in_flight_page: inpData?.show_in_flight_page,
          iata: inpData?.destination?.iata_code || null,
          airline_iata: inpData?.airline?.iata || null,
          excerpt: inpData?.excerpt,
          is_google_indexed: inpData?.is_google_indexed,
        },
      },
    });
    if (res?.data?.update_brd_posts_by_pk?.id) {
      toast.success("Post added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.resetStore();
    }
  };

  return (
    <div>
      <Layout>
        <BrandModule>
          <BrandPostDetail
            setLogoFile={setLogoFile}
            addPost={updatePostSetting}
            content={content}
            setContent={setContent}
            register={register}
            control={control}
            handleSubmit={handleSubmit}
            errors={errors}
            pageData={pageData}
            setType="update"
            setValue={setValue}
          />
          {/* <PostUpdate /> */}
        </BrandModule>
      </Layout>
    </div>
  );
}
