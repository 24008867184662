export const StayFormatFormData = (staySuggestions: any) => {
  const generateCost = (staySuggestionCosts: any) => {
    const roomMap: Record<string, any> = {};

    staySuggestionCosts.forEach((cost: any) => {
      const roomId = cost?.stay_room_id;

      // If the room isn't already added to the map, initialize it
      if (!roomMap[roomId]) {
        roomMap[roomId] = {
          id: roomId,
          roomType: cost?.stay_inq_room?.room_type || null,
          roomNo: cost?.stay_inq_room?.room_no || null,
          costs: [],
        };
      }

      // Add the cost details to the costs array of the room
      roomMap[roomId].costs.push({
        id: cost?.id,
        roomRate: cost?.room_rate,
        roomTax: cost?.room_tax,
        salePrice: cost?.sale_price,
      });
    });

    // Convert the room map to an array of rooms
    return Object.values(roomMap);
  };

  const defaultValue: any = {
    id: staySuggestions.id,
    location: staySuggestions.location || "", // Default to empty string if undefined
    checkIn: staySuggestions.check_in || "", // Default to empty string if undefined
    checkOut: staySuggestions.check_out || "", // Default to empty string if undefined
    hotelName: staySuggestions.hotel_name || "", // Default to empty string if undefined
    rooms: generateCost(staySuggestions.stay_inq_suggestion_costs || []),
  };

  return defaultValue;
};
