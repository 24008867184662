import React, { useEffect } from "react";
import InputField from "~/components/FormInputs/InputField";

export default function CostRow({
  passenger,
  register,
  errors,
  setValue,
  index,
  watch,
  type, // Add a type prop (either "flight" or "stay")
}: any) {
  // Conditionally set the field name based on the type
  const passengerFieldName =
    type === "Stay"
      ? `suggestion_cost.${index}.stay_room_id`
      : type === "Train"
      ? `suggestion_cost.${index}.train_passenger_id`
      : type === "Cars"
      ? `suggestion_cost.${index}.car_id`
      : `suggestion_cost.${index}.passenger_id`;

  useEffect(() => {
    if (passenger?.id) {
      setValue(passengerFieldName, passenger.id);
    }
  }, [passenger, passengerFieldName, setValue]);

  const basicFare = watch(`suggestion_cost.${index}.basic_fare`);
  const tax = watch(`suggestion_cost.${index}.tax_price`);
  const atol = watch(`suggestion_cost.${index}.atol_price`);

  return (
    <div className="flex flex-row gap-4">
      <div className="w-[60%] pt-[30px] text-primary dark:text-dark-primary">
        {passenger?.first_name + " " + passenger?.last_name} (
        {passenger?.type.toUpperCase()})
      </div>
      {/* Flight/Stay Offer Price */}
      <InputField
        label="Basic Fare"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Basic Fare"
        fieldName={`suggestion_cost.${index}.basic_fare`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      {/* Flight/Stay Tax */}
      <InputField
        label="Tax"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Tax"
        fieldName={`suggestion_cost.${index}.tax_price`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      {/* Flight/Stay Atol */}
      {type !== "Cars" && (
        // Render the Atol field only if it's not a car option
        <InputField
          label="Atol"
          register={register}
          errors={errors}
          type="number"
          placeHolder="Atol"
          fieldName={`suggestion_cost.${index}.atol_price`}
          required={true}
          inputClasses="focus:outline-none focus:border-basic"
        />
      )}

      {/* Sale Price Calculation */}
      <InputField
        label={`Sale Price ( cost - ${
          (basicFare ? parseFloat(basicFare) : 0) +
          (tax ? parseFloat(tax) : 0) +
          (atol ? parseFloat(atol) : 0)
        })`}
        register={register}
        errors={errors}
        type="number"
        placeHolder="Sale Price"
        fieldName={`suggestion_cost.${index}.sale_price`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
    </div>
  );
}
