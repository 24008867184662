import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_BRD_GMAIL_CONVERSATION_WITH_USER_ID } from "~/graphql/brd_gmail_conversation/Query";
import ChatWindow from "~/modules/EmailModule/segments/EmailWindow/components/ChatWindow";

const Email = () => {
  const { customerId } = useParams();
  const { data, loading, error } = useQuery(
    GET_BRD_GMAIL_CONVERSATION_WITH_USER_ID,
    {
      variables: { user_id: customerId },
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const conversationId = data?.user?.brd_gmail_conversation?.id;

  return (
    <div>
      <ChatWindow conversation_id={conversationId} setShowUserInfo={true} />
    </div>
  );
};

export default Email;
