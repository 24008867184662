import React from "react";
import { GET_TRANSACTIONS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useQuery } from "@apollo/client";
import PaymentRecord from "./components/PaymentRecord";
import brandsStore from "~/store/brandsStore/brandsStore";

export default function PaymentRequest() {
  // const { travelHouse }: any = travelHouseStore();
  const { brands } = brandsStore();

  const { loading, data, error } = useQuery(GET_TRANSACTIONS, {
    variables: {
      brd_ids: brands.map((brand: any) => brand.id),
    },
  });

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <h1 className="text-primary uppercase text-[18px] dark:text-dark-primary font-bold border-b-2 divide-gray-200  dark:divide-gray-700 mb-2">
        Payment Request ({data.inq_transection.length})
      </h1>
      <main className="w-full pt-2">
        <section className="container">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden rounded-lg">
                  <table className="min-w-full text-center divide-y divide-gray-200  dark:divide-gray-700">
                    <thead className="bg-secondary dark:bg-dark-secondary">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          <div className="flex items-center gap-x-3">
                            <button className="flex items-center gap-x-2">
                              <span>Sr No.</span>
                            </button>
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                        >
                          Customer
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                        >
                          Payment Method
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                        >
                          Inquiry No
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                        >
                          Picked By
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                        >
                          Request Date
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                        >
                          Ref. No
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Customer Details
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-secondary dark:bg-dark-secondary divide-y divide-gray-200  dark:divide-gray-700 ">
                      {data.inq_transection.length > 0 ? (
                        data.inq_transection?.map(
                          (transaction: any, i: any) => (
                            <PaymentRecord
                              key={i}
                              index={i}
                              transaction={transaction}
                            />
                          )
                        )
                      ) : (
                        <tr className="">
                          <td
                            colSpan={7}
                            className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary   whitespace-nowrap"
                          >
                            <div className="inline-flex items-center gap-x-3">
                              <span>No Record Found</span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
