import { gql } from "@apollo/client";

export const GET_USER = gql`
  query GetActivity($id: uuid!) {
    def_user_activity(
      order_by: { timestamp: asc }
      where: { user_id: { _eq: $id } }
    ) {
      id
      timestamp
      action
      activity_id
      connection_id
      user_id
    }
  }
`;

export const GET_USER_LAST_ACTIVITY = gql`
  query MyQuery($id: uuid!) {
    def_user_activity(
      where: { user_id: { _eq: $id } }
      order_by: { timestamp: desc }
      limit: 1
    ) {
      id
      action
      timestamp
    }
  }
`;

export const NOTIFICATOIN_STREAM = gql`
  subscription MySubscription(
    $user_id: uuid!
    $brd_ids: [uuid!]!
    $timestamp: timestamptz
  ) {
    brd_notifications_stream(
      batch_size: 100
      cursor: { initial_value: { created_at: $timestamp } }
      where: { brd_id: { _in: $brd_ids }, user_id: { _eq: $user_id } }
    ) {
      sender_user_id
      content
      created_at
      read
      id
    }
  }
`;

export const UNREAD_NOTIFICATOINS = gql`
  subscription MySubscription($user_id: uuid!, $brd_ids: [uuid!]!) {
    brd_notifications_aggregate(
      where: {
        read: { _eq: false }
        brd_id: { _in: $brd_ids }
        user_id: { _eq: $user_id }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const NOTIFICATOINS = gql`
  subscription Notification($user_id: uuid!, $brd_ids: [uuid!]!) {
    brd_notifications(
      order_by: { created_at: desc }
      limit: 10
      where: { user_id: { _eq: $user_id }, brd_id: { _in: $brd_ids } }
    ) {
      sender_user_id
      content
      created_at
      read
      id
    }
  }
`;

export const GET_ASSIGNED_BRANDS = gql`
  query GetAssignedBrands($id: uuid!, $thp_id: uuid) {
    brd_users(
      where: { user_id: { _eq: $id }, thp_id: { _eq: $thp_id } }
      distinct_on: brd_id
    ) {
      brd_id
      brd_list {
        name
        whatsapp_token
        phoneNumberId
      }
    }
    thp_list(where: { primary_user_id: { _eq: $id } }) {
      brd_lists {
        id
        name
        whatsapp_token
        phoneNumberId
      }
    }
  }
`;
export const GET_ASSIGNED_BRANDS_Dashboard = gql`
  query GetAssignedBrands($id: uuid!, $thp_id: uuid) {
    brd_users(
      where: { user_id: { _eq: $id }, thp_id: { _eq: $thp_id } }
      distinct_on: brd_id
    ) {
      brd_id
      brd_list {
        name
        whatsapp_token
        phoneNumberId
      }
    }
    thp_list(where: { primary_user_id: { _eq: $id } }) {
      brd_lists {
        id
        name
        whatsapp_token
        phoneNumberId
      }
    }
  }
`;
