import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import TrainPassengerCostRow from "../TrainPassengerCostRow";

const TrainPassengerCost = ({
    passengers,
    errors,
    register,
    control,
    setValue,
    watch,
    costs
}: any) => {

    const [trainPassengers, setTrainPassengers] = useState<any[]>([]);
    const [selectedPsngr, setSelectedPsngr] = useState<any>([])

    const handleAddPassenger = () => {
        if (trainPassengers.length < passengers.length) {
            setTrainPassengers((prev) => [...prev, { id: "", basicFare: "", taxPrice: "", salePrice: "" }]);
        }
    };

    const handleRemovePassenger = (indexToRemove: number) => {
        setTrainPassengers((prev) =>
            prev.filter((_, index) => index !== indexToRemove)
        );
    };

    useEffect(() => {
        if (costs && costs.length > 0) {
            setTrainPassengers(costs);
        } else {
            setTrainPassengers([{ id: "", offerPrice: "", costPrice: "" }]);
        }
    }, [])


    return (
        <div className="bg-gray-100 dark:bg-[#28243D] rounded-lg p-2 mt-4">
            <div className="bg-gray-100 dark:bg-[#28243D] rounded-lg p-2 mt-4">
                <div className="flex flex-col border-b border-gray-300 w-full">
                    {/* Section Heading */}
                    <h1 className="flex-1 w-full font-bold text-left text-primary dark:text-dark-primary">
                        Passenger Cost
                    </h1>
                </div>
                <div className="mt-2">
                    {trainPassengers.map((passenger, index) => (
                        <TrainPassengerCostRow
                            key={index}
                            passenger={passenger}
                            passengers={passengers} // Filter available passengers
                            control={control}
                            selectedPsngr={selectedPsngr}
                            errors={errors}
                            index={index}
                            setSelectedPsngr={setSelectedPsngr}
                            onRemove={() => handleRemovePassenger(index)}
                            costs={costs}
                        />
                    ))}
                </div>
            </div>
            <div className="flex justify-end">
                {!costs && trainPassengers.length < passengers.length && (
                    <Button
                        onClick={handleAddPassenger}
                        className="mt-4 bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark"
                    >
                        Add Person
                    </Button>
                )}
            </div>
        </div>
    );
};

export default TrainPassengerCost;
