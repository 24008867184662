import { useQuery } from "@apollo/client";
import React, { useState } from "react";

import base64 from "base64-js";
import { GET_EMAIL_DETAIL } from "~/graphql/actions/Query";
import SingleFile from "./SingleFile";

function extractEmailContent(payload: any, snippet: string): string {
  // If the payload itself has a body and data, return it based on its MIME type.
  if (payload.body && payload.body.data) {
    if (payload.mimeType === "text/html") {
      return decodeBase64(payload.body.data);
    }
    if (payload.mimeType === "text/plain") {
      return decodeBase64(payload.body.data);
    }
  }

  const files = [];

  // If the payload has parts, recurse into each part.
  if (payload.parts) {
    let plainTextContent: string | null = null;

    for (const part of payload.parts) {
      const content = extractEmailContent(part, snippet);

      // If the content is HTML, return it immediately.
      if (part.mimeType === "application/pdf" && content) {
        return content;
      }

      // If the content is plain text, store it and continue searching for an HTML version.
      if (part.mimeType === "text/html" && content) {
        plainTextContent = content;
      }

      if (part.mimeType === "text/plain" && content) {
        plainTextContent = content;
      }
    }

    // If no HTML content was found but plain text was, return the plain text content.
    if (plainTextContent) {
      return plainTextContent;
    }
  }

  // If neither HTML nor plain text content was found, return the snippet.
  return snippet;
}

const decodeBase64 = (data: string) => {
  if (!data) {
    return "";
  }
  const bytes = base64.toByteArray(data);
  return new TextDecoder().decode(bytes);
};

function getAttachmentInfo(emailResponse: any) {
  let attachmentInfo: any = [];

  if (emailResponse?.payload?.filename) {
    attachmentInfo.push({
      attachmentId: emailResponse.payload?.body?.attachmentId,
      name: emailResponse?.payload?.filename,
    });
  }
  if (emailResponse?.payload?.parts) {
    let part: any;
    for (part of emailResponse.payload.parts) {
      if (part.filename) {
        attachmentInfo.push({
          attachmentId: part?.body?.attachmentId,
          name: part.filename,
        });
      } else if (part.parts) {
        // Check nested parts for attachments
        let nestedPart: any;
        for (nestedPart of part.parts) {
          if (nestedPart.filename) {
            attachmentInfo.push({
              attachmentId: nestedPart?.body?.attachmentId,
              name: nestedPart.filename,
            });
          }
        }
      }
    }
  }

  return attachmentInfo;
}

export default function SingleMessageDetail({
  message,
  token,
  user_email,
}: any) {
  const { data, loading, error } = useQuery(GET_EMAIL_DETAIL, {
    variables: {
      id: Array.isArray(user_email)
        ? message?.message_id[0]
        : message?.message_id,
      refresh_token: token,
    },
  });

  if (loading) return <>loading...</>;
  if (error)
    return (
      <>
        ERR...{error?.message} {message?.message_id} {token}
      </>
    );
  const selectedEmail = data?.getGmailMessages;
  //   function extractEmailFiles(payload: any): any {
  //     ;

  //     if (payload?.mimeType === "application/pdf") {
  //       ;
  //       let fileLet = [
  //         ...files,
  //         { id: payload.body.attachmentId, size: payload.body.size },
  //       ];
  //       setFiles([...fileLet]);
  //     }

  //     if (payload?.parts) {
  //       for (const part of payload?.parts) {
  //         if (part?.mimeType === "application/pdf") {
  //           ;
  //           let fileLet = [
  //             ...files,
  //             { id: part.body.attachmentId, size: part.body.size },
  //           ];
  //           setFiles([...fileLet]);
  //         }
  //         if (part?.parts) {
  //           for (const part2 of part?.parts) {
  //             if (part2?.mimeType === "application/pdf") {
  //               let fileLet = [
  //                 ...files,
  //                 { id: part2.body.attachmentId, size: part2.body.size },
  //               ];
  //               setFiles([...fileLet]);
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   extractEmailFiles(selectedEmail.payload);

  const files = getAttachmentInfo(selectedEmail);
  const emailContent = extractEmailContent(
    selectedEmail.payload,
    selectedEmail.snippet
  );

  const otpMatch = emailContent.match(/One time password \(OTP\): (\d{8})/);
  const otp = otpMatch ? otpMatch[1] : "OTP not found";

  const accountMatch = emailContent.match(/Btres\.com account (\w+)/);
  const accountNumber = accountMatch
    ? accountMatch[1]
    : "Account number not found";

  return (
    <div>
      {" "}
      <div>
        <div>
          <label className="block mb-2 text-sm font-sm mt-2 text-gray-900 ">
            MSGID:
          </label>
          <p className="bg-gray-50 p-2.5 rounded-lg">{message.message_id}</p>
        </div>
        <div>
          <label className="block mb-2 text-sm font-sm mt-2 text-gray-900 ">
            From
          </label>
          <p className="bg-gray-50 p-2.5 rounded-lg">
            {
              selectedEmail?.payload?.headers.find(
                (header: any) => header?.name?.toLowerCase() === "from"
              )?.value
            }
          </p>
        </div>
        <div>
          <label className="block mb-2 text-sm font-sm mt-2 text-gray-900 ">
            To
          </label>
          <p className="bg-gray-50 p-2.5 rounded-lg">
            {Array.isArray(user_email)
              ? Object.values(user_email)
                  ?.filter((value) => value !== "")
                  ?.join(",")
              : selectedEmail?.payload?.headers?.find(
                  (header: any) => header?.name.toLowerCase() === "to"
                )?.value}
          </p>
        </div>
        <div>
          <label className="block mb-2 text-sm font-sm mt-2 text-gray-900 ">
            Subject
          </label>
          <p className="bg-gray-50 p-2.5 rounded-lg">
            {
              selectedEmail.payload.headers.find(
                (header: any) => header.name.toLowerCase() === "subject"
              ).value
            }
          </p>
        </div>

        <div>
          <label className="block mb-2 text-sm font-sm mt-2 text-gray-900 ">
            Content
          </label>
          <p className="bg-gray-50 p-2.5 rounded-lg">
            <iframe
              className="w-full rounded-lg h-[550px]"
              srcDoc={extractEmailContent(
                selectedEmail.payload,
                selectedEmail.snippet
              )}
              title="Email content"
            />
          </p>
        </div>

        <div>
          <label className="block mb-2 text-sm font-sm mt-2 text-gray-900 ">
            Attachments
          </label>
          <div className="flex flex-col gap-2">
            {files?.map((file: any) => {
              return (
                <SingleFile
                  file={file}
                  message_id={message?.message_id}
                  refresh_token={token}
                />
              );
            })}
            {files?.length <= 0 && "No files attached"}
          </div>
        </div>

        {/* <div className="flex mt-4">
          <button
            onClick={() => setReply(true)}
            className="ml-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Reply
          </button>
        </div> */}
      </div>
    </div>
  );
}
