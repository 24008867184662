import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_INQ_TRANSACTIONS_WITH_USER_ID } from "~/graphql/inq_transections/Query";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export default function Payments() {
  const { customerId } = useParams();

  const { loading, error, data } = useQuery(GET_INQ_TRANSACTIONS_WITH_USER_ID, {
    variables: { userId: customerId },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const inqLists = data?.inq_list || [];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Reference No</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Refund Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inqLists.map((inq: any) =>
            inq.inq_transections.map((transaction: any) => (
              <TableRow key={transaction.ref_no || "N/A"}>
                <TableCell>{transaction.ref_no || "N/A"}</TableCell>
                <TableCell>{transaction.amount}</TableCell>
                <TableCell>{transaction.status}</TableCell>
                <TableCell>{transaction.refund_amount || "0.00"}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
