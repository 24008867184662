import React, { useState, useEffect } from "react";
import RequestForm from "../index";
import StayRequestForm from "./components/stayRequestForm";
import CarRequestForm from "./components/carRequestForm";
import TrainRequestForm from "./components/trainRequestForm";
import VisaRequestForm from "./components/VisaRequestForm";

const SelectedRequestForm = ({
  refundable,
  PaidAmount,
  inquiry,
  ticekts,
  setSidePopup,
  // tabs = [],
  data,
}: any) => {

  // const [selectedTab, setSelectedTab] = useState(tabs?.[0]?.label || "Hotel");
  // //   const [localTicketPurchase, setLocalTicketPurchase] = useState(false); // Local state per component

  // useEffect(() => {
  //   // Update selectedTab if tabs array changes
  //   if (tabs.length > 0 && tabs?.[0]?.label !== selectedTab) {
  //     setSelectedTab(tabs?.[0].label);
  //     //   setLocalTicketPurchase(false); // Reset local state when tab changes
  //   }
  // }, [tabs, selectedTab]);


  const renderComponent = () => {
    switch (inquiry?.type) {
      case "Hotel":
        return (
          <>
            <StayRequestForm
              paidAmount={PaidAmount}
              refundable={refundable}
              inquiry={inquiry}
              setSidePopup={setSidePopup}
              vendors={data.users}
              fareExpiry={inquiry.selected_suggestion?.fare_expiry || undefined}
              ticekts={ticekts}
            />
          </>
        );
      case "Car":
        return (
          <>
            <CarRequestForm
              paidAmount={PaidAmount}
              refundable={refundable}
              setSidePopup={setSidePopup}
              inquiry={inquiry}
              vendors={data.users}
              fareExpiry={inquiry.selected_suggestion?.fare_expiry || undefined}
              ticekts={ticekts}
            />
          </>
        );
      case "Train":
        return (
          <>
            <TrainRequestForm
              paidAmount={PaidAmount}
              refundable={refundable}
              inquiry={inquiry}
              setSidePopup={setSidePopup}
              vendors={data.users}
              fareExpiry={inquiry.selected_suggestion?.fare_expiry || undefined}
              ticekts={ticekts}
            />
          </>
        );
      case "Flights":
        return (
          <RequestForm
            paidAmount={PaidAmount}
            refundable={refundable}
            inquiry={inquiry}
            setSidePopup={setSidePopup}
            vendors={data.users}
            fareExpiry={inquiry.selected_suggestion?.fare_expiry || undefined}
            ticekts={ticekts}
          />
        );
      case "Visa":
        return (
          <VisaRequestForm
            paidAmount={PaidAmount}
            refundable={refundable}
            inquiry={inquiry}
            setSidePopup={setSidePopup}
            vendors={data.users}
            fareExpiry={inquiry.selected_suggestion?.fare_expiry || undefined}
            ticekts={ticekts}
          />
        );
      default:
        return <div>No matching component found</div>;
    }
  };

  return renderComponent();
};

export default SelectedRequestForm;
